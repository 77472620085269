<template>
  <div
    :style="`background-color: ${color};`"
    class="mr-2 py-1 d-flex align-center justify-center black--text font-weight-medium select-width-120">
    {{ text }}
  </div>
</template>

<script>
import theme from '@/theme';

export default {
  name: 'Badge',

  props: {
    text: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      enum: Object.values(theme),
    },
  },
};
</script>
