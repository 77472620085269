import { CardBaseService } from './CardBaseService';
import { FILTERS_TYPES } from '@/utils/panel-filters';
import { CARDS_TYPES } from '@services/CardDataService.js';
import { getDefaultTimePeriod } from '@/utils/defaultTimePeriods';
import { isAudienceFilterHidden } from '@/utils/countries';

export default class TopSVPiPerformersService extends CardBaseService {
  static getCardType() {
    return CARDS_TYPES.TOP_SVPI_PERFORMERS;
  }

  static getChartTitle() {
    return 'Leading Drivers of Reputation: Market Comparison';
  }

  static getChartComponentName() {
    return 'TopSVPiPerformersChart';
  }

  static getFilters(defaultFilters = {}) {
    const { defaultTimeperiod, defaultAudience } = defaultFilters;

    return {
      audience: defaultAudience ?? null,
      year: getDefaultTimePeriod(location) ?? defaultTimeperiod ?? null,
    };
  }

  static getFiltersConfig(model, { asset, country }) {
    return [
      {
        type: FILTERS_TYPES.AUDIENCE,
        model,
        key: 'audience',
        isHidden: isAudienceFilterHidden(country, asset),
      },
      { type: FILTERS_TYPES.TIME_PERIOD, model, key: 'year' },
    ];
  }

  static getReportPageConfig({ filters, data }) {
    return {
      type: this.getCardType(),
      title: this.getChartTitle(),
      filters,
      data: {
        datas: data,
      },
      rows: this.getRows(data, filters, location.country, location.asset),
    };
  }

  static getRows(data, filters, country, asset) {
    const rows = [];

    data?.svpis?.forEach((svpi) => {
      const dataItem = {
        Country: svpi.country,
        'Awareness': `${svpi.overallsvpi.awareness}`,
        'SV(P)I': `${svpi.overallsvpi.svpi}`,
        'Sector leader': svpi.sectorleader,
        'Gap to average': svpi.gaptoaverage,
      };

      svpi.top5attributes.forEach((attribute, index) => {
        dataItem[`Top ${index + 1} attribute`] = attribute;
      });

      rows.push(dataItem);
    });

    // Add description of filters to last row
    if (rows.length && filters?.year) {
      this.addFiltersToRows(rows, filters, country, asset);
    }
    return rows;
  }

  static humanizeFilters({ year }) {
    return {
      'Time Period': year,
    };
  }

  static async loadData({ filters, filtersConfig }) {
    const params = { period: filters.year };

    // Don't pass audience if hidden
    const audienceConfig = filtersConfig.find((filter) => filter.key === 'audience');
    if (!audienceConfig.isHidden) {
      params.audience = filters.audience;
    }

    const data = await this.httpRequestService.get('/api/v2/svp-comparison', null, params, false);

    const datas = data[0] || {};
    return datas;
  }
}
