import { AUSTRALIA, isAudienceFilterHidden } from '@/utils/countries';
import { groupBy } from '@/utils/helpers';
import Insights from '@/utils/insights';
import { FILTERS_TYPES } from '@/utils/panel-filters';
import moment from 'moment';
import { CARDS_TYPES } from '../CardDataService';
import { CardBaseService } from './CardBaseService';

export default class NewsImpactService extends CardBaseService {
  static getCardType() {
    return CARDS_TYPES.NEWS_IMPACT;
  }

  static getChartTitle() {
    return 'News Awareness and Impact';
  }

  static getChartComponentName() {
    return 'NewsAwarenessAndImpactChart';
  }

  static getFilters(defaultFilters = {}, location = { country: null, asset: null }) {
    const {
      defaultDateRangesFrom,
      defaultDateRangesTo,
      defaultAudience,
      defaultDemographic,
      defaultQuarterPeriodsFrom,
      defaultQuarterPeriodsTo,
      defaultCountriesAll,
    } = defaultFilters;

    return {
      demographic: defaultDemographic ?? null,
      audience: defaultAudience ?? null,
      dateRange: {
        from: defaultDateRangesFrom ?? null,
        to: defaultDateRangesTo ?? null,
      },
      dateRangeByQuarter: {
        from: defaultQuarterPeriodsFrom ?? null,
        to: defaultQuarterPeriodsTo ?? null,
      },
      community: this.getDefaultCommunity(location.asset, location.country),
      countries: defaultCountriesAll ?? [],
    };
  }

  static getFiltersConfig(model, { country, asset }) {
    const INSIGHT = Insights.get(country, asset);
    let dateRange = { type: FILTERS_TYPES.DATE_RANGE, model, key: 'dateRange' };
    // For Australia national view, we use quarter periods
    if (INSIGHT === Insights.NATIONAL && country === AUSTRALIA) {
      dateRange = { type: FILTERS_TYPES.DATE_RANGE_BY_QUARTER, model, key: 'dateRangeByQuarter' };
    }

    return [
      {
        type: FILTERS_TYPES.DEMOGRAPHIC,
        model,
        key: 'demographic',
        country,
        isLocal: !!asset,
        isHidden: INSIGHT === Insights.GLOBAL,
      },
      {
        type: FILTERS_TYPES.AUDIENCE,
        model,
        key: 'audience',
        isHidden: isAudienceFilterHidden(country, asset),
      },
      {
        type: FILTERS_TYPES.COMMUNITY,
        model,
        key: 'community',
        isHidden: INSIGHT !== Insights.ASSET,
        items: this.getCommunities(asset, country),
      },
      {
        type: FILTERS_TYPES.COUNTRIES,
        model,
        key: 'countries',
        isHidden: INSIGHT !== Insights.GLOBAL,
      },
      dateRange,
    ];
  }

  static getReportPageConfig({ location = {}, filters, data }) {
    return {
      type: this.getCardType(),
      title: this.getChartTitle(),
      filters: {
        ...filters,
        country: location.country,
        asset: location.asset,
      },
      data: {
        datas: data,
      },
      rows: this.getRows(data, filters, location.country, location.asset),
    };
  }

  // Formats rows for CSV report
  static getRows(datas, filters, country, asset) {
    const rows = [];
    for (let i = 0; i < datas.newsHeard.length; i++) {
      const heardItem = datas.newsHeard[i];
      const lessItems = datas.newsImpact.filter(
        (i) => i.year === heardItem.year && i.label === 'Somewhat/much less favorable',
      );
      const equalItems = datas.newsImpact.filter(
        (i) => i.year === heardItem.year && i.label === 'No more/less favorable',
      );
      const moreItems = datas.newsImpact.filter(
        (i) => i.year === heardItem.year && i.label === 'Somewhat/much more favorable',
      );
      const lessSmp = lessItems.length ? lessItems[0].smp : null;
      const equalSmp = equalItems.length ? equalItems[0].smp : null;
      const moreSmp = moreItems.length ? moreItems[0].smp : null;
      const row = {
        Date: moment(heardItem.date).format('YYYY-MM-DD'),
        'News Heard': heardItem.smp,
        'Somewhat/much less favorable': lessSmp,
        'No more/less favorable': equalSmp,
        'Somewhat/much more favorable': moreSmp,
      };
      rows.push(row);
    }

    // Add description of filters to last row
    if (rows.length && filters?.reputation) {
      this.addFiltersToRows(rows, filters, country, asset);
    }

    return rows;
  }

  static humanizeFilters({ country, asset, community, dateRange, audience, demographic }) {
    const INSIGHT = Insights.get(country, asset);
    const { from, to } = dateRange;
    const { age, gender, shareholder, votingPref } = demographic;

    if (INSIGHT === Insights.GLOBAL) {
      return {
        Audience: audience,
        'Date Range': `${from} to ${to}`,
      };
    }

    if (INSIGHT === Insights.NATIONAL) {
      return {
        Country: country,
        'Date Range': `${from} to ${to}`,
        Audience: audience,
        Age: age,
        Gender: gender,
        Shareholder: shareholder,
        'Voting Preference': votingPref,
      };
    }

    return {
      'Host Community': `${asset} (${country})`,
      Community: community,
      'Date Range': `${from} to ${to}`,
      Audience: audience,
      Demographic: demographic,
      Age: age,
      Gender: gender,
    };
  }

  static async loadData({ filters, location }) {
    const { from, to } = filters.dateRange;
    const INSIGHT = Insights.get(location.country, location.asset);
    const params = {
      audience: filters.audience,
      yearFrom: from,
      yearTo: to,
      country: location.country,
      asset: location.asset,
      community: filters.community,
      ...filters.demographic,
    };

    // Add countries if global view
    if (INSIGHT === Insights.GLOBAL) {
      params.countries = filters.countries;
    }

    // Set audience for host communities
    if (INSIGHT === Insights.ALL_HOST_COMMUNITIES || INSIGHT === Insights.ASSET) {
      params.audience = 'Host Community';
    }

    // If australia national view, pass dates by quarter
    const isAustraliaNational = INSIGHT === Insights.NATIONAL && location.country === AUSTRALIA;
    if (isAustraliaNational) {
      params.yearFrom = filters.dateRangeByQuarter.from.value;
      params.yearTo = filters.dateRangeByQuarter.to.value;
      params.quarterFrom = filters.dateRangeByQuarter.from.label.replace('Q', 'Quarter ');
      params.quarterTo = filters.dateRangeByQuarter.to.label.replace('Q', 'Quarter ');
    }

    const newsAwarenessEndPoint = '/api/v2/news-awareness';
    const datas = await this.httpRequestService.get(newsAwarenessEndPoint, null, params, false);

    datas.newsAwareness = groupBy(datas.newsImpact, 'label');
    Object.keys(datas.newsAwareness).forEach((set) => {
      datas.newsAwareness[set] = datas.newsAwareness[set].map((item) => [item.date, item.smp]);
    });

    return datas;
  }
}
