
<v-menu
  v-model="isMenuOpen"
  :close-on-content-click="false"
  transition="scale-transition"
  offset-y
  offset-overflow
>
  <template v-slot:activator="{ on, attrs }">
    <v-btn
      @click="isMenuOpen = !isMenuOpen"
      @click:append="isMenuOpen = !isMenuOpen"
      :append-icon="isMenuOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'"
      v-bind="attrs"
      v-on="on"
      outlined
      class="select-btn rounded-0"
    >
      {{ label }}
      <v-icon v-if="isMenuOpen" right dark> mdi-chevron-up </v-icon>
      <v-icon v-else right dark> mdi-chevron-down </v-icon>
    </v-btn>
  </template>

  <v-container v-if="items.length" class="py-0 px-0">
    <v-tabs v-model="tab">
      <v-tab v-for="tab in items" :key="tab.label">
        {{ tab.label }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="tab in items" :key="tab.label">
        <v-row justify="space-between" class="pt-4">
          <v-col cols="auto" class="pt-0">
            <v-checkbox
              v-for="checkbox in tab.buckets"
              :key="checkbox"
              :label="checkbox"
              :input-value="isChecked(tab.type, checkbox)"
              @change="toggleChecked(tab.type, checkbox)"
              class="checkbox"
              hide-details
            ></v-checkbox>
          </v-col>
          <v-col cols="auto">
            <v-btn small outlined tile class="mod-btn mr-4" @click="toggleAll(tab.type, true)"
              >All</v-btn
            >
            <v-btn small outlined tile class="mod-btn mr-4" @click="toggleAll(tab.type, false)"
              >None</v-btn
            >
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
  </v-container>
  <v-list v-else dense class="pa-0">
    <v-list-item>
      <v-list-item-title>No data available</v-list-item-title>
    </v-list-item>
  </v-list>
</v-menu>
