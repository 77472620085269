import CommunityConcernsService from './CardServices/CommunityConcernsService.js';
import CorporateReputationService from './CardServices/CorporateReputationService.js';
import GlobalMapService from './CardServices/GlobalMapService.js';
import OpinionLeadersService from './CardServices/OpinionLeadersService.js';
import ReputationDriverService from './CardServices/ReputationDriverService.js';
import TopSVPiPerformersService from './CardServices/TopSVPiPerformersService.js';
import SentimentService from './CardServices/SentimentService.js';
import VolumeService from './CardServices/VolumeService.js';
import TopSVPiCountryService from './CardServices/TopSVPiCountryService.js';
import ComparisonConcernsService from './CardServices/ComparisonConcernsService.js';
import NewsService from './CardServices/NewsService.js';
import SVPScoreboardService from './CardServices/SVPScorecardService.js';
import NewsImpactService from './CardServices/NewsImpactService.js';

export const CARDS_TYPES = {
  GLOBAL_MAP: 'GLOBAL_MAP',
  OPINION_LEADERS: 'OPINION_LEADERS',
  REPUTATION_DRIVER: 'REPUTATION_DRIVER',
  COMMUNITY_CONCERNS: 'COMMUNITY_CONCERNS',
  TOP_SVPI_PERFORMERS: 'TOP_SVPI_PERFORMERS',
  CORPORATE_REPUTATION: 'CORPORATE_REPUTATION',
  SENTIMENT: 'SENTIMENT',
  VOLUME: 'VOLUME',
  TOP_SVPI_COUNTRY: 'TOP_SVPI_COUNTRY',
  COMPARISON_CONCERNS: 'COMPARISON_CONCERNS',
  NEWS: 'NEWS',
  SVP_SCORECARD: 'SVP_SCORECARD',
  NEWS_IMPACT: 'NEWS_IMPACT',
};

const CARDS_TYPES_TO_SERVICES = {
  [CARDS_TYPES.GLOBAL_MAP]: GlobalMapService,
  [CARDS_TYPES.OPINION_LEADERS]: OpinionLeadersService,
  [CARDS_TYPES.REPUTATION_DRIVER]: ReputationDriverService,
  [CARDS_TYPES.COMMUNITY_CONCERNS]: CommunityConcernsService,
  [CARDS_TYPES.TOP_SVPI_PERFORMERS]: TopSVPiPerformersService,
  [CARDS_TYPES.CORPORATE_REPUTATION]: CorporateReputationService,
  [CARDS_TYPES.SENTIMENT]: SentimentService,
  [CARDS_TYPES.VOLUME]: VolumeService,
  [CARDS_TYPES.TOP_SVPI_COUNTRY]: TopSVPiCountryService,
  [CARDS_TYPES.COMPARISON_CONCERNS]: ComparisonConcernsService,
  [CARDS_TYPES.NEWS]: NewsService,
  [CARDS_TYPES.SVP_SCORECARD]: SVPScoreboardService,
  [CARDS_TYPES.NEWS_IMPACT]: NewsImpactService,
};

export default function CardDataService(type) {
  if (!(type in CARDS_TYPES)) {
    // eslint-disable-next-line no-console
    console.error(`Card type : ${type} is not registered`);

    return {};
  }

  return CARDS_TYPES_TO_SERVICES[type];
}
