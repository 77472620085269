import eventBus from '@/EventBus';
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('selects', [
      'loaded',
      'defaultSelectValues',
    ]),
    defaultSelectValsByCountry() {
      return this.defaultSelectValues({ country: this.country });
    },
  },
  methods: {
    onSelectsLoaded(callback) {
      if (this.loaded) {
        callback(this.defaultSelectValsByCountry);
        return;
      }
      eventBus.on('selectLoaded', () => callback(this.defaultSelectValsByCountry));
    },
  },
};
