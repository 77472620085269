import { AUSTRALIA } from './countries';

const ASSETS = [
  { name: 'BMA', country: AUSTRALIA },
  { name: 'NIW', country: AUSTRALIA },
  { name: 'NSWEC', country: AUSTRALIA },
  { name: 'OD', country: AUSTRALIA },
  { name: 'WAIO', country: AUSTRALIA },
  { name: 'Jansen', country: 'Canada' },
  { name: 'Cerro Colorado', country: 'Chile' },
  { name: 'Spence & Escondida', country: 'Chile' },
  { name: 'Resolution Copper', country: 'United States' },
  { name: 'Shenzi/Mad Dog/Neptune/Atlantis', country: 'United States' },
  { name: 'Petroleum Headquarter', country: 'United States' },
];

const COMMUNITIES = [
  { id: '392', name: 'BMA (Overall)', asset: 'BMA' },
  { id: '398', name: 'Cerro Colorado (Overall)', asset: 'Cerro Colorado' },
  { id: '397', name: 'Jansen (Overall)', asset: 'Jansen' },
  { id: '393', name: 'NIW (Overall)', asset: 'NIW' },
  { id: '394', name: 'NSWEC (Overall)', asset: 'NSWEC' },
  { id: '395', name: 'OD (Overall)', asset: 'OD' },
  { id: '402', name: 'Petroleum Headquarters (Overall)', asset: 'Petroleum Headquarters' },
  { id: '400', name: 'Resolution Copper (Overall)', asset: 'Resolution Copper' },
  {
    id: '401',
    name: 'Shenzi/Mad Dog/Neptune/Atlantis (Overall)',
    asset: 'Shenzi/Mad Dog/Neptune/Atlantis',
  },
  { id: '399', name: 'Spence & Escondida (Overall)', asset: 'Spence & Escondida' },
  { id: '396', name: 'WAIO (Overall)', asset: 'WAIO' },
  { id: '412', name: 'Andamooka, Maree, Roxby Downs, Woomera', asset: 'OD' },
  {
    id: '417',
    name: 'Antofagasta Region (Antofagasta City, Mejillones, Sierra Gorda)',
    asset: 'Cerro Colorado',
  },
  { id: '419', name: 'Arizona', asset: 'Resolution Copper' },
  { id: '420', name: 'Gulf of Mexico', asset: 'Shenzi/Mad Dog/Neptune/Atlantis' },
  { id: '421', name: 'Houston', asset: 'Petroleum Headquarter' },
  { id: '377', name: 'K&S', asset: '' },
  { id: '407', name: 'Kalgoorlie & Kambalda', asset: 'NIW' },
  { id: '408', name: 'Kwinana & Baldivis', asset: 'NIW' },
  { id: '409', name: 'Leonora, Leinster & Wiluna', asset: 'NIW' },
  { id: '405', name: 'Mackay', asset: 'BMA' },
  { id: '406', name: 'Moranbah, Blackwater, Emerald, Dysart', asset: 'BMA' },
  { id: '411', name: 'Muswellbrook, Singleton & Upper Hunter', asset: 'NSWEC' },
  { id: '410', name: 'Newcastle', asset: 'NSWEC' },
  { id: '413', name: 'Newman', asset: 'WAIO' },
  { id: '414', name: 'Port Hedland', asset: 'WAIO' },
  { id: '403', name: 'Rockhampton', asset: 'BMA' },
  {
    id: '416',
    name: 'Rural Saskatchewan communities (Includes Humboldt, Lanigan, Watson, Muenster, Drake, Wynyard, LeRoy, Englefeld, Nokomis, St Gregor and Jansen)',
    asset: 'Jansen',
  },
  { id: '415', name: 'Saskatoon', asset: 'Jansen' },
  { id: '418', name: 'Tarapacá Region (Iquique, Pozo Almonte)', asset: 'Spence & Escondida' },
  { id: '404', name: 'Townsville', asset: 'BMA' },
];

export const getAssetsByCountry = (country) => {
  return ASSETS.filter((a) => a.country === country).map((a) => a.name);
};

export const getCommunitiesByAssets = (assets) => {
  if (!assets) return [];
  return COMMUNITIES.filter((c) => assets.includes(c.asset)).map((a) => a.name);
};

export const getAllCommunitiesIdsByCountry = (country) => {
  const assets = getAssetsByCountry(country);
  return COMMUNITIES.filter((c) => assets.includes(c.asset)).map((a) => a.id);
};

export const mapCommunityNamesToIds = (communityNames = []) => {
  return communityNames.map((companyName) => COMMUNITIES.find((c) => c.name === companyName).id);
};
