import { CardBaseService } from './CardBaseService';
import moment from 'moment';
import Insights from '@utils/insights';
import { FILTERS_TYPES } from '@/utils/panel-filters';
import { CARDS_TYPES } from '@services/CardDataService.js';
import { toPercent } from '@/utils/helpers';
import { shortMonthFromQuarterLabel, quarterLabelFromShortMonth } from '@/utils/quarters';
import { AUSTRALIA, isAudienceFilterHidden } from '@/utils/countries';

export default class OpinionLeadersService extends CardBaseService {
  static getCardType() {
    return CARDS_TYPES.OPINION_LEADERS;
  }

  static getChartTitle() {
    return 'Reputation Over Time';
  }

  static getChartComponentName() {
    return 'OpinionLeadersChart';
  }

  static getFilters(defaultFilters = {}, location = { country: null, asset: null }) {
    const {
      defaultDemographic,
      defaultDateRangesFrom,
      defaultDateRangesTo,
      defaultKeyAttribut,
      defaultCountries,
      defaultAudience,
      defaultQuarterPeriodsFrom,
      defaultQuarterPeriodsTo,
    } = defaultFilters;

    return {
      demographic: defaultDemographic ?? null,
      audience: defaultAudience ?? null,
      reputation: defaultKeyAttribut ?? null,
      dateRange: {
        from: defaultDateRangesFrom ?? null,
        to: defaultDateRangesTo ?? null,
      },
      dateRangeByQuarter: {
        from: defaultQuarterPeriodsFrom ?? null,
        to: defaultQuarterPeriodsTo ?? null,
      },
      community: this.getDefaultCommunity(location.asset, location.country),
      selectedLines: defaultCountries ?? [],
    };
  }

  static getFiltersConfig(model, { country, asset }) {
    const INSIGHT = Insights.get(country, asset);

    let dateRange = { type: FILTERS_TYPES.DATE_RANGE, model, key: 'dateRange' };
    // For Australia national view, we use quarter periods
    if (INSIGHT === Insights.NATIONAL && country === AUSTRALIA) {
      dateRange = { type: FILTERS_TYPES.DATE_RANGE_BY_QUARTER, model, key: 'dateRangeByQuarter' };
    }
    return [
      {
        type: FILTERS_TYPES.DEMOGRAPHIC,
        model,
        key: 'demographic',
        country,
        isLocal: !!asset,
        isHidden: INSIGHT === Insights.GLOBAL,
      },
      {
        type: FILTERS_TYPES.AUDIENCE,
        model,
        key: 'audience',
        isHidden: isAudienceFilterHidden(country, asset),
      },
      {
        type: FILTERS_TYPES.COUNTRIES,
        model,
        key: 'selectedLines',
        isHidden: INSIGHT !== Insights.GLOBAL,
      },
      { type: FILTERS_TYPES.KEY_ATTRIBUTE, model, key: 'reputation' },
      {
        type: FILTERS_TYPES.COMMUNITY,
        model,
        key: 'community',
        isHidden: INSIGHT !== Insights.ASSET || INSIGHT === Insights.ALL_HOST_COMMUNITIES,
        items: this.getCommunities(asset, country),
      },
      dateRange,
    ];
  }

  static getReportPageConfig({ location = {}, filters, data }) {
    return {
      type: this.getCardType(),
      title: this.getChartTitle(),
      filters: {
        ...filters,
        country: location.country,
        asset: location.asset,
      },
      data: {
        lines: data,
      },
      rows: this.getRows(data, filters, location.country, location.asset),
    };
  }

  static getRows(data, filters, country, asset) {
    const rows = [];
    if (!data?.length) return rows;
    const lines = [...data].sort((line1, line2) => line2.data.length - line1.data.length);
    const maxDataLength = lines[0].data;
    data.forEach((timeserie) => {
      const data = {
        Name: timeserie.name,
      };
      for (let index = 0; index < maxDataLength.length; index++) {
        const maxData = maxDataLength[index];
        const year = new Date(maxData[0]).getFullYear();
        data[`Year ${year}`] =
          index < timeserie.data.length ? `${toPercent(timeserie.data[index][1])}%` : null;
      }
      rows.push(data);
    });

    // Add description of filters to last row
    if (rows.length && filters?.reputation) {
      this.addFiltersToRows(rows, filters, country, asset);
    }
    return rows;
  }

  static humanizeFilters({
    country,
    asset,
    dateRange,
    audience,
    reputation,
    selectedLines,
    community,
    demographic,
  }) {
    const { age, gender, shareholder, votingPref } = demographic;
    const { from, to } = dateRange;

    const INSIGHT = Insights.get(country, asset);

    if (INSIGHT === Insights.GLOBAL) {
      return {
        Country: country,
        'Date range': `${from} to ${to}`,
        'Key Attribute': reputation,
        Audience: audience,
        Countries: selectedLines.toString(),
      };
    }

    if (INSIGHT === Insights.NATIONAL) {
      return {
        Country: country,
        'Date Range': `${from} to ${to}`,
        'Key Attribute': reputation,
        Age: age,
        Gender: gender,
        ...(country === AUSTRALIA && { Audience: audience }),
        Shareholder: shareholder,
        'Voting Preference': votingPref,
      };
    }

    return {
      'Host Community': `${asset} (${country})`,
      Community: community,
      'Date Range': `${from} to ${to}`,
      'Key Attribute': reputation,
      Age: age,
      Gender: gender,
      Shareholder: shareholder,
      'Voting Preference': votingPref,
    };
  }

  static async loadData({ filters, location, filtersConfig }) {
    const { from, to } = filters.dateRange;
    const INSIGHT = Insights.get(location.country, location.asset);

    // Don't pass companies if hidden
    const audienceConfig = filtersConfig.find((filter) => filter.key === 'audience');
    const audience = audienceConfig.isHidden ? [] : filters.audience;

    const params = {
      country: location.country,
      audience: audience,
      asset: location.asset,
      periodFrom: from,
      periodTo: to,
      reputation: filters.reputation,
      community: INSIGHT === Insights.ASSET ? filters.community : null,
      ...filters.demographic,
    };

    // If australia national view, pass dates by quarter
    if (INSIGHT === Insights.NATIONAL && location.country === AUSTRALIA) {
      params.periodFrom = filters.dateRangeByQuarter.from.value;
      params.periodTo = filters.dateRangeByQuarter.to.value;
      params.quarterFrom = quarterLabelFromShortMonth(filters.dateRangeByQuarter.from.label);
      params.quarterTo = quarterLabelFromShortMonth(filters.dateRangeByQuarter.to.label);
    }

    let datas = await this.httpRequestService.get('/api/v2/opinion-leader', null, params, false);
    const lines = [];

    datas = datas.sort((data1, data2) => {
      const date1 = moment(
        `${data1.year}-${shortMonthFromQuarterLabel(data1.quarter_label) || ''}`,
        'YYYY-MMM',
      ).valueOf();
      const date2 = moment(
        `${data2.year}-${shortMonthFromQuarterLabel(data2.quarter_label) || ''}`,
        'YYYY-MMM',
      ).valueOf();
      return date1 - date2;
    });
    if (INSIGHT === Insights.GLOBAL) {
      for (let index = 0; index < datas.length; index++) {
        const data = datas[index];
        if (!filters.selectedLines.includes(data.country_label)) {
          continue;
        }
        const binded = lines.find((bind) => bind.name === data.country_label);
        const yearDate = moment(
          `${data.year}-${shortMonthFromQuarterLabel(data.quarter_label) || ''}`,
          'YYYY-MMM',
        ).valueOf();
        if (binded) {
          binded.data.push([yearDate, data.smp]);
        } else {
          lines.push({
            name: data.country_label,
            data: [[yearDate, data.smp]],
          });
        }
      }
    } else if (INSIGHT === Insights.NATIONAL) {
      for (let index = 0; index < datas.length; index++) {
        const data = datas[index];
        const binded = lines.find((bind) => bind.name === data.company_label);
        const yearDate = moment(
          `${data.year}-${shortMonthFromQuarterLabel(data.quarter_label) || ''}`,
          'YYYY-MMM',
        ).valueOf();
        if (binded) {
          binded.data.push([yearDate, data.smp]);
        } else {
          lines.push({
            name: data.company_label,
            data: [[yearDate, data.smp]],
          });
        }
      }
    } else if (INSIGHT === Insights.ALL_HOST_COMMUNITIES) {
      return Object.entries(
        datas.reduce((acc, curr) => {
          const key = curr.asset_short;

          if (!acc[key]) acc[key] = [];

          acc[key] = [...acc[key], curr];

          return acc;
        }, {}),
      ).map(([asset, value]) => {
        const data = Object.entries(
          value.reduce((acc, curr) => {
            const key = moment(`${curr.year}`, 'YYYY').valueOf();

            if (!acc[key]) {
              acc[key] = {
                asset: curr.asset_short,
                total: 0,
                smp: 0,
              };
            }

            acc[key] = {
              ...acc[key],
              total: acc[key].total + 1,
              smp: acc[key].smp + curr.smp,
            };

            return acc;
          }, {}),
        ).map(([year, { smp, total }]) => [+year, smp / total]);

        return {
          name: asset,
          data,
        };
      });
    } else {
      for (let index = 0; index < datas.length; index++) {
        const data = datas[index];
        const binded = lines.find((bind) => bind.name === data.company_label);
        const yearDate = moment(`${data.year}`, 'YYYY').valueOf();
        if (binded) {
          binded.data.push([yearDate, data.smp]);
        } else {
          lines.push({
            name: data.company_label,
            data: [[yearDate, data.smp]],
          });
        }
      }
    }

    return lines;
  }
}
