import httpRequestService from '../HttpRequestService';
import store from '@/store';

const notImplemented = (methodName) => {
  throw new Error(`Static method "${methodName}" is not implemented`);
};

export class CardBaseService {
  static httpRequestService = httpRequestService;

  static getChartTitle() {
    notImplemented('getChartTitle');
  }

  static getFilters() {
    return {};
  }

  static getFiltersConfig() {
    return [];
  }

  static getChartComponentName() {
    notImplemented('getChartComponentName');
  }

  static humanizeFilters() {
    notImplemented('humanizeFilters');
  }

  static async loadData() {
    notImplemented('loadData');
  }

  static getReportConfiguration() {
    return {
      // eslint-disable-next-line no-unused-vars
      ignoreElements: (element) => false,
      backgroundColor: undefined,
    };
  }

  static addFiltersToRows(rows, filters, country, asset) {
    const humanizedFilters = this.humanizeFilters({ ...filters, country, asset });
    rows.push({ [Object.keys(rows[0])[0]]: 'FILTERS:' });
    Object.keys(humanizedFilters).map(key => {
      const row = humanizedFilters[key] ? `${key}: ${humanizedFilters[key]}` : null;
      row && rows.push({ [Object.keys(rows[0])[0]]: row });
    });
  }

  static getCommunities(asset, country) {
    let communities = [];
    if (country && asset && asset !== 'All') {
      communities = store.state.selects.assetsAndCommunitiesByCountries
        .find((count) => count.country === country)?.assets
        .find((ass) => ass.asset_short === asset)?.communities || [];
    }
    return communities;
  }

  static getDefaultCommunity(asset, country) {
    const communities = this.getCommunities(asset, country);
    return communities[0] || null;
  }
}
