
<div
  ref="screenshot-container"
  class="screenshot-container"
  :style="{
    height: `${height}px`,
    width: `${width}px`,
  }"
>
  <div v-if="page" class="d-flex flex-column justify-space-between fill-height">
    <div
      ref="chart"
      class="content d-inline-flex align-center"
      :style="{ transform: `scale(${scale})` }"
    >
      <component v-bind:is="page['chartComponentName']" v-bind="page['data']" />
    </div>
    <div ref="footer" class="footer d-flex justify-space-between">
      <div>
        <h1 class="font-weight-medium grey--text text--darken-3">
          {{ page.title }}
        </h1>
        <ul>
          <li v-for="[title, value] in Object.entries(page.humanizedFilters)" :key="title">
            <template v-if="!!value">
              <b class="font-weight-medium">{{ title }}: </b> {{ value }}
            </template>
          </li>
        </ul>
      </div>

      <div class="d-flex align-center">
        <img :src="`data:image/png;base64,${BHP_LOGO}`" class="logo" />
      </div>
    </div>
  </div>
</div>
