
<v-lazy :min-height="minHeight">
  <component :is="!digitalIntel ? 'v-card' : 'div'" :class="[loading ? 'is-loading' : '']">
    <v-card-title v-if="title" class="title">
      {{ title }}
    </v-card-title>
    <v-divider class="mx-4" v-if="title" />
    <component :is="!digitalIntel ? 'v-card-text' : 'div'" class="panel-content">
      <div v-if="loading" class="progress-container">
        <v-progress-circular
          :class="[digitalIntel ? 'loader digital-intel' : 'loader']"
          :size="70"
          :width="7"
          indeterminate
        />
      </div>
      <component
        :is="!digitalIntel ? 'div' : 'v-card'"
        elevation="0"
        :class="[!digitalIntel ? '' : 'digital-intel pa-4 pt-0 mb-3']"
      >
        <v-row no-gutters class="flex-nowrap align-start">
          <v-col v-if="filters" cols="auto" class="flex-shrink-1">
            <v-row :align="'center'" no-gutters>
              <v-col class="filters">
                <slot name="filters"> </slot>
              </v-col>
            </v-row>
          </v-col>
          <v-spacer />
          <v-col v-if="actions" cols="auto">
            <Actions
              :reportDisabled="reportDisabled"
              :isGeneratingReport="isGeneratingReport"
              :showNoData="showNoData"
              :downloadDisabled="downloadDisabled"
              :addToReport="addToReport"
              :downloadChart="downloadChart"
            />
          </v-col>
        </v-row>
      </component>
      <v-divider v-if="!digitalIntel" class="my-4" />
      <div
        v-if="showNoData && !loading"
        class="d-flex flex-column align-center justify-center panel-content"
      >
        <v-icon x-large> mdi-circle-off-outline </v-icon>
        <span class="black--text font-weight-medium mt-2"> No Data Available </span>
      </div>
      <div v-else ref="panelContent" class="panel-content">
        <slot></slot>
      </div>
    </component>
  </component>
</v-lazy>
