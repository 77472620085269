import { CardBaseService } from './CardBaseService';
import moment from 'moment';
import { FILTERS_TYPES } from '@/utils/panel-filters';
import { CARDS_TYPES } from '@services/CardDataService.js';
import { getIdFromCountry } from '@utils/countries';
import { mapCompanyNamesToIds, getCompanyNamesByCountryName } from '@/utils/companies';
import {
  getAssetsByCountry,
  getCommunitiesByAssets,
  mapCommunityNamesToIds,
} from '@/utils/assetsAndCommunities';

export default class SentimentService extends CardBaseService {
  static getCardType() {
    return CARDS_TYPES.SENTIMENT;
  }

  static getChartTitle() {
    return 'Net Sentiment';
  }

  static getChartComponentName() {
    return 'SentimentChart';
  }

  static getFilters(defaultFilters = {}) {
    const { defaultCountry } = defaultFilters;

    return {
      country: defaultCountry ?? null,
      assets: [],
      topics: [],
      postType: [],
      companies: [],
      communities: [],
      reputation: 'Against local peers',
      period: {
        from: moment().subtract(7, 'd').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
        period: 0,
      },
    };
  }

  static getFiltersConfig(model) {
    return [
      { type: FILTERS_TYPES.COUNTRY, label: 'Countries', model, key: 'country', width: 400 },
      { type: FILTERS_TYPES.POST_TYPE, label: 'Post Type', model, key: 'postType', width: 400 },
      { type: FILTERS_TYPES.TOPICS, label: 'Key Issues', model, key: 'topics', width: 400 },
      {
        type: FILTERS_TYPES.DATE_RANGE_BY_PERIOD,
        label: 'Time Period',
        model,
        key: 'period',
        width: 400,
      },
      {
        type: FILTERS_TYPES.REPUTATION,
        label: 'Reputations',
        model,
        key: 'reputation',
        width: 400,
      },
      {
        type: FILTERS_TYPES.COMPANIES,
        model,
        key: 'companies',
        items: getCompanyNamesByCountryName(model.country),
        isHidden: model.reputation !== 'Against local peers',
        width: 400,
      },
      {
        type: FILTERS_TYPES.ASSETS,
        label: 'Assets',
        model,
        key: 'assets',
        items: getAssetsByCountry(model.country),
        isHidden: model.reputation === 'Against local peers',
        width: 400,
      },
      {
        type: FILTERS_TYPES.COMMUNITIES,
        model,
        key: 'communities',
        items: getCommunitiesByAssets(model.assets),
        isHidden: model.reputation === 'Against local peers' || !model?.assets?.length,
        width: 400,
      },
    ];
  }

  static getReportPageConfig({ filters, data }) {
    return {
      type: this.getCardType(),
      title: this.getChartTitle(),
      filters: filters,
      data: {
        data: data,
      },
      rows: this.getRows(data, filters, location.country, location.asset),
    };
  }

  static getRows(data, filters, country, asset) {
    const rows = [];
    const dates = [
      ...new Set(
        data?.reduce((acc, item) => {
          acc.push(...item.data.map((d) => d.x));
          return acc;
        }, []),
      ),
    ];
    dates.forEach((date) => {
      const dateFormatted = new Date(date).toISOString().slice(0, 10);

      const dataItem = {
        Date: dateFormatted,
      };
      data.forEach((item) => {
        const dataFound = item.data.find((d) => d.x === date);
        dataItem[item.name] = dataFound ? dataFound.y : null;
      });
      rows.push(dataItem);
    });

    // Add description of filters to last row
    if (rows.length && filters?.reputation) {
      this.addFiltersToRows(rows, filters, country, asset);
    }
    return rows;
  }

  static humanizeFilters({
    period,
    country,
    companies,
    communities,
    topics,
    reputation,
    postType,
    assets,
  }) {
    const filters = {
      Country: country,
      Reputation: reputation,
      'Time Period': `${period.from} to ${period.to}`,
      'Key Issues': topics.map((topic) => topic.name).join(', '),
      'Post Type': postType.join(', '),
      ...(reputation !== 'Against local peers' && { Assets: assets.join(', ') }),
      ...(reputation !== 'Against local peers' && { Communities: communities.join(', ') }),
      ...(reputation === 'Against local peers' && { Companies: companies.join(', ') }),
    };
    return filters;
  }

  static async loadData({ filters, filtersConfig, customTimePeriod }) {
    // Don't pass companies if hidden
    const companiesConfig = filtersConfig.find((filter) => filter.key === 'companies');
    const companies = companiesConfig.isHidden ? [] : mapCompanyNamesToIds(filters.companies);

    // Dont pass assets if hidden
    const assetsConfig = filtersConfig.find((filter) => filter.key === 'assets');
    const assets = assetsConfig.isHidden ? [] : filters.assets;

    // Dont pass communities if hidden
    const communitiesConfig = filtersConfig.find((filter) => filter.key === 'communities');
    let communities = mapCommunityNamesToIds(filters.communities);
    communities = communitiesConfig.isHidden ? [] : communities;

    const countryId = getIdFromCountry(filters.country);

    const params = {
      from: customTimePeriod ? customTimePeriod.from : filters.period.from,
      to: customTimePeriod ? customTimePeriod.to : filters.period.to,
      countryIds: countryId ? [countryId] : null,
      country: filters.country,
      assets: assets,
      topics: filters.topics.map((topic) => topic.id),
      reputation: filters.reputation,
      companies: companies,
      communities: communities,
      // Hardcoding post type name to ID for now
      postTypes: filters.postType.map((name) => {
        if (name === 'News') {
          return 1;
        } else if (name === 'Social') {
          return 2;
        }
      }),
    };

    const type = params.reputation === 'Against local peers' ? 'Company' : 'Community';
    delete params.reputation;
    const sentiments = await this.httpRequestService.get(
      '/api/v2/digital-intelligence/sentiments',
      null,
      {
        ...params,
        type,
      },
      false,
    );
    return sentiments;
  }
}
