<template>
  <v-app class="no-layout-app" :style="{ padding: 0, position: 'static' }">
    <v-container fluid class="fill-height pa-0" :style="{ padding: 0 }">
      <slot />
    </v-container>
  </v-app>
</template>

<script>
export default {};
</script>

<style lang="scss">
.no-layout-app .v-application--wrap {
  position: static !important;
}
</style>
