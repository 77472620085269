<template>
  <v-lazy :min-height="minHeight">
    <component :is="!digitalIntel ? 'v-card' : 'div'" :class="[loading ? 'is-loading' : '']">
      <v-card-title v-if="title" class="title">
        {{ title }}
      </v-card-title>
      <v-divider class="mx-4" v-if="title" />
      <component :is="!digitalIntel ? 'v-card-text' : 'div'" class="panel-content">
        <div v-if="loading" class="progress-container">
          <v-progress-circular
            :class="[digitalIntel ? 'loader digital-intel' : 'loader']"
            :size="70"
            :width="7"
            indeterminate
          />
        </div>
        <component
          :is="!digitalIntel ? 'div' : 'v-card'"
          elevation="0"
          :class="[!digitalIntel ? '' : 'digital-intel pa-4 pt-0 mb-3']"
        >
          <v-row no-gutters class="flex-nowrap align-start">
            <v-col v-if="filters" cols="auto" class="flex-shrink-1">
              <v-row :align="'center'" no-gutters>
                <v-col class="filters">
                  <slot name="filters"> </slot>
                </v-col>
              </v-row>
            </v-col>
            <v-spacer />
            <v-col v-if="actions" cols="auto">
              <Actions
                :reportDisabled="reportDisabled"
                :isGeneratingReport="isGeneratingReport"
                :showNoData="showNoData"
                :downloadDisabled="downloadDisabled"
                :addToReport="addToReport"
                :downloadChart="downloadChart"
              />
            </v-col>
          </v-row>
        </component>
        <v-divider v-if="!digitalIntel" class="my-4" />
        <div
          v-if="showNoData && !loading"
          class="d-flex flex-column align-center justify-center panel-content"
        >
          <v-icon x-large> mdi-circle-off-outline </v-icon>
          <span class="black--text font-weight-medium mt-2"> No Data Available </span>
        </div>
        <div v-else ref="panelContent" class="panel-content">
          <slot></slot>
        </div>
      </component>
    </component>
  </v-lazy>
</template>

<script>
import { ScreenshotGeneratorBus } from '@components/ScreenshotGenerator.vue';
import Actions from '@components/Actions.vue';
import { mapActions } from 'vuex';

export default {
  components: {
    Actions,
  },

  props: {
    title: {
      type: String,
      default: null,
    },
    showNoData: {
      type: Boolean,
      default: false,
    },
    minHeight: {
      type: [Number, String],
      default: 100,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Boolean,
      default: true,
    },
    digitalIntel: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => ({}),
      validator: function (config) {
        const keys = Object.keys(config);
        return !keys.length || ['type', 'title', 'data'].every((key) => keys.includes(key));
      },
    },
    filters: Boolean,
    reportDisabled: Boolean,
    downloadDisabled: Boolean,
    rows: Array,
  },

  data: function () {
    return {
      isGeneratingReport: false,
    };
  },

  methods: {
    ...mapActions('reportbuilder', ['addAddOnReportPage']),

    addToReport() {
      this.isGeneratingReport = true;
      ScreenshotGeneratorBus.$emit('TAKE_SCREENSHOT', this.config, (page) => {
        this.addAddOnReportPage({ ...page, rows: this.rows, title: this.title });
        this.isGeneratingReport = false;
      });
    },

    downloadChart() {
      this.$emit('panel-download');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/_colors.scss';
@import '@styles/_fonts.scss';
@import '@styles/variables.scss';

.panel-content {
  min-height: 300px;
}

.title {
  color: $bhp-blue-1;
  font-size: $bhp-large-title-font-size;
  line-height: $bhp-large-title-line-height;
}

.divider {
  margin: 0 16px;
}

.progress-container {
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.25);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  .loader {
    position: absolute;
    top: calc(50% - 35px);
    right: calc(50% - 35px);
    display: block;
    margin-right: auto;
    margin-left: auto;

    &.digital-intel {
      position: fixed;
    }
  }
}

.filters {
  color: $bhp-blue-1;
  font-size: $bhp-title-font-size;
  line-height: $bhp-title-line-height;
}

.digital-intel {
  position: fixed;
  top: $header-height + 12px;
  right: 0;
  width: 250px;
  height: calc(100vh - #{$header-height});
  z-index: 10;
}
</style>
