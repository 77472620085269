const LOCATIONS_INSIGHTS = {
  ALL_HOST_COMMUNITIES: 'ALL_HOST_COMMUNITIES',
  NATIONAL: 'NATIONAL',
  GLOBAL: 'GLOBAL',
  ASSET: 'ASSET',
};

export default {
  get: (country, asset) => {
    if (asset === 'All') return LOCATIONS_INSIGHTS.ALL_HOST_COMMUNITIES;
    if (!!country && !asset) return LOCATIONS_INSIGHTS.NATIONAL;
    if (!country && !asset) return LOCATIONS_INSIGHTS.GLOBAL;
    if (!!country && !!asset) return LOCATIONS_INSIGHTS.ASSET;
  },
  ...LOCATIONS_INSIGHTS,
};
