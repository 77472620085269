<template>
  <div class="bar-container">
    <span
      :class="`bar-value bar-size-${value}`"
      :data-value="`${value / coef}${text}`"
      :style="`background-color: ${color};`"
    />
  </div>
</template>
<script>
import theme from '@/theme';

export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    value: {
      type: [Number, String],
      default: 0,
    },
    coef: {
      type: Number,
      default: 1,
    },
    color: {
      type: String,
      default: theme.bhpBlue1,
    },
  },
};
</script>
<style lang="scss" scoped>
@use 'sass:math';
@import '@styles/_colors.scss';

.bar-container {
  position: relative;
  height: 25px;
  vertical-align: middle;
  width: calc(100% - 35px);
  .bar-value {
    height: 20px;
    background-color: red;
    position: absolute;
    top: calc(50% - 10px);
    -webkit-transition: width 1s ease-in-out, background-color 1s ease-in-out;
    -moz-transition: width 1s ease-in-out, background-color 1s ease-in-out;
    -o-transition: width 1s ease-in-out, background-color 1s ease-in-out;
    transition: width 1s ease-in-out, background-color 1s ease-in-out;
    &::after {
      content: attr(data-value);
      position: absolute;
      right: 0;
      margin-right: -38px;
    }
    @for $value from 0 to 101 {
      &.bar-size-#{$value} {
        width: calc(#{math.percentage($value / 100)});
      }
    }
  }
}
</style>
