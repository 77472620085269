<template>
  <v-app>
    <v-main>
      <v-container fluid class="fill-height pa-0">
        <v-row no-gutters class="layout">
          <Header />
          <slot />
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Header from '@components/Header';

export default {
  components: {
    Header,
  },
};
</script>
