
<v-select
  :label="label"
  :class="`bhp-select select-width-${width}`"
  v-model="period"
  outlined
  dense
  append-icon="mdi-chevron-down"
  :items="items"
  hide-details
  :menu-props="{ offsetY: true, contentClass: 'bhp-select-list' }"
  :item-text="itemText"
  :item-value="itemValue"
  :key="digitalIntelPeriod"
/>
