import { CardBaseService } from './CardBaseService';
import { getCodeFromCountries } from '@utils/countries';
import Insights from '@utils/insights';
import { FILTERS_TYPES } from '@/utils/panel-filters';
import { CARDS_TYPES } from '@services/CardDataService.js';
import { toPercent } from '@/utils/helpers';
import { isAudienceFilterHidden } from '@/utils/countries';

export default class ReputationDriverService extends CardBaseService {
  static getCardType() {
    return CARDS_TYPES.REPUTATION_DRIVER;
  }

  static getChartTitle(location = { country: null, asset: null }) {
    const INSIGHT = Insights.get(location.country, location.asset);

    if (INSIGHT === Insights.ASSET) return 'Leading Drivers of Reputation';

    return 'Leading Drivers of Reputation: Performance Over Time';
  }

  static getChartComponentName() {
    return 'ReputationDriverTable';
  }

  static getFilters(defaultFilters = {}, location = { country: null, asset: null }) {
    const INSIGHT = Insights.get(location.country, location.asset);

    const { defaultSentiment, defaultAudience, defaultDemographic } = defaultFilters;

    const audience =
      INSIGHT === Insights.ASSET || INSIGHT === Insights.ALL_HOST_COMMUNITIES
        ? 'Host Community'
        : defaultAudience;

    return {
      audience: audience ?? null,
      demographic: defaultDemographic ?? null,
      sentiment: defaultSentiment ?? null,
      community: this.getDefaultCommunity(location.asset, location.country),
    };
  }

  static getFiltersConfig(model, { country, asset }) {
    const INSIGHT = Insights.get(country, asset);

    return [
      {
        type: FILTERS_TYPES.DEMOGRAPHIC,
        model,
        key: 'demographic',
        country,
        isLocal: !!asset,
        isHidden: INSIGHT === Insights.GLOBAL,
      },
      {
        type: FILTERS_TYPES.AUDIENCE,
        model,
        key: 'audience',
        isHidden: isAudienceFilterHidden(country, asset),
      },
      {
        type: FILTERS_TYPES.COMMUNITY,
        model,
        key: 'community',
        isHidden: INSIGHT !== Insights.ASSET,
        items: this.getCommunities(asset, country),
      },
      { type: FILTERS_TYPES.SENTIMENT, model, key: 'sentiment' },
    ];
  }

  static getReportPageConfig({ location = {}, filters, data }) {
    let groupBy = null;
    const INSIGHT = Insights.get(location.country, location.asset);

    if (INSIGHT === Insights.GLOBAL) groupBy = 'country';
    else if (INSIGHT === Insights.ALL_HOST_COMMUNITIES) groupBy = 'asset';

    return {
      type: this.getCardType(),
      title: this.getChartTitle(location),
      filters: {
        ...filters,
        country: location.country,
        asset: location.asset,
      },
      data: {
        items: data,
        groupBy,
      },
      rows: this.getRows(data, filters, location.country, location.asset),
    };
  }

  static getRows(data, filters, country, asset) {
    const rows = [];
    const INSIGHT = Insights.get(country, asset);

    data.forEach((item) => {
      const row = {};
      if (INSIGHT === Insights.GLOBAL) {
        row.Country = item.country;
      }
      row.Driver = item.name;
      row['Raw driver score'] = toPercent(item.rawDriverScore);
      row['Most recent performance'] = toPercent(item.score);

      item.performanceOverTime.forEach((item) => {
        // Have to add space before year to avoid columns being added to the left
        row[` ${item.year}`] = item.value === undefined ? '' : toPercent(item.value);
      });
      rows.push(row);
    });

    // Sort rows alphabetically by country
    rows.sort((a, b) => {
      if (a.Country < b.Country) return -1;
      if (a.Country > b.Country) return 1;
      return 0;
    });

    // Add description of filters to last row
    if (rows.length && filters?.audience) {
      this.addFiltersToRows(rows, filters, country, asset);
    }
    return rows;
  }

  static humanizeFilters({ audience, demographic, sentiment, country, community }) {
    const { age, gender, shareholder, votingPref } = demographic;
    return {
      ...(country && { Country: country }),
      ...(community && { Community: community }),
      ...(!community && { Audience: audience }),
      Sentiment: sentiment,
      ...(country && { Age: age }),
      ...(country && { Gender: gender }),
      ...(country && { Shareholder: shareholder }),
      ...(country && { 'Voting Preference': votingPref }),
    };
  }

  static async loadData({ filters, location }) {
    const INSIGHT = Insights.get(location.country, location.asset);

    const params = {
      audience: filters.audience,
      sentiment: filters.sentiment,
      asset: location.asset,
      country: location.country,
      community: INSIGHT === Insights.ASSET ? filters.community : null,
      ...filters.demographic,
    };

    let items = await this.httpRequestService.get('/api/v2/reputation-driver', null, params, false);
    items = items.map((item) => ({
      ...item,
      iso: getCodeFromCountries(item.country),
    }));

    return items;
  }
}
