<template>
  <v-row no-gutters>
    <v-col>
      <div class="px-0 d-flex align-center">
        <Badge v-for="(color, key) in colors" :key="key" :text="key" :color="color" />
      </div>
    </v-col>
    <v-col cols="auto" class="svp__table">
      <v-simple-table>
        <thead>
          <tr class="svp__table__tr">
            <th class="svp__table__th">Lead indicator</th>
            <th class="svp__table__th">Performance rating</th>
            <th class="svp__table__th text-center">Sector leader</th>
            <th class="svp__table__th text-center">Gap-to-sector leader</th>
            <th class="svp__table__th text-center">Overall SV(P)I</th>
          </tr>
        </thead>
        <tbody>
          <tr class="svp__table__tr__trust">
            <td class="svp__table__td__attribute font-weight-bold">Trust</td>
            <td class="svp__table__td__attribute">
              <Bar :value="toPercent(datas.trust.performance)" text="%" :color="trustColor" />
            </td>
            <td class="svp__table__td" v-if="datas.trust.sectorleader">
              <template v-if="getCompanyImage(datas.trust.sectorleader)">
                <img
                  :src="leaderImage(datas.trust.sectorleader)"
                  style="max-width: 130px; max-height: 34px"
                  :title="datas.trust.sectorleader"
                />
              </template>
              <template v-else>
                {{ datas.trust.sectorleader }}
              </template>
            </td>
            <td class="svp__table__td" v-else>
              <v-tooltip bottom color="white" class="insufficient-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on"> mdi-circle-off-outline </v-icon>
                </template>
                <span class="black--text">Insufficient data available</span>
              </v-tooltip>
            </td>
            <td class="svp__table__td" v-if="!isNaN(datas.trust.gaptoleader)">
              {{ datas.trust.gaptoleader | gapToPercent }}
            </td>
            <td class="svp__table__td" v-else>
              <v-tooltip bottom color="white" class="insufficient-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on"> mdi-circle-off-outline </v-icon>
                </template>
                <span class="black--text">Insufficient data available</span>
              </v-tooltip>
            </td>
            <td :rowspan="dataCells.length + 1" class="svp__table__td__overall">
              <div class="fill-height">
                <activity-gauge
                  :svi="datas.overallsvpi.svpi"
                  :awareness="datas.overallsvpi.awareness"
                  class="svp-gauge"
                />
                <div v-if="datas.overallsvpi.svpi" class="text-center">
                  <b
                    :class="{
                      'positive-gap-to-average': datas.overallsvpi.gapToAverage > 0,
                      'negative-gap-to-average': datas.overallsvpi.gapToAverage < 0,
                    }"
                    >{{ datas.overallsvpi.gapToAverage }}</b
                  >
                  p.p. gap-to-average
                </div>
              </div>
            </td>
          </tr>
          <tr v-for="(dataCell, index) of dataCells" :key="index" class="svp__table__tr">
            <td class="svp__table__td__attribute">{{ dataCell.attribute }}</td>
            <td class="svp__table__td__attribute">
              <Bar
                :value="toPercent(dataCell.performance)"
                text="%"
                :color="colors[dataCell.theme]"
              />
            </td>
            <td class="svp__table__td" v-if="dataCell.sectorleader">
              <template v-if="getCompanyImage(dataCell.sectorleader)">
                <img
                  :src="leaderImage(dataCell.sectorleader)"
                  style="max-width: 130px; max-height: 34px"
                  :title="dataCell.sectorleader"
                />
              </template>
              <template v-else>
                {{ dataCell.sectorleader }}
              </template>
            </td>
            <td class="svp__table__td" v-else>
              <v-tooltip bottom color="white" class="insufficient-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on"> mdi-circle-off-outline </v-icon>
                </template>
                <span class="black--text">Insufficient data available</span>
              </v-tooltip>
            </td>
            <td class="svp__table__td" v-if="!isNaN(dataCell.gaptoleader)">
              {{ dataCell.gaptoleader | gapToPercent }}
            </td>
            <td class="svp__table__td" v-else>
              <v-tooltip bottom color="white" class="insufficient-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on"> mdi-circle-off-outline </v-icon>
                </template>
                <span class="black--text">Insufficient data available</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-col>
  </v-row>
</template>

<script>
import Badge from '@components/Badge.vue';
import Bar from '@components/Charts/Bar.vue';
import ActivityGauge from '@components/Charts/ActivityGauge.vue';
import theme from '@/theme';
import { getCompanyImage } from '@utils/companies';
import { toPercent } from '@utils/helpers';

const THEMES = {
  Community: theme.bhpCommunity,
  Corporate: theme.bhpCoporate,
  Economic: theme.bphEconomic,
  Environmental: theme.bhpEnvironmental,
};

export default {
  components: {
    Bar,
    ActivityGauge,
    Badge,
  },
  props: {
    datas: {
      type: Object,
      default: () => ({
        attributes: [],
        trust: {
          performance: null,
          gaptoleader: null,
          sectorleader: null,
        },
        overallsvpi: {
          awareness: null,
          svpi: null,
        },
      }),
    },
  },
  data() {
    return {
      colors: THEMES,
      trustColor: theme.bhpBlue1,
    };
  },
  computed: {
    dataCells() {
      return this.datas.attributes.filter((svpRow) => THEMES[svpRow?.theme]);
    },
  },
  methods: {
    getCompanyImage(company) {
      return getCompanyImage(company);
    },
    leaderImage(leader) {
      return typeof leader === 'string' ? `/images/companies/${getCompanyImage(leader)}` : leader;
    },
  },
  filters: {
    gapToPercent(value) {
      if (!isNaN(value)) {
        const roundedPercentValue = toPercent(value);
        return `${roundedPercentValue > 0 ? '+' : ''}${roundedPercentValue}`;
      }
      return value;
    },
  },
};
</script>
<style scoped lang="scss">
@import '@styles/_colors.scss';

.svp {
  &__table {
    width: 100%;
    &__th,
    &__td {
      text-decoration: none;
      text-align: center;
      &__theme {
        font-weight: 500;
      }
      &__attribute {
        text-align: left;
      }
      &__overall {
        background-color: white !important;
        border-bottom: none !important;
      }
    }
    &__tr {
      &--highlighted {
        background-color: $bhp-functional-grey-1;
      }
      &__trust {
        background-color: $bhp-nav-blue-grey-2;
      }
    }
  }
}
.theme_cell {
  display: inline-block;
  padding: 6px;
  width: 100%;
  border-radius: 2px;
}
.range_tile {
  display: inline-block;
  font-size: 0.6rem;
  padding: 3px;
  width: 160px;
  height: 30px;
  font-weight: bold;
  border-radius: 2px;
  text-align: center;
}

.svp-gauge {
  display: block;
  margin-right: auto;
  margin-left: auto;
}
</style>
