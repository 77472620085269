<template>
  <div class="comparison">
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th>Unprompted</th>
            <th>Percentage concerned</th>
            <th class="text-center">Change from previous wave</th>
            <th>Prompted</th>
            <th>Percentage concerned</th>
            <th class="text-center">Change from previous wave</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="datas.country">
            <tr
              v-for="n in effectiveQuantity"
              :key="countryCode + '-' + (n - 1)"
              class="content-row"
            >
              <td class="labelCol">
                <div class="d-flex">
                  <span class="mr-2">
                    <b>{{ n }}.</b>
                  </span>
                  <span>{{ effectiveConcernCountry.unprompted.concerns[n - 1] }}</span>
                </div>
              </td>
              <td class="otherCol">
                <Bar
                  :value="toPercent(effectiveConcernCountry.unprompted.data[n - 1])"
                  text="%"
                  :color="barColor"
                />
              </td>
              <td class="otherCol text-center">
                <v-icon v-if="!effectiveConcernCountry.unprompted.evolution[n - 1]">
                  mdi-minus
                </v-icon>
                <span
                  v-else
                  class="variation"
                  :style="`color: ${getVariationColor(
                    effectiveConcernCountry.unprompted.evolution[n - 1],
                  )};`"
                >
                  {{ getVaration(effectiveConcernCountry.unprompted.evolution[n - 1]) }}
                </span>
              </td>
              <td class="labelCol">
                <div class="d-flex">
                  <span class="mr-2">
                    <b>{{ n }}.</b>
                  </span>
                  <span>{{ effectiveConcernCountry.prompted.concerns[n - 1] }}</span>
                </div>
              </td>
              <td class="otherCol">
                <Bar
                  :value="toPercent(effectiveConcernCountry.prompted.data[n - 1])"
                  text="%"
                  :color="barColor"
                />
              </td>
              <td class="lastCol text-center">
                <v-icon v-if="!effectiveConcernCountry.prompted.evolution[n - 1]">
                  mdi-minus
                </v-icon>
                <span
                  v-else
                  class="variation"
                  :style="`color: ${getVariationColor(
                    effectiveConcernCountry.prompted.evolution[n - 1],
                  )};`"
                >
                  {{ getVaration(effectiveConcernCountry.prompted.evolution[n - 1]) }}
                </span>
              </td>
            </tr>
          </template>
          <template v-else-if="datas.concerns.length">
            <template v-for="concern in datas.concerns">
              <tr :key="concern.location + 'A'" v-if="!concern.noTitle">
                <td v-if="concern.iso" colSpan="6" class="px-0">
                  <v-subheader class="subheader">
                    <flag :iso="concern.iso" class="mr-2" />
                    {{ concern.location }}
                  </v-subheader>
                </td>
                <td v-else colSpan="6" class="px-0">
                  <v-subheader class="subheader">
                    {{ concern.location }}
                  </v-subheader>
                </td>
              </tr>
              <tr
                v-for="n in concern.unprompted.concerns.length"
                :key="concern.location + '-' + (n - 1)"
                class="content-row"
              >
                <td class="labelCol">
                  <div class="d-flex">
                    <span class="mr-2">
                      <b>{{ n }}.</b>
                    </span>
                    <span>{{ concern.unprompted.concerns[n - 1] }}</span>
                  </div>
                </td>
                <td class="otherCol">
                  <Bar
                    :value="toPercent(concern.unprompted.data[n - 1])"
                    text="%"
                    :color="barColor"
                  />
                </td>
                <td class="otherCol text-center">
                  <v-icon v-if="!concern.unprompted.evolution[n - 1]"> mdi-minus </v-icon>
                  <span
                    v-else
                    class="variation"
                    :style="`color: ${getVariationColor(concern.unprompted.evolution[n - 1])};`"
                  >
                    {{ getVaration(concern.unprompted.evolution[n - 1]) }}
                  </span>
                </td>
                <td class="labelCol">
                  <div class="d-flex">
                    <span class="mr-2">
                      <b>{{ n }}.</b>
                    </span>
                    <span>{{ concern.prompted.concerns[n - 1] }}</span>
                  </div>
                </td>
                <td class="otherCol">
                  <Bar
                    :value="toPercent(concern.prompted.data[n - 1])"
                    text="%"
                    :color="barColor"
                  />
                </td>
                <td class="lastCol text-center">
                  <v-icon v-if="!concern.prompted.evolution[n - 1]"> mdi-minus </v-icon>
                  <span
                    v-else
                    class="variation"
                    :style="`color: ${getVariationColor(concern.prompted.evolution[n - 1])};`"
                  >
                    {{ getVaration(concern.prompted.evolution[n - 1]) }}
                  </span>
                </td>
              </tr>
            </template>
          </template>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { getCodeFromCountries } from '@utils/countries';
import Bar from '@components/Charts/Bar.vue';
import theme from '@/theme';

export default {
  components: {
    Bar,
  },
  props: {
    datas: {
      type: Object,
      default: () => ({
        concerns: [],
        country: null,
      }),
    },
  },
  data() {
    return {
      barColor: theme.bhpBlue2,
    };
  },
  methods: {
    getVariationColor(evolution) {
      if (!evolution || evolution === 0) return null;
      const fixedEvolution =
        evolution < 0 ? this.toPercent(evolution * -1) : this.toPercent(evolution);
      if (fixedEvolution === '0') return null;
      return evolution < 0 ? theme.bhpNegativeRed : theme.bhpPositiveGreen;
    },
    getVaration(evolution) {
      if (!evolution || evolution === 0) return '0';
      const fixedEvolution =
        evolution < 0 ? this.toPercent(evolution * -1) : this.toPercent(evolution);
      if (fixedEvolution === '0') return fixedEvolution;
      return `${evolution < 0 ? '-' : '+'}${fixedEvolution}`;
    },
  },
  computed: {
    countryCode() {
      return getCodeFromCountries(this.datas.country);
    },
    effectiveQuantity() {
      const concern = this.datas.concerns.find(
        (concern) => concern.location === this.datas.country,
      );
      if (!concern) return 0;
      return concern.prompted.data.length;
    },
    effectiveConcernCountry() {
      const concern = this.datas.concerns.find(
        (concern) => concern.location === this.datas.country,
      );
      return concern;
    },
  },
};
</script>

<style lang="scss">
@import '@styles/_colors.scss';
@import '@styles/_fonts.scss';
.comparison {
  .country {
    color: $bhp-blue-1;
  }
  .country::first-letter,
  .type::first-letter {
    text-transform: uppercase;
  }
  .type {
    width: 100%;
    padding: 4px 0 4px 24px;
    border-top: 2px solid $bhp-functional-grey-1;
    border-bottom: 2px solid $bhp-functional-grey-1;
    color: $bhp-text-grey;
  }
  .rows {
    gap: 24px;
  }
  .tableau {
    .rowed {
      width: 100%;
    }
    > .rowed:last-child {
      border-bottom: 2px solid $bhp-functional-grey-1;
    }
    > .rowed:nth-child(2n) {
      background: $bhp-functional-grey-1;
    }
  }
  .subheader {
    background-color: $bhp-functional-grey-1;
    font-size: $bhp-title-font-size;
    font-weight: bold;
    height: 30px;
  }
  .labelCol {
    border-left: 2px dashed $bhp-functional-grey-1;
    width: 30%;
  }
  .lastCol {
    border-right: 2px dashed $bhp-functional-grey-1;
    width: 10%;
  }
  .otherCol {
    width: 10%;
  }

  .variation {
    font-weight: 500;
    height: 24px;
    font-size: 20px;
  }

  .content-row {
    td {
      padding-top: 4px !important;
      padding-bottom: 4px !important;
    }
  }
}
</style>
