import httpRequestService from '@/services/HttpRequestService';
import download from '@/mixins/download';

const getDefaultReportState = () => ({
  title: 'Custom Report',
  pages: [],
});

const getDefaultState = () => {
  return {
    addOnBuilder: getDefaultReportState(),
    DIYBuilder: getDefaultReportState(),
  };
};

const state = getDefaultState();

const getters = {
  addOnBuilderPages: (state) => state.addOnBuilder.pages,
  addOnBuilderTitle: (state) => state.addOnBuilder.title,
  addOnBuilderPagesCount: (_, getters) => getters.addOnBuilderPages.length,
  addOnBuilderScreenshots: (_, getters) => getters.addOnBuilderPages
    .map(({ screenshot }) => screenshot),
  addOnBuilderCsvData: (_, getters) => getters.addOnBuilderPages
    .map(({ title, rows }) => ({ title, rows })),
  DIYBuilderPages: (state) => state.DIYBuilder.pages,
  DIYBuilderTitle: (state) => state.DIYBuilder.title,
  DIYBuilderScreenshots: (_, getters) => getters.DIYBuilderPages
    .map(({ data }) => data.screenshot),
  DIYBuilderCsvData: (_, getters) => getters.DIYBuilderPages
    .map(({ data }) => ({ title: data.title, rows: data.rows })),
};

const mutations = {
  resetAddOnBuilder(state) {
    state.addOnBuilder = getDefaultReportState();
  },
  setAddOnBuilderTitle(state, title) {
    state.addOnBuilder.title = title;
  },
  setAddOnBuilderPages(state, pages) {
    state.addOnBuilder.pages = pages;
  },
  setDIYBuilderTitle(state, title) {
    state.DIYBuilder.title = title;
  },
  setDIYBuilderPages(state, pages) {
    state.DIYBuilder.pages = pages;
  },
  resetDIYBuilder(state) {
    state.DIYBuilder = getDefaultReportState();
  },
};

const actions = {
  async downloadReport({ getters }, { type, reportType }) {
    const title = reportType === 'ADD_ON_BUILDER' ? getters.addOnBuilderTitle : getters.DIYBuilderTitle;

    if (type === 'CSV') {
      const cvsData = reportType === 'ADD_ON_BUILDER' ? getters.addOnBuilderCsvData : getters.DIYBuilderCsvData;
      download.methods.downloadMultipleCharts(title, cvsData);
    } else {
      const screenshots = reportType === 'ADD_ON_BUILDER' ? getters.addOnBuilderScreenshots : getters.DIYBuilderScreenshots;
      const result = await httpRequestService.post('/api/v2/report-builder', {
        type,
        title,
        pages: screenshots,
      }, false);
      download.methods.createDownloadFileUrl(`${title}.${type.toLowerCase()}`, result);
    }
  },
  addAddOnReportPage({ commit, state }, { id, screenshot, rows, title }) {
    commit('setAddOnBuilderPages', [...state.addOnBuilder.pages, { id, screenshot, rows, title }]);
  },
  deleteAddOnReportPage({ state, commit }, id) {
    const addOnBuilderPages = state.addOnBuilder.pages.filter(page => page.id !== id);
    commit('setAddOnBuilderPages', addOnBuilderPages);
  },
  clearAddOnBuilderPages({ commit }) {
    commit('resetAddOnBuilder');
  },
  clearDIYBuilderPages({ commit }) {
    commit('resetDIYBuilder');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
