
<div :class="[showBorder ? 'ml-4 pl-4' : '', 'actions']">
  <v-tooltip
    v-if="!reportDisabled"
    bottom
    color="white"
    content-class="action-tooltip"
    nudge-top="6"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :disabled="isGeneratingReport || showNoData"
        outlined
        tile
        icon
        v-bind="attrs"
        v-on="on"
        class="action-btn mr-4"
        @click="addToReport"
      >
        <v-icon> create_new_folder </v-icon>
      </v-btn>
    </template>
    <span>Add to report</span>
  </v-tooltip>
  <v-tooltip
    v-if="!downloadDisabled"
    bottom
    color="white"
    content-class="action-tooltip"
    nudge-top="6"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :disabled="showNoData"
        outlined
        tile
        icon
        v-bind="attrs"
        v-on="on"
        class="action-btn mr-4"
        @click="downloadChart"
      >
        <span class="material-icons-outlined"> file_download </span>
      </v-btn>
    </template>
    <span class="text--black">Download chart</span>
  </v-tooltip>
</div>
