import { CardBaseService } from './CardBaseService';
import { getCodeFromCountries } from '@utils/countries';
import store from '@/store';
import Insights from '@utils/insights';
import { CARDS_TYPES } from '@services/CardDataService.js';

export default class GlobalMapService extends CardBaseService {
  static getCardType() {
    return CARDS_TYPES.GLOBAL_MAP;
  }

  static getChartTitle(location = { country: null, asset: null }) {
    const INSIGHT = Insights.get(location.country, location.asset);

    if (INSIGHT === Insights.NATIONAL) return `${location.country} National Overview`;

    return 'Global Overview';
  }

  static getChartComponentName() {
    return 'GlobalMapChart';
  }

  static humanizeFilters(filters) {
    if (filters.country) {
      return {
        'Nb Hosts Communities': store.state.selects.assetsAndCommunitiesByCountries.find(
          (country) => country.country,
        ).assets.length,
      };
    }
    return {
      'Nb Countries': store.state.selects.assetsAndCommunitiesByCountries.length,
      'Nb Hosts Communities': store.state.selects.assetsAndCommunitiesByCountries.reduce(
        (acc, country) => acc + country.assets.length,
        0,
      ),
    };
  }

  static getReportPageConfig({ location = {}, data }) {
    return {
      type: this.getCardType(),
      title: this.getChartTitle(location),
      filters: {
        country: location.country,
      },
      data: {
        datas: data,
      },
      rows: this.getRows(location.country, data),
    };
  }

  static getRows(country, data) {
    const rows = [];
    data.assetsAndCommunitiesByCountries.forEach((assetsByCountry) => {
      if ((country && country === assetsByCountry.country) || !this.country) {
        rows.push(
          ...assetsByCountry.assets.map((asset) => ({
            'Hosts Communities': asset.asset_label,
            Country: assetsByCountry.country,
            Latitude: asset.latitude,
            Longitude: asset.longitude,
            ShortName: asset.asset_short,
          })),
        );
      }
    });
    return rows;
  }

  static async loadData({ location }) {
    const iso = location.country ? getCodeFromCountries(location.country) : 'world';

    const map = await this.httpRequestService.get(
      '/api/v2/map',
      null,
      {
        iso,
      },
      false,
    );

    return {
      map,
      iso,
      assetsAndCommunitiesByCountries: store.state.selects.assetsAndCommunitiesByCountries,
    };
  }

  static getReportConfiguration() {
    return {
      ignoreElements: (element) => {
        return (
          typeof element.className === 'object' &&
          element.className.baseVal.indexOf('highcharts-map-navigation') >= 0
        );
      },
      backgroundColor: 'null',
    };
  }
}
