<template>
  <div class="activitygauge" :class="{ nodata: !svi }" :style="`width:${width}px;`">
    <highcharts :options="chart"></highcharts>
  </div>
</template>

<script>
import theme from '@/theme';
export default {
  props: {
    svi: {
      type: Number,
      default: 0,
    },
    awareness: {
      type: Number,
      default: 0,
    },
    noLabel: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [Number, String],
      default: 122,
    },
  },
  data() {
    return {
      chart: {},
    };
  },
  computed: {
    allData() {
      return { svi: this.svi, awareness: this.awareness };
    },
    label() {
      if (this.svi) {
        return `<b>${this.svi}</b>${!this.noLabel ? '</b><small>SV(P)I</small>' : ''}`;
      } else {
        return '<small style="text-align:center">No<br>SV(P)I<br>Data</small>';
      }
    },
  },
  methods: {
    updateChart() {
      let { svi, awareness } = this.allData;
      const noLabel = this.noLabel;
      svi = Math.round(svi);
      awareness = Math.round(awareness);

      const series = [
        {
          lineWidth: 115,
          dataLabels: {
            format: this.label,
          },
          data: [
            {
              color: theme.bhpChartLightGreen,
              radius: '100%',
              innerRadius: '77%',
              y: awareness,
            },
          ],
        },
        {
          lineWidth: 115,
          data: [
            {
              color: theme.bhpChartBlue,
              radius: '74%',
              innerRadius: '45%',
              y: svi,
            },
          ],
        },
      ];

      if (isNaN(svi) && isNaN(awareness)) {
        series[0].dataLabels.format = '<small>insufficient data</small>';
        series[0].data[0].y = 0;
        series[1].data[0].y = 0;
      }

      this.chart = {
        chart: {
          type: 'solidgauge',
          width: this.width,
          height: this.width,
          spacingTop: 0,
          spacingRight: 0,
          spacingBottom: 0,
          spacingLeft: 0,
          style: {
            fontFamily: "'Graphik Web', sans-serif",
          },
        },

        title: {
          text: '',
        },

        credits: {
          enabled: false,
        },

        pane: {
          startAngle: 0,
          endAngle: 360,
          background: [
            {
              outerRadius: '100%',
              innerRadius: '77%',
              backgroundColor: theme.bhpFunctionalGrey1,
              borderWidth: 0,
            },
            {
              outerRadius: '74%',
              innerRadius: '45%',
              backgroundColor: theme.bhpFunctionalGrey1,
              borderWidth: 0,
            },
          ],
        },

        yAxis: {
          min: 0,
          max: 100,
          lineWidth: 0,
          tickPositions: [],
        },

        plotOptions: {
          solidgauge: {
            dataLabels: {
              y: -25,
              x: -5,
              borderWidth: 0,
              useHTML: true,
            },
            linecap: 'round',
            stickyTracking: false,
          },
        },

        tooltip: {
          borderWidth: 0,
          backgroundColor: 'none',
          shadow: false,
          useHTML: true,
          className: '_tooltipActivityGauge',
          formatter() {
            if (isNaN(svi) && isNaN(awareness)) {
              return '<small>insufficient data</small>';
            }
            return `<span class="data><b style="color:${this.color}">${this.y}</b><small>${
              !noLabel ? (this.color === theme.bhpChartBlue ? 'SV(P)I' : 'Awareness') : ''
            }</small></span>`;
          },
          positioner: function () {
            return {
              x: 0,
              y: 0,
            };
          },
        },

        series: series,
      };
    },
  },
  mounted() {
    this.updateChart();
  },
  watch: {
    svi() {
      this.updateChart();
    },
    awareness() {
      this.updateChart();
    },
  },
};
</script>

<style lang="scss">
@import '@styles/_colors.scss';

.activitygauge {
  &.nodata {
    pointer-events: none;
  }

  .highcharts-data-labels {
    left: 50% !important;
    top: 50% !important;
  }
  .highcharts-data-label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    left: 0 !important;
    top: 0 !important;
    span {
      position: static !important;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 50px;
      height: 50px;
    }
    b {
      color: $bhp-blue-2;
      font-size: 24px;
      display: block;
      font-weight: 500;
      line-height: 1;
    }
    small {
      font-size: 8px;
      font-weight: 400;
      color: $bhp-text-grey;
    }
  }
  ._tooltipActivityGauge {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    span {
      position: static !important;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: $bhp-white;
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    b {
      color: $bhp-blue-2;
      font-size: 24px;
      display: block;
      font-weight: 500;
      line-height: 1;
    }
    small {
      font-size: 8px;
      font-weight: 400;
      color: $bhp-text-grey;
    }
  }
}
</style>
