
<div
  class="corp-rep-chart d-flex justify-space-between overflow-x-auto overflow-y-hidden"
  v-if="items.type"
>
  <div
    class="theme-container d-flex flex-column-reverse align-center px-4"
    :class="{
      'align-self-end': !spreadData,
    }"
  >
    <div
      v-for="theme in REVERSE_THEMES"
      :key="theme.name"
      class="mt-1 py-1 font-weight-medium text-uppercase white--text text-center"
      :style="{ 'background-color': theme.color, width: theme.width }"
    >
      {{ theme.name }}
    </div>
  </div>

  <div
    class="answers-container d-flex align-self-end"
    :class="{
      'flex-wrap': spreadData,
    }"
  >
    <div
      v-if="(items.type === 'national' || items.type === 'asset') && items.benchmark"
      class="d-flex flex-column align-self-end align-center px-4 font-weight-medium answer-column"
      :class="{
        'flex-grow-1 flex-shrink-1': !spreadData,
        'ma-2': spreadData,
      }"
    >
      <span class="d-flex flex-column align-center answer-column-title">
        <span v-if="columnTitleProperty !== 'logo'">
          {{ items.benchmark.name }}
        </span>
        <v-img
          v-else
          :src="`/images/companies/${items.benchmark.logo}.svg`"
          :aspect-ratio="16 / 9"
          :width="48"
          :title="items.benchmark.name"
          contain
        />
        <small class="benchmark" v-if="columnTitleProperty === 'name'"> (Benchmark) </small>
      </span>
      <template v-if="items.benchmark?.data.length">
        <template v-for="({ name, color }, index) in THEMES">
          <div
            v-if="typeof getValue(items.benchmark.data, name) !== 'undefined'"
            :key="index"
            :data-value="`${getValue(items.benchmark.data, name)}%`"
            :class="[index > THEMES.length / 2 ? 'black--text' : 'white--text']"
            class="mt-1 py-1 font-weight-medium text-uppercase text-center"
            :style="{
              'background-color': color,
              width: `${getValue(items.benchmark.data, name)}%`,
            }"
          ></div>
          <div v-else :key="name" class="mt-1 py-1">
            <InsufficientDataTooltip />
          </div>
        </template>
      </template>
      <template v-else>
        <div style="height: 270px; margin-top: 36px">
          <InsufficientDataTooltip />
        </div>
      </template>
    </div>
    <template v-if="items.items.length">
      <div
        v-for="(answer, index) in items.items"
        :key="answer.label || index"
        :class="{
          'd-none': !answer[columnTitleProperty],
          'd-flex': answer[columnTitleProperty],
          'flex-grow-1 flex-shrink-1': !spreadData,
          'ma-2': spreadData,
        }"
        class="flex-column align-self-end align-center px-4 font-weight-medium answer-column"
      >
        <CorporateReputationChartCompanyHeader
          :answer="answer"
          :columnTitleProperty="columnTitleProperty"
        />

        <template v-for="({ name, color }, index) in THEMES">
          <div
            v-if="typeof getValue(answer.data, name) !== 'undefined'"
            :key="index"
            :data-value="`${getValue(answer.data, name)}%`"
            :class="[index > THEMES.length / 2 ? 'black--text' : 'white--text']"
            class="mt-1 py-1 font-weight-medium text-uppercase text-center"
            :style="{ 'background-color': color, width: `${getValue(answer.data, name)}%` }"
          ></div>

          <div v-else :key="name" class="mt-1 py-1">
            <InsufficientDataTooltip />
          </div>
        </template>
      </div>
    </template>
    <template v-else>
      <div
        class="d-flex flex-column align-self-end align-center px-4 font-weight-medium answer-column"
        :class="{
          'flex-grow-1 flex-shrink-1': !spreadData,
          'ma-2': spreadData,
        }"
      >
        <span
          class="d-flex flex-column align-center mb-2 text-center justify-start answer-column-title"
        >
          <span> Insufficient data available </span>
        </span>
        <div style="height: 270px; margin-top: 36px">
          <InsufficientDataTooltip />
        </div>
      </div>
    </template>
  </div>
</div>
