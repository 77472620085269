import { CardBaseService } from './CardBaseService';
import { FILTERS_TYPES } from '@/utils/panel-filters';
import Insights from '@utils/insights';
import { CARDS_TYPES } from '@services/CardDataService.js';
import { toPercent } from '@/utils/helpers';
import { HOST_COMMUNITY } from '@/utils/constants';
import { getDefaultTimePeriod } from '@/utils/defaultTimePeriods';

export default class CommunityConcernsService extends CardBaseService {
  static getCardType() {
    return CARDS_TYPES.COMMUNITY_CONCERNS;
  }

  static getChartTitle(location) {
    const INSIGHT = Insights.get(location.country, location.asset);

    if (INSIGHT === Insights.ALL_HOST_COMMUNITIES) {
      return 'Leading Drivers of Reputation: Sector Comparison';
    }

    return 'Social Value Perception';
  }

  static getChartComponentName() {
    return 'CommunityConcernsChart';
  }

  static getFilters(defaultFilters = {}, location = { country: null, asset: null }) {
    const { defaultDemographic, defaultTimeperiod } = defaultFilters;

    return {
      demographic: defaultDemographic ?? null,
      year: getDefaultTimePeriod(location) ?? defaultTimeperiod ?? null,
    };
  }

  static getFiltersConfig(model, { country, asset }) {
    const INSIGHT = Insights.get(country, asset);

    return [
      {
        type: FILTERS_TYPES.DEMOGRAPHIC,
        model,
        key: 'demographic',
        country,
        isLocal: !!asset,
        isHidden: INSIGHT === Insights.GLOBAL,
      },
      { type: FILTERS_TYPES.TIME_PERIOD, model, key: 'year' },
    ];
  }

  static getReportPageConfig({ location = {}, filters, data }) {
    return {
      type: this.getCardType(),
      title: this.getChartTitle(location),
      filters: {
        ...filters,
        country: location.country,
        asset: location.asset,
      },
      data: {
        cards: data,
        cols: 2,
      },
      rows: this.getRows(data, filters, location.country, location.asset),
    };
  }

  static getRows(data, filters, country, asset) {
    const rows = data.map((value) => ({
      Country: value.country,
      Asset: value.asset,
      Awareness: toPercent(value.awareness),
      'SV(P)I': toPercent(value.svpi),
      ...value.pillars.reduce((acc, curr) => {
        acc[`${curr.name} Pillar`] = toPercent(curr.score);
        return acc;
      }, {}),
      ...value.attributes.reduce((acc, curr, index) => {
        acc[`Top ${index + 1} Driver of Social Value`] = curr.attribute;
        return acc;
      }, {}),
    }));

    // Add description of filters to last row
    if (rows.length && filters?.year) {
      this.addFiltersToRows(rows, filters, country, asset);
    }
    return rows;
  }

  static humanizeFilters({ country, year, demographic }) {
    const { age, gender, shareholder, votingPref } = demographic;
    return {
      Country: country,
      'Time Period': year,
      Age: age,
      Gender: gender,
      Shareholder: shareholder,
      'Voting Preference': votingPref,
    };
  }

  static async loadData({ filters, location }) {
    const params = {
      period: filters.year,
      country: location.country,
      asset: location.asset,
      audience: HOST_COMMUNITY,
      ...filters.demographic,
    };

    const cards = await this.httpRequestService.get('/api/v2/svp-comparison', null, params, false);

    return cards.map((card) => ({
      ...card,
      pillars: card.pillars.sort((a, b) => a.name.localeCompare(b.name)),
      attributes: card.attributes
        .sort((driver1, driver2) => (driver1.performance > driver2.performance ? -1 : 1))
        .slice(0, 5),
    }));
  }
}
