<template>
  <highcharts :options="chartOptions" :key="JSON.stringify(colors)"></highcharts>
</template>

<script>
import moment from 'moment';
import { mapColorsToCompanies } from '@/utils/companies';

export default {
  name: 'OpinionLeadersChart',

  props: {
    lines: {
      type: Array,
      required: true,
    },
    height: {
      type: [Number, String],
      default: 500,
    },
    showMonths: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    colors() {
      return mapColorsToCompanies(this.lines);
    },

    chartOptions() {
      const ctx = this;

      return {
        chart: {
          height: this.height,
          type: 'line',
          plotBorderWidth: 0,
          spacingRight: 1,
          spacingBottom: 1,
          spacingLeft: 0,
          style: {
            fontFamily: "'Graphik Web', sans-serif",
          },
        },

        title: {
          text: '',
        },

        credits: {
          enabled: false,
        },

        yAxis: {
          title: {
            text: 'Percentage',
          },
        },

        colors: this.colors,

        xAxis: {
          startOnTick: false,
          type: 'datetime',
          minPadding: 0,
          maxPadding: 0,
          labels: {
            format: ctx.showMonths ? '{value:%b %y}' : '{value:%Y}',
          },
        },

        tooltip: {
          useHTML: true,
          backgroundColor: null,
          borderWidth: 0,
          shadow: false,
          formatter() {
            return `<div class="_tooltip"><div class="_bar" style="background-color: ${
              this.color
            };"></div><div><b>${this.y.toFixed(0)}%</b></div>${this.series.name} - ${moment(
              this.x,
            ).format(ctx.showMonths ? 'MMM YYYY' : 'YYYY')}</div>`;
          },
        },

        legend: {
          align: 'right',
          padding: 20,
          itemStyle: {
            fontWeight: 'normal',
          },
        },

        plotOptions: {
          spline: {
            marker: {
              enabled: false,
              symbol: 'circle',
              lineColor: null,
              lineWidth: 0,
            },
          },
          series: [],
        },

        series:
          ctx.lines?.map((serie) => {
            const data = serie.data?.map(([d0, d1, ...d]) => [d0, d1 * 100, ...d]) || null;

            return {
              type: 'spline',
              name: serie.name,
              marker: {
                enabled: data?.length === 1,
              },
              states: {
                hover: {
                  lineWidth: 3,
                  halo: {
                    size: 0,
                  },
                },
              },
              data,
            };
          }) || [],
      };
    },
  },
};
</script>
