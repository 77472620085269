<template>
  <v-row>
    <v-col class="col-12">
      <h4>News Heard about BHP</h4>
      <NewsHeardChart :lines="datas?.newsHeard" :showMonths="showMonths" />
    </v-col>
    <v-col class="col-12">
      <h4>News Impact on Favourability</h4>
      <NewsFavourabilityChart :lines="datas?.newsAwareness" :showMonths="showMonths" />
    </v-col>
  </v-row>
</template>

<script>
import NewsFavourabilityChart from './NewsFavourabilityChart.vue';
import NewsHeardChart from './NewsHeardChart.vue';

export default {
  components: {
    NewsHeardChart,
    NewsFavourabilityChart,
  },

  props: {
    country: {
      type: String,
      default: null,
    },
    asset: {
      type: String,
      default: null,
    },
    datas: {
      type: Object,
      default: () => ({
        newsHeard: [],
        newsAwareness: {},
      }),
    },
    showMonths: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@styles/_colors.scss';

h4 {
  color: $bhp-blue-1;
  font-weight: 500;
  margin-bottom: 24px;
}
</style>
