<template>
  <component :is="layout">
    <slot />
  </component>
</template>

<script>
import Default from './DefaultLayout.vue';
import No from './NoLayout.vue';

export default {
  computed: {
    layout() {
      return this.$route.meta.layout === 'no' ? No : Default;
    },
  },
};
</script>
