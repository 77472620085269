import { CardBaseService } from './CardBaseService';
import { getCodeFromCountries } from '@utils/countries';
import { FILTERS_TYPES } from '@/utils/panel-filters';
import Insights from '@utils/insights';
import { CARDS_TYPES } from '@services/CardDataService.js';
import { toPercent } from '@/utils/helpers';
import { strToQuarterAndYear } from '@/utils/quarters';
import { getDefaultTimePeriod } from '@/utils/defaultTimePeriods';
import { AUSTRALIA, isAudienceFilterHidden } from '@/utils/countries';

export default class ComparisonConcernsService extends CardBaseService {
  static getCardType() {
    return CARDS_TYPES.COMPARISON_CONCERNS;
  }

  static getChartTitle() {
    return 'Main Issues of Concern';
  }

  static getChartComponentName() {
    return 'ComparisonConcernsChart';
  }

  static getFilters(defaultFilters = {}, location = { country: null, asset: null }) {
    const { defaultDemographic, defaultTimeperiod, defaultTimePeriodByQuarter, defaultAudience } =
      defaultFilters;

    return {
      demographic: defaultDemographic ?? null,
      audience: defaultAudience ?? null,
      year: getDefaultTimePeriod(location) ?? defaultTimeperiod ?? null,
      quarter: getDefaultTimePeriod(location) ?? defaultTimePeriodByQuarter ?? null,
      community: this.getDefaultCommunity(location.asset, location.country),
    };
  }

  static getFiltersConfig(model, { asset, country }) {
    const INSIGHT = Insights.get(country, asset);

    let timePeriod = { type: FILTERS_TYPES.TIME_PERIOD, model, key: 'year' };
    // For Australia national view, we use quarter periods
    if (INSIGHT === Insights.NATIONAL && country === AUSTRALIA) {
      timePeriod = { type: FILTERS_TYPES.TIME_PERIOD_BY_QUARTER, model, key: 'quarter' };
    }

    return [
      {
        type: FILTERS_TYPES.DEMOGRAPHIC,
        model,
        key: 'demographic',
        country,
        isLocal: !!asset,
        isHidden: INSIGHT === Insights.GLOBAL,
      },
      {
        type: FILTERS_TYPES.AUDIENCE,
        model,
        key: 'audience',
        isHidden: isAudienceFilterHidden(country, asset),
      },
      {
        type: FILTERS_TYPES.COMMUNITY,
        model,
        key: 'community',
        isHidden: INSIGHT !== Insights.ASSET,
        items: this.getCommunities(asset, country),
      },
      timePeriod,
    ];
  }

  static humanizeFilters({ country, year, community, asset, demographic, audience }) {
    const { age, gender, shareholder, votingPref } = demographic;
    const filters = {};
    if (country) {
      filters.Country = country;
    }
    if (asset && asset !== 'All') {
      filters.Asset = asset;
      filters.Community = community;
    }
    filters['Time Period'] = year;
    (!country || country === AUSTRALIA) && (filters.audience = audience);
    age && (filters.Age = age);
    gender && (filters.Gender = gender);
    shareholder && (filters.Shareholder = shareholder);
    votingPref && (filters.VotingPreference = votingPref);
    return filters;
  }

  static getReportPageConfig({ location = {}, filters, data }) {
    return {
      type: this.getCardType(),
      title: this.getChartTitle(),
      filters: {
        ...filters,
        country: location.country,
        asset: location.asset,
      },
      data: {
        datas: data,
      },
      rows: this.getRows(data, filters, location.country, location.asset),
    };
  }

  static getRows(data, filters, country, asset) {
    const rows = [];
    data.concerns.forEach((concern) => {
      for (let i = 0; i < concern.unprompted.concerns.length; i++) {
        const row = {};
        row['Name'] = concern.location;
        row['Unprompted concerns'] = concern.unprompted.concerns[i];
        row['Percentage concerned'] = toPercent(
          concern.unprompted.data[i],
        );
        row['Change from previous wave'] = concern.unprompted.evolution[i] === null ? 'null' : toPercent(
          concern.unprompted.evolution[i],
        );
        row['Prompted concerns'] = concern.prompted.concerns[i];
        row['Percentage concerned '] = toPercent(
          concern.prompted.data[i],
        );
        row['Change from previous wave '] = concern.prompted.evolution[i] === null ? 'null' : toPercent(
          concern.prompted.evolution[i],
        );
        rows.push(row);
      }
    });

    // Add description of filters to last row
    if (rows.length && filters?.year) {
      this.addFiltersToRows(rows, filters, country, asset);
    }
    return rows;
  }

  static getVaration(evolution) {
    if (!evolution || evolution === 0) return '';
    const fixedEvolution = evolution < 0 ? toPercent(evolution * -1) : toPercent(evolution);
    if (fixedEvolution === '0') return fixedEvolution;
    return `${evolution < 0 ? '-' : '+'}${fixedEvolution}`;
  }

  static async loadData({ filters, location }) {
    const INSIGHT = Insights.get(location.country, location.asset);

    const params = {
      period: filters.year,
      country: location.country,
      asset: location.asset,
      audience: filters.audience,
      community: INSIGHT === Insights.ASSET ? filters.community : null,
      ...filters.demographic,
    };

    // If Australia national view, we use quarter periods
    if (INSIGHT === Insights.NATIONAL && location.country === AUSTRALIA) {
      const { quarter, year } = strToQuarterAndYear(filters.quarter);
      params.period = year;
      params.quarter = quarter;
    }

    // Set audience for host communities
    if (INSIGHT === Insights.ALL_HOST_COMMUNITIES || INSIGHT === Insights.ASSET) {
      params.audience = 'Host Community';
    }

    const concernsData = await this.httpRequestService.get(
      '/api/v2/comparison-concern',
      null,
      params,
      false,
    );
    const concerns = [];
    if (INSIGHT === Insights.GLOBAL || INSIGHT === Insights.NATIONAL) {
      const countries = Array.from(
        new Set([
          ...concernsData.unprompted.map((card) => card.location[0]),
          ...concernsData.prompted.map((card) => card.location[0]),
        ]),
      );
      for (let i = 0; i < countries.length; i += 1) {
        const country = countries[i];
        const unpromptedCard = concernsData.unprompted.find((card) => card.location[0] === country);
        const promptedCard = concernsData.prompted.find((card) => card.location[0] === country);
        if (!unpromptedCard.concerns.length || !promptedCard.concerns.length) {
          continue;
        }
        concerns.push({
          location: country,
          iso: getCodeFromCountries(country),
          prompted: promptedCard,
          unprompted: unpromptedCard,
        });
      }
    } else if (INSIGHT === Insights.ALL_HOST_COMMUNITIES) {
      const assets = Array.from(
        new Set([
          ...concernsData.unprompted[0].map((card) => card.asset),
          ...concernsData.prompted[0].map((card) => card.asset),
        ]),
      );
      for (let index = 0; index < assets.length; index++) {
        const asset = assets[index];
        const unpromptedCard = concernsData.unprompted[0].find((card) => card.asset === asset);
        const promptedCard = concernsData.prompted[0].find((card) => card.asset === asset);
        if (!unpromptedCard.concerns.length || !promptedCard.concerns.length) {
          continue;
        }
        concerns.push({
          location: asset,
          prompted: promptedCard,
          unprompted: unpromptedCard,
        });
      }
    } else {
      const communities = Array.from(
        new Set([
          ...concernsData.unprompted.map((card) => card.community),
          ...concernsData.prompted.map((card) => card.community),
        ]),
      );
      for (let index = 0; index < communities.length; index++) {
        const community = communities[index];
        const unpromptedCard = concernsData.unprompted.find((card) => card.community === community);
        const promptedCard = concernsData.prompted.find((card) => card.community === community);
        if (!unpromptedCard.concerns.length || !promptedCard.concerns.length) {
          continue;
        }
        concerns.push({
          location: community,
          noTitle: true,
          prompted: promptedCard,
          unprompted: unpromptedCard,
        });
      }
    }

    return {
      concerns,
      country: INSIGHT === Insights.GLOBAL || INSIGHT === Insights.NATIONAL ? params.country : null,
    };
  }
}
