<template>
  <div class="gauge-container">
    <div v-if="socialValue">
      <highcharts :options="chart"></highcharts>
      <div class="shift positive" v-if="shiftValue > 0">+{{ shiftValue }}</div>
      <div class="shift negative" v-else-if="shiftValue < 0">{{ shiftValue }}</div>
      <div class="shift" v-else-if="shiftValue === 0">{{ shiftValue }}</div>
      <div class="shift" v-else>N/A</div>
    </div>
    <div v-else class="d-flex flex-column align-center justify-center text-center mt-5">
      <v-icon x-large> mdi-circle-off-outline </v-icon>
      <span class="font-weight-medium mt-2"> No Data Available </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    socialValue: {
      type: Number,
      default: 0,
    },
    shiftValue: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      chart: {},
    };
  },

  mounted() {
    this.makeChart();
  },

  methods: {
    makeChart() {
      this.chart = {
        chart: {
          type: 'solidgauge',
          height: '90px',
          marginTop: 0,
          marginBottom: 0,
          style: {
            fontFamily: "'Graphik Web', sans-serif",
          },
        },

        credits: {
          enabled: false,
        },

        pane: {
          startAngle: 0,
          endAngle: 360,
          background: [
            {
              outerRadius: '100%',
              innerRadius: '70%',
              backgroundColor: '#eee',
              borderWidth: 0,
            },
          ],
        },

        plotOptions: {
          solidgauge: {
            dataLabels: {
              enabled: true,
              borderWidth: 0,
              verticalAlign: 'middle',
              style: {
                fontSize: '16px',
              },
            },
            linecap: 'round',
            stickyTracking: false,
            rounded: true,
          },
        },

        series: [
          {
            data: [
              {
                color: this.color,
                radius: '100%',
                innerRadius: '70%',
                y: this.socialValue,
              },
            ],
          },
        ],

        title: {
          text: '',
        },

        tooltip: {
          enabled: false,
        },

        yAxis: {
          min: 0,
          max: 100,
          lineWidth: 0,
          tickPositions: [],
        },
      };
    },
  },

  watch: {
    socialValue() {
      this.makeChart();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/_colors.scss';

.shift {
  position: relative;
  text-align: center;
  z-index: 1;

  &.positive {
    color: $bhp-positive-green;
  }

  &.negative {
    color: $bhp-negative-red;
  }
}
</style>
