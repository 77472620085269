import store from '@/store';

export const FILTERS_TYPES = {
  CUSTOM: 'CUSTOM',
  COMMUNITIES: 'COMMUNITIES',
  COMPANIES: 'COMPANIES',
  POST_TYPE: 'POST_TYPE',
  REPUTATION: 'REPUTATION',
  KEY_ATTRIBUTE: 'KEY_ATTRIBUTE',
  AUDIENCE: 'AUDIENCE',
  COMMUNITY: 'COMMUNITY',
  DEMOGRAPHIC: 'DEMOGRAPHIC',
  SENTIMENT: 'SENTIMENT',
  TIME_PERIOD: 'TIME_PERIOD',
  TIME_PERIOD_BY_QUARTER: 'TIME_PERIOD_BY_QUARTER',
  DATE_RANGE: 'DATE_RANGE',
  DATE_RANGE_BY_QUARTER: 'DATE_RANGE_BY_QUARTER',
  DATE_RANGE_BY_PERIOD: 'DATE_RANGE_BY_PERIOD',
  DATE_RANGE_AND_BENCHMARK: 'DATE_RANGE_AND_BENCHMARK',
  DATE_RANGE_AND_BENCHMARK_BY_QUARTER: 'DATE_RANGE_AND_BENCHMARK_BY_QUARTER',
  COUNTRY: 'COUNTRY',
  COUNTRIES: 'COUNTRIES',
  ASSETS: 'ASSETS',
  TOPICS: 'TOPICS',
};

export const PANEL_FILTERS_DEFAULT_CONFIG = {
  [FILTERS_TYPES.CUSTOM]: {
    component: 'simple-select',
    width: '180',
  },
  [FILTERS_TYPES.COMMUNITIES]: {
    component: 'multi-select',
    label: 'Communities',
    width: '180',
    includeToggles: true,
  },
  [FILTERS_TYPES.COMPANIES]: {
    component: 'multi-select',
    label: 'Companies',
    width: '180',
    includeToggles: true,
  },
  [FILTERS_TYPES.POST_TYPE]: {
    component: 'multi-select',
    getter: () => ['News', 'Social'],
    label: 'Post Type',
    width: '180',
  },
  [FILTERS_TYPES.KEY_ATTRIBUTE]: {
    component: 'simple-select',
    getter: 'keyAttributs',
    label: 'Key Attribute',
    width: '180',
  },
  [FILTERS_TYPES.REPUTATION]: {
    component: 'simple-select',
    getter: () => ['By community', 'Against local peers'],
    label: 'Reputation',
    width: '220',
  },
  [FILTERS_TYPES.AUDIENCE]: {
    component: 'simple-select',
    getter: 'audiences',
    label: 'Audience',
    width: '180',
  },
  [FILTERS_TYPES.COMMUNITY]: {
    component: 'simple-select',
    label: 'Community',
    getter: () => [],
    width: '180',
  },
  [FILTERS_TYPES.COUNTRY]: {
    component: 'simple-select',
    getter: 'countries',
    label: 'Countries',
    width: '180',
    on: {
      select: (value) => store.dispatch('selects/setCountrySelected', value),
    },
  },
  [FILTERS_TYPES.COUNTRIES]: {
    component: 'multi-select',
    getter: 'countries',
    label: 'Countries',
    width: '180',
    'max-items': 5,
    'min-items': 1,
    'return-object': false,
    includeToggles: true,
  },
  [FILTERS_TYPES.ASSETS]: {
    component: 'multi-select',
    getter: 'assets',
    label: 'Assets',
    width: '180',
    includeToggles: true,
  },
  [FILTERS_TYPES.TOPICS]: {
    component: 'multi-select',
    getter: 'topics',
    label: 'Key Issues',
    width: '180',
    includeToggles: true,
    'item-text': 'name',
    'item-value': 'id',
  },
  [FILTERS_TYPES.DEMOGRAPHIC]: {
    component: 'tabbed-multi-select',
    getter: 'demographics',
    label: 'Demographic',
    width: '180',
  },
  [FILTERS_TYPES.SENTIMENT]: {
    component: 'simple-select',
    getter: 'sentiments',
    label: 'Sentiment',
    width: '180',
  },
  [FILTERS_TYPES.TIME_PERIOD]: {
    component: 'simple-select',
    getter: 'timePeriods',
    label: 'Time Period',
    width: '100',
  },
  [FILTERS_TYPES.TIME_PERIOD_BY_QUARTER]: {
    component: 'simple-select',
    getter: 'timePeriodsByQuarter',
    label: 'Time Period',
    width: '150',
  },
  [FILTERS_TYPES.DATE_RANGE]: {
    component: 'date-range-select',
    getter: 'timePeriods',
    label: 'Date Range',
    width: '180',
  },
  [FILTERS_TYPES.DATE_RANGE_BY_QUARTER]: {
    component: 'date-range-and-benchmark-by-quarter-select',
    getter: 'quarterPeriods',
    label: 'Date Range',
    hideBenchmark: true,
    width: '220',
  },
  [FILTERS_TYPES.DATE_RANGE_BY_PERIOD]: {
    component: 'date-range-by-period-select',
    getter: 'timePeriods',
    label: 'Time Period',
    width: '180',
  },
  [FILTERS_TYPES.DATE_RANGE_AND_BENCHMARK]: {
    component: 'date-range-and-benchmark-select',
    getter: 'dateRanges',
    label: 'Date Range & Benchmark',
    width: '180',
  },
  [FILTERS_TYPES.DATE_RANGE_AND_BENCHMARK_BY_QUARTER]: {
    component: 'date-range-and-benchmark-by-quarter-select',
    getter: 'quarterPeriods',
    label: 'Date Range & Benchmark',
    width: '220',
  },
};
