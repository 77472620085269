<template>
  <v-menu
    v-model="isMenuOpen"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    right
    min-width="auto"
    content-class="bhp-select-menu"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="selectText"
        :class="`bhp-select select-width-${width}`"
        @click:append="isMenuOpen = !isMenuOpen"
        :label="label"
        :append-icon="isMenuOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'"
        outlined
        dense
        hide-details
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>

    <v-list dense class="bhp-data-range-select-list">
      <template v-for="([key, label], index) in Object.entries(KEYS_TO_LABELS)">
        <v-menu v-if="items.length" :key="index" offset-x open-on-click>

          <template v-slot:activator="{ on, attrs }">
            <v-list-item class="v-list-item--link" v-bind="attrs" v-on="on">
              <v-list-item-title>
                {{ label }}
              </v-list-item-title>
              <v-list-item-action>
                <v-icon :color="color">
                  mdi-chevron-right
                </v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>

          <v-list dense class="bhp-data-range-select-list"  max-height="300">
            <v-list-item
              v-for="(date, indexDate) in items"
              :key="indexDate"
              class="v-list-item--link"
              @click="choseDate(key, date)"
            >
              <v-list-item-title>
                {{ date }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import theme from '@/theme';


const KEYS_TO_LABELS = {
  from: 'Date Start',
  to: 'Date End',
};

export default {
  name: 'date-range-select',

  props: {
    value: {
      type: Object,
      default: () => ({
        from: null,
        to: null,
      }),
    },
    label: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => ([]),
    },
    width: {
      type: [Number, String],
      default: 280,
    },
  },

  data: () => {
    return {
      isMenuOpen: false,
      color: theme.bhpBlue1,
      values: {
        from: null,
        to: null,
      },
      KEYS_TO_LABELS,
    };
  },

  watch: {
    'value.from'() {
      this.values.from = this.value.from;
    },
    'value.to'() {
      this.values.to = this.value.to;
    },
  },

  mounted() {
    this.values = Object.entries(this.values)
      .reduce((acc, [key, value]) => {
        acc[key] = this.value[key] ?? value;

        return acc;
      }, {});
  },

  computed: {
    selectText() {
      if (!this.values.from || !this.values.to) return null;
      return `${this.values.from} to ${this.value.to}`;
    },
  },

  methods: {
    choseDate(key, value) {
      this.values[key] = value;

      this.isMenuOpen = false;

      this.$emit('input', this.values);
    },
  },
};
</script>
