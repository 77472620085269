
<div>
  <div class="px-0 d-flex align-center">
    <Badge v-for="(color, key) in colors" :key="key" :text="key" :color="color" />
  </div>

  <v-data-table
    class="reputation-driver-table"
    :headers="headers"
    :items="items"
    hide-default-footer
    disable-sort
    disable-pagination
    item-key="name"
    :group-by="groupBy"
  >
    <template #header="{}">
      <thead class="v-data-table-top-header">
        <tr>
          <th colspan="2" class="body-2 text-center font-weight-medium black--text">
            Driver analysis
          </th>
          <th colspan="2" class="body-2 text-center font-weight-medium black--text">
            Performance analysis
          </th>
        </tr>
      </thead>
    </template>

    <template #[`group.header`]="{ items }">
      <td :colspan="headers.length" class="font-weight-medium">
        <flag v-if="groupBy === 'country'" :iso="items[0]['iso']" class="mb-n1 mr-2" size="36" />
        {{ items[0][groupBy] }}
      </td>
    </template>

    <template #item="{ item }">
      <tr>
        <td>{{ item.name }}</td>
        <td>
          <Bar :value="toPercent(item.rawDriverScore)" :coef="100" :color="colors[item.theme]" />
        </td>
        <td class="td-performance text-center font-weight-medium">
          {{ toPercent(item.score) }}%
        </td>
        <td class="td-chart">
          <ReputationDriverPerformanceOvertimeChart :data="item.performanceOverTime" />
        </td>
      </tr>
    </template>
  </v-data-table>
</div>
