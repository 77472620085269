
<div class="gauge-container">
  <div v-if="socialValue">
    <highcharts :options="chart"></highcharts>
    <div class="shift positive" v-if="shiftValue > 0">+{{ shiftValue }}</div>
    <div class="shift negative" v-else-if="shiftValue < 0">{{ shiftValue }}</div>
    <div class="shift" v-else-if="shiftValue === 0">{{ shiftValue }}</div>
    <div class="shift" v-else>N/A</div>
  </div>
  <div v-else class="d-flex flex-column align-center justify-center text-center mt-5">
    <v-icon x-large> mdi-circle-off-outline </v-icon>
    <span class="font-weight-medium mt-2"> No Data Available </span>
  </div>
</div>
