import { CardBaseService } from './CardBaseService';
import { CARDS_TYPES } from '@services/CardDataService.js';
import moment from 'moment';
import { FILTERS_TYPES } from '@/utils/panel-filters';
import { getIdFromCountry } from '@utils/countries';
import { mapCompanyNamesToIds, getCompanyNamesByCountryName } from '@/utils/companies';
import {
  getAssetsByCountry,
  getCommunitiesByAssets,
  mapCommunityNamesToIds,
  getAllCommunitiesIdsByCountry,
} from '@/utils/assetsAndCommunities';

export default class NewsService extends CardBaseService {
  static getCardType() {
    return CARDS_TYPES.NEWS;
  }

  static getChartTitle() {
    return 'News';
  }

  static getChartComponentName() {
    return 'NewsFeed';
  }

  static getFilters(defaultFilters = {}) {
    const { defaultCountry } = defaultFilters;

    return {
      country: defaultCountry ?? null,
      assets: [],
      topics: [],
      postType: [],
      companies: [],
      communities: [],
      reputation: 'Against local peers',
      period: {
        from: moment().subtract(7, 'd').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
        period: 0,
      },
    };
  }

  static getFiltersConfig(model) {
    return [
      { type: FILTERS_TYPES.POST_TYPE, label: 'Post Type', model, key: 'postType' },
      { type: FILTERS_TYPES.COUNTRY, label: 'Countries', model, key: 'country' },
      { type: FILTERS_TYPES.REPUTATION, label: 'Reputations', model, key: 'reputation' },
      {
        type: FILTERS_TYPES.COMPANIES,
        model,
        key: 'companies',
        items: getCompanyNamesByCountryName(model.country),
        isHidden: model.reputation !== 'Against local peers',
      },
      {
        type: FILTERS_TYPES.ASSETS,
        label: 'Assets',
        model,
        key: 'assets',
        items: getAssetsByCountry(model.country),
        isHidden: model.reputation === 'Against local peers',
      },
      {
        type: FILTERS_TYPES.COMMUNITIES,
        model,
        key: 'communities',
        items: getCommunitiesByAssets(model.assets),
        isHidden: model.reputation === 'Against local peers' || !model.assets.length,
      },
      { type: FILTERS_TYPES.TOPICS, label: 'Key Issues', model, key: 'topics' },
      { type: FILTERS_TYPES.DATE_RANGE_BY_PERIOD, label: 'Time Period', model, key: 'period' },
    ];
  }

  static async loadData({ filters, page, filtersConfig, customTimePeriod }) {
    // Don't pass companies if hidden
    const companiesConfig = filtersConfig.find((filter) => filter.key === 'companies');
    const companies = companiesConfig.isHidden ? [] : mapCompanyNamesToIds(filters.companies);

    // Dont pass assets if hidden
    const assetsConfig = filtersConfig.find((filter) => filter.key === 'assets');
    const assets = assetsConfig.isHidden ? [] : filters.assets;

    let communities = mapCommunityNamesToIds(filters.communities);
    // If no communities selected explicitly, pass all community ids for country
    !communities.length && (communities = getAllCommunitiesIdsByCountry(filters.country));
    // Dont pass communities if reputation is 'Against local peers'
    communities = filters.reputation === 'Against local peers' ? [] : communities;

    const countryId = getIdFromCountry(filters.country);

    const params = {
      from: customTimePeriod ? customTimePeriod.from : filters.period.from,
      to: customTimePeriod ? customTimePeriod.to : filters.period.to,
      countryIds: countryId ? [countryId] : null,
      assets: assets,
      topics: filters.topics.map((topic) => topic.id),
      reputation: filters.reputation,
      companies: companies,
      communities: communities,
      // Hardcoding post type name to ID for now
      postTypes: filters.postType.map((name) => {
        if (name === 'News') {
          return 1;
        } else if (name === 'Social') {
          return 2;
        }
      }),
      page,
    };

    const type = filters.reputation === 'Against local peers' ? 'Company' : 'Community';

    return this.httpRequestService.get(
      '/api/v2/digital-intelligence/news',
      null,
      {
        ...params,
        type,
        page,
      },
      false,
    );
  }
}
