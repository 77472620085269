<template>
  <img :src="`/images/flags/${iso}.png`" :class="flagClass" :title="title" />
</template>

<script>
export default {
  props: {
    iso: String,
    size: {
      type: [String, Number],
      default: 24,
    },
    squared: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
  computed: {
    flagClass() {
      if (this.squared) return `flag-square-${this.size}`;
      if (this.rounded) return `flag-round-${this.size}`;
      return `flag-${this.size}`;
    },
  },
};
</script>
<style lang="scss" scoped>
@for $value from 14 to 56 {
  .flag-#{$value} {
    width: #{$value}px;
    height: auto;
  }

  .flag-square-#{$value} {
    width: #{$value}px;
    height: #{$value}px;
  }

  .flag-round-#{$value} {
    width: #{$value}px;
    height: #{$value}px;
    border-radius: 50%;
  }
}
</style>
