
<div class="comparison">
  <v-simple-table dense>
    <template v-slot:default>
      <thead>
        <tr>
          <th>Unprompted</th>
          <th>Percentage concerned</th>
          <th class="text-center">Change from previous wave</th>
          <th>Prompted</th>
          <th>Percentage concerned</th>
          <th class="text-center">Change from previous wave</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="datas.country">
          <tr
            v-for="n in effectiveQuantity"
            :key="countryCode + '-' + (n - 1)"
            class="content-row"
          >
            <td class="labelCol">
              <div class="d-flex">
                <span class="mr-2">
                  <b>{{ n }}.</b>
                </span>
                <span>{{ effectiveConcernCountry.unprompted.concerns[n - 1] }}</span>
              </div>
            </td>
            <td class="otherCol">
              <Bar
                :value="toPercent(effectiveConcernCountry.unprompted.data[n - 1])"
                text="%"
                :color="barColor"
              />
            </td>
            <td class="otherCol text-center">
              <v-icon v-if="!effectiveConcernCountry.unprompted.evolution[n - 1]">
                mdi-minus
              </v-icon>
              <span
                v-else
                class="variation"
                :style="`color: ${getVariationColor(
                  effectiveConcernCountry.unprompted.evolution[n - 1],
                )};`"
              >
                {{ getVaration(effectiveConcernCountry.unprompted.evolution[n - 1]) }}
              </span>
            </td>
            <td class="labelCol">
              <div class="d-flex">
                <span class="mr-2">
                  <b>{{ n }}.</b>
                </span>
                <span>{{ effectiveConcernCountry.prompted.concerns[n - 1] }}</span>
              </div>
            </td>
            <td class="otherCol">
              <Bar
                :value="toPercent(effectiveConcernCountry.prompted.data[n - 1])"
                text="%"
                :color="barColor"
              />
            </td>
            <td class="lastCol text-center">
              <v-icon v-if="!effectiveConcernCountry.prompted.evolution[n - 1]">
                mdi-minus
              </v-icon>
              <span
                v-else
                class="variation"
                :style="`color: ${getVariationColor(
                  effectiveConcernCountry.prompted.evolution[n - 1],
                )};`"
              >
                {{ getVaration(effectiveConcernCountry.prompted.evolution[n - 1]) }}
              </span>
            </td>
          </tr>
        </template>
        <template v-else-if="datas.concerns.length">
          <template v-for="concern in datas.concerns">
            <tr :key="concern.location + 'A'" v-if="!concern.noTitle">
              <td v-if="concern.iso" colSpan="6" class="px-0">
                <v-subheader class="subheader">
                  <flag :iso="concern.iso" class="mr-2" />
                  {{ concern.location }}
                </v-subheader>
              </td>
              <td v-else colSpan="6" class="px-0">
                <v-subheader class="subheader">
                  {{ concern.location }}
                </v-subheader>
              </td>
            </tr>
            <tr
              v-for="n in concern.unprompted.concerns.length"
              :key="concern.location + '-' + (n - 1)"
              class="content-row"
            >
              <td class="labelCol">
                <div class="d-flex">
                  <span class="mr-2">
                    <b>{{ n }}.</b>
                  </span>
                  <span>{{ concern.unprompted.concerns[n - 1] }}</span>
                </div>
              </td>
              <td class="otherCol">
                <Bar
                  :value="toPercent(concern.unprompted.data[n - 1])"
                  text="%"
                  :color="barColor"
                />
              </td>
              <td class="otherCol text-center">
                <v-icon v-if="!concern.unprompted.evolution[n - 1]"> mdi-minus </v-icon>
                <span
                  v-else
                  class="variation"
                  :style="`color: ${getVariationColor(concern.unprompted.evolution[n - 1])};`"
                >
                  {{ getVaration(concern.unprompted.evolution[n - 1]) }}
                </span>
              </td>
              <td class="labelCol">
                <div class="d-flex">
                  <span class="mr-2">
                    <b>{{ n }}.</b>
                  </span>
                  <span>{{ concern.prompted.concerns[n - 1] }}</span>
                </div>
              </td>
              <td class="otherCol">
                <Bar
                  :value="toPercent(concern.prompted.data[n - 1])"
                  text="%"
                  :color="barColor"
                />
              </td>
              <td class="lastCol text-center">
                <v-icon v-if="!concern.prompted.evolution[n - 1]"> mdi-minus </v-icon>
                <span
                  v-else
                  class="variation"
                  :style="`color: ${getVariationColor(concern.prompted.evolution[n - 1])};`"
                >
                  {{ getVaration(concern.prompted.evolution[n - 1]) }}
                </span>
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </template>
  </v-simple-table>
</div>
