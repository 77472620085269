<template>
  <v-menu
    v-model="isMenuOpen"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    right
    content-class="bhp-select-menu menu"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="valueSected"
        @click="isMenuOpen = !isMenuOpen"
        @click:append="isMenuOpen = !isMenuOpen"
        :label="label"
        :append-icon="isMenuOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'"
        :class="`bhp-select select-width-${width} mutli-select`"
        outlined
        dense
        hide-details
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <div class="buttons" v-if="includeToggles">
      <v-btn
        small
        outlined
        tile
        class="mod-btn mr-4"
        @click="toggleAll(true)"
      >All</v-btn>
      <v-btn
        small
        outlined
        tile
        class="mod-btn"
        @click="toggleAll(false)"
      >None</v-btn>
    </div>
    <v-list
      dense max-height="250px"
      :class="`multi-list bhp-select-list ${includeToggles && 'with-toggles'}`"
    >
      <v-list-item v-if="!items.length">
        No data Available
      </v-list-item>
      <v-list-item
        v-for="(item, index) in itemList"
        :key="`${item}-${index}`"
        :disabled="isItemDisabled(item)"
        class="v-list-item--link"
      >
        <v-list-item-action class="mr-2">
          <v-checkbox
            :ripple="false"
            color="bhpBlue2"
            v-model="item.checked"
            @change="addOrRemoveItemFromChecked(item)"
            hide-details
          ></v-checkbox>
        </v-list-item-action>
        <v-list-item-content
          @click="checkItem(item)"
        >
          <v-list-item-title>
            {{ item.text }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'multi-select',
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => ([]),
    },
    width: {
      type: [Number, String],
      default: 100,
    },
    maxItems: {
      type: Number,
      default: null,
    },
    minItems: {
      type: Number,
      default: null,
    },
    itemText: {
      type: String,
      default: null,
    },
    itemValue: {
      type: String,
      default: null,
    },
    returnObject: {
      type: Boolean,
      default: true,
    },
    resetOnItemsChange: {
      type: Boolean,
      default: false,
    },
    includeToggles: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMenuOpen: false,
      itemsChecked: [...this.value.map((item) => this.itemValue ? item[this.itemValue] : item)],
      itemList: this.items.map((item) => ({
        text: this.itemText ? item[this.itemText] : item,
        value: this.itemValue ? item[this.itemValue] : item,
        checked: this.isChecked(this.value, item),
      })),
    };
  },
  computed: {
    valueSected() {
      return this.itemList.filter((item) => item.checked).map((item) => item.text).join(', ') || '';
    },
    returnItems() {
      return this.items.filter((item) => {
        return this.itemsChecked.includes(this.itemValue ? item[this.itemValue] : item);
      });
    },
  },
  methods: {
    isItemDisabled(item) {
      return (this.maxItems !== null &&
      this.itemsChecked.length >= this.maxItems &&
       !this.itemsChecked.includes(item.value)) ||
        (this.minItems !== null &&
        this.itemsChecked.length <= this.minItems &&
        this.itemsChecked.includes(item.value));
    },
    addOrRemoveItemFromChecked(item) {
      if (!item.checked) {
        this.itemsChecked = this.itemsChecked.filter((itemChecked) => itemChecked !== item.value);
      } else {
        this.itemsChecked.push(item.value);
      }
    },
    checkItem(item) {
      if (item.checked) {
        this.itemsChecked = this.itemsChecked.filter((itemChecked) => itemChecked !== item.value);
      } else {
        this.itemsChecked.push(item.value);
      }
      item.checked = !item.checked;
    },
    isChecked(itemsChecked, item) {
      const itemFound = itemsChecked
        .find((itemChecked) => this.itemValue
          ? itemChecked[this.itemValue] === item[this.itemValue]
          : itemChecked === item,
        );
      return !!itemFound;
    },
    // Toggle all checkboxes
    toggleAll(checked) {
      this.itemList.map(item => {
        item.checked = checked;
        this.addOrRemoveItemFromChecked(item);
      });
    },
  },
  watch: {
    value() {
      this.itemsChecked =
      [...this.value.map((item) => this.itemValue ? item[this.itemValue] : item)];
      this.itemList = this.items.map((item) => ({
        text: this.itemText ? item[this.itemText] : item,
        value: this.itemValue ? item[this.itemValue] : item,
        checked: this.isChecked(this.value, item),
      }));
    },
    items: {
      handler() {
        this.itemList = this.items.map((item) => ({
          text: this.itemText ? item[this.itemText] : item,
          value: this.itemValue ? item[this.itemValue] : item,
          checked: this.isChecked(this.value, item),
        }));
        if (this.resetOnItemsChange) {
          this.$emit('input', this.returnItems);
        }
      },
      deep: true,
    },
    isMenuOpen() {
      if (!this.isMenuOpen) {
        if (JSON.stringify(this.returnItems) === JSON.stringify(this.value)) return;
        this.$emit('input', this.returnItems);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons {
  position: absolute;
  width: calc(100% - 20px);
  min-width: 180px;
  padding: 16px 18px;
  z-index: 2;
}

.with-toggles::v-deep {
  padding-top: 52px !important;
  overflow: auto;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    width: calc(100% - 20px);
    height: 68px;
    background: linear-gradient(180deg, rgba(255,255,255,1) 75%, rgba(255,255,255,0.3) 100%);
    z-index: 1;
  }
}
</style>
