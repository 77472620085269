const state = {
  assetSelected: null,
  countrySelected: null,
};

const getters = {
  assetSelected: (state) => state.assetSelected,
  countrySelected: (state) => state.countrySelected,
};

const mutations = {
  setAssetSelected(state, assetSelected) {
    state.assetSelected = assetSelected;
  },
  setCountrySelected(state, countrySelected) {
    state.countrySelected = countrySelected;
  },
};

const actions = {
  async setCountrySelected({ commit }, country) {
    commit('setCountrySelected', country);
  },
  async setAssetSelected({ commit }, asset) {
    commit('setAssetSelected', asset);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
