import moment from 'moment';

const state = {
  digitalIntelPeriod: null,
};

const getters = {
  digitalIntelPeriod: (state) => state.digitalIntelPeriod,
};

const mutations = {
  setDigitalIntelPeriod(state, date) {
    // Set the period to 2 days before and after the date
    if (date) {
      const from = moment(date).subtract(2, 'days').format('YYYY-MM-DD');
      const to = moment(date).add(2, 'days').format('YYYY-MM-DD');
      state.digitalIntelPeriod = { from, to };
    } else {
      state.digitalIntelPeriod = null;
    }
  },
};

const actions = {
  async setDigitalIntelPeriod({ commit }, date) {
    commit('setDigitalIntelPeriod', date);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
