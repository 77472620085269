<template>
  <div>
    <div class="px-0 d-flex align-center">
      <Badge v-for="(color, key) in colors" :key="key" :text="key" :color="color" />
    </div>

    <v-data-table
      class="reputation-driver-table"
      :headers="headers"
      :items="items"
      hide-default-footer
      disable-sort
      disable-pagination
      item-key="name"
      :group-by="groupBy"
    >
      <template #header="{}">
        <thead class="v-data-table-top-header">
          <tr>
            <th colspan="2" class="body-2 text-center font-weight-medium black--text">
              Driver analysis
            </th>
            <th colspan="2" class="body-2 text-center font-weight-medium black--text">
              Performance analysis
            </th>
          </tr>
        </thead>
      </template>

      <template #[`group.header`]="{ items }">
        <td :colspan="headers.length" class="font-weight-medium">
          <flag v-if="groupBy === 'country'" :iso="items[0]['iso']" class="mb-n1 mr-2" size="36" />
          {{ items[0][groupBy] }}
        </td>
      </template>

      <template #item="{ item }">
        <tr>
          <td>{{ item.name }}</td>
          <td>
            <Bar :value="toPercent(item.rawDriverScore)" :coef="100" :color="colors[item.theme]" />
          </td>
          <td class="td-performance text-center font-weight-medium">
            {{ toPercent(item.score) }}%
          </td>
          <td class="td-chart">
            <ReputationDriverPerformanceOvertimeChart :data="item.performanceOverTime" />
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Badge from '@/components/Badge.vue';
import theme from '@/theme';
import Bar from '@components/Charts/Bar.vue';
import ReputationDriverPerformanceOvertimeChart from './ReputationDriverPerformanceOvertimeChart.vue';

const THEMES_TO_COLORS = {
  Community: theme.bhpCommunity,
  Corporate: theme.bhpCoporate,
  Economic: theme.bphEconomic,
  Environmental: theme.bhpEnvironmental,
};

export default {
  name: 'ReputationDriverTable',

  components: {
    Bar,
    ReputationDriverPerformanceOvertimeChart,
    Badge,
  },

  props: {
    items: {
      type: Array,
      default: () => [],
    },
    groupBy: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      colors: THEMES_TO_COLORS,
      headers: [
        { text: 'Driver', width: '35%' },
        { text: 'Raw driver score', width: '25%' },
        { text: 'Most recent performance', width: '5%' },
        { text: 'Performance over time', width: '35%', align: 'center' },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
@import '@styles/_colors.scss';

.reputation-driver-table table {
  width: 100%;

  tr {
    &:first-child > td {
      border-top: 1px solid $bhp-functional-grey-1;
    }

    td {
      border-bottom: 2px solid $bhp-functional-grey-1 !important;

      &:first-child {
        border-left: 2px dashed $bhp-functional-grey-1;
      }

      &:nth-child(2) {
        border-right: 2px dashed $bhp-functional-grey-1;
      }

      &:last-child {
        border-right: 2px dashed $bhp-functional-grey-1;
      }

      &.td-performance {
        padding: 0;
      }

      &.td-chart {
        min-width: 460px;
        padding: 0 5px 0;
      }
    }
  }
}
</style>
