/* eslint-disable no-console */
import { CardBaseService } from './CardBaseService';
import { AUSTRALIA, getCodeFromCountries, isAudienceFilterHidden } from '@/utils/countries';
import { FILTERS_TYPES } from '@/utils/panel-filters';
import Insights from '@utils/insights';
import { toPercent } from '@utils/helpers';
import { CARDS_TYPES } from '@services/CardDataService.js';
import { getDefaultTimePeriod } from '@/utils/defaultTimePeriods';
import { quarterAndYearFormat, quarterLabelFromShortMonth } from '@/utils/quarters';

export default class CorporateReputationService extends CardBaseService {
  static getCardType() {
    return CARDS_TYPES.CORPORATE_REPUTATION;
  }

  static getChartTitle() {
    return 'Corporate Reputation';
  }

  static getChartComponentName() {
    return 'CorporateReputationChart';
  }

  static getFilters(defaultFilters = {}, location = { country: null, asset: null }) {
    const {
      defaultTimeperiod,
      defaultDemographic,
      defaultAudience,
      defaultDateRangesFrom,
      defaultDateRangesTo,
      defaultDateRangesBenchmark,
      defaultQuarterPeriodsFrom,
      defaultQuarterPeriodsTo,
      defaultQuarterPeriodsBenchmark,
    } = defaultFilters;

    const INSIGHT = Insights.get(location.country, location.asset);

    let reputation = 'Against peers';

    if (INSIGHT === Insights.NATIONAL) {
      reputation = 'BHP Overtime';
    } else if (INSIGHT === Insights.ASSET) {
      reputation = 'By Community';
    }

    return {
      year: getDefaultTimePeriod(location) ?? defaultTimeperiod ?? null,
      audience: defaultAudience ?? null,
      demographic: defaultDemographic ?? null,
      reputation,
      community: this.getDefaultCommunity(location.asset, location.country),
      dateRange: {
        from: defaultDateRangesFrom ?? null,
        to: defaultDateRangesTo ?? null,
        benchmark: defaultDateRangesBenchmark ?? null,
      },
      dateQuarterPeriod: {
        from: defaultQuarterPeriodsFrom ?? null,
        to: defaultQuarterPeriodsTo ?? null,
        benchmark: defaultQuarterPeriodsBenchmark ?? null,
      },
    };
  }

  static getFiltersConfig(model, { asset, country }) {
    const INSIGHT = Insights.get(country, asset);

    let dateRange;
    if (model.reputation === 'Against peers') {
      // Australia national against peers
      if (INSIGHT === Insights.NATIONAL && country === AUSTRALIA) {
        dateRange = {
          type: FILTERS_TYPES.DATE_RANGE_BY_QUARTER,
          model,
          key: 'dateQuarterPeriod',
        };
      } else {
        // Non-Australia national against peers
        dateRange = {
          type: FILTERS_TYPES.DATE_RANGE,
          model,
          key: 'dateRange',
          isHidden: INSIGHT !== Insights.NATIONAL,
        };
      }
    } else {
      // Australia national BHP over time
      if (INSIGHT === Insights.NATIONAL && country === AUSTRALIA) {
        dateRange = {
          type: FILTERS_TYPES.DATE_RANGE_AND_BENCHMARK_BY_QUARTER,
          model,
          key: 'dateQuarterPeriod',
        };
      } else {
        // Non-Australia national against peers
        dateRange = {
          type: FILTERS_TYPES.DATE_RANGE_AND_BENCHMARK,
          model,
          key: 'dateRange',
          isHidden: INSIGHT !== Insights.NATIONAL,
        };
      }
    }

    const items =
      INSIGHT !== Insights.ASSET
        ? ['BHP Overtime', 'Against peers']
        : ['By Community', 'Against local peers'];

    return [
      {
        type: FILTERS_TYPES.DEMOGRAPHIC,
        model,
        key: 'demographic',
        country,
        isLocal: !!asset,
        isHidden: INSIGHT === Insights.GLOBAL,
      },
      {
        type: FILTERS_TYPES.AUDIENCE,
        model,
        key: 'audience',
        isHidden: isAudienceFilterHidden(country, asset),
      },
      {
        type: FILTERS_TYPES.REPUTATION,
        model,
        key: 'reputation',
        items,
        isHidden: INSIGHT === Insights.ALL_HOST_COMMUNITIES || INSIGHT === Insights.GLOBAL,
      },
      {
        type: FILTERS_TYPES.COMMUNITY,
        model,
        key: 'community',
        isHidden: INSIGHT !== Insights.ASSET || model.reputation === 'By Community',
        items: this.getCommunities(asset, country),
      },
      dateRange,
      {
        type: FILTERS_TYPES.TIME_PERIOD,
        model,
        key: 'year',
        isHidden: INSIGHT === Insights.NATIONAL,
      },
    ];
  }

  static getReportPageConfig({ location = {}, filters, data }) {
    return {
      type: this.getCardType(),
      title: this.getChartTitle(),
      filters: {
        ...filters,
        country: location.country,
        asset: location.asset,
      },
      data: {
        items: data,
        asset: location.asset,
        country: location.country,
        reputation: filters.reputation,
        spreadData: true,
      },
      rows: this.getRows(data, filters, location.country, location.asset),
    };
  }

  static getRows(data, filters, country, asset) {
    const THEMES = [
      'Advocacy',
      'Trust',
      'Favourability',
      'Familiarity',
      'Awareness',
      'Unfamiliarity',
      'Unfavourability',
      'Distrust',
      'Criticism',
    ];

    const rows = THEMES.map((theme) => {
      const row = {
        Sentiment: theme,
      };

      const sentimentValues = this.getSentimentValues(data, theme);

      if (sentimentValues?.benchmarkValue) {
        row[`${sentimentValues.benchmarkValue.label} - (Benchmark)`] =
          sentimentValues.benchmarkValue.value;
      }

      sentimentValues.sentimentValues?.forEach((sentimentValue) => {
        row[sentimentValue.label] = sentimentValue.value;
      });
      return row;
    });

    // Add description of filters to last row
    if (rows.length && filters?.audience) {
      this.addFiltersToRows(rows, filters, country, asset);
    }
    return rows;
  }

  static getSentimentValues(data, theme) {
    let benchmarkValue = null;

    if (data?.benchmark) {
      benchmarkValue = {
        label: data.benchmark.name,
        value: this.getValue(data.benchmark.data, theme),
      };
    }

    const sentimentValues = data?.items.map((item) => {
      return {
        label: item.name,
        value: this.getValue(item.data, theme),
      };
    });

    return {
      benchmarkValue,
      sentimentValues,
    };
  }

  static getValue(datas, theme) {
    return datas.find((d) => theme === d.label)?.value ?? '';
  }

  static humanizeFilters({
    asset,
    audience,
    country,
    demographic,
    reputation,
    dateRange,
    dateQuarterPeriod,
    year,
    community,
  }) {
    const { age, gender, shareholder, votingPref } = demographic;
    const INSIGHT = Insights.get(country, asset);

    if (INSIGHT === Insights.ASSET) {
      return {
        'Host Community': `${asset} (${country})`,
        Community: community,
        'Time Period': year,
        Reputation: reputation,
      };
    }

    if (INSIGHT === Insights.ALL_HOST_COMMUNITIES) {
      return {
        'Host Community': `${asset} (${country})`,
        'Time Period': year,
      };
    }

    if (INSIGHT === Insights.GLOBAL) {
      return {
        Country: country,
        'Time Period': year,
      };
    }

    if (INSIGHT === Insights.NATIONAL) {
      const { from, to } = country === AUSTRALIA ? dateQuarterPeriod : dateRange;
      let { benchmark } = country === AUSTRALIA ? dateQuarterPeriod : dateRange;

      let dateRangeString = `${from} to ${to}`;
      if (country === AUSTRALIA) {
        benchmark = quarterAndYearFormat(benchmark.label, benchmark.value);
        dateRangeString = `${quarterAndYearFormat(
          from.label,
          from.value,
        )} to ${quarterAndYearFormat(to.label, to.value)}`;
      }

      return {
        Country: country,
        ...(reputation !== 'Against peers' && { Benchmark: benchmark }),
        'Date Range': dateRangeString,
        Reputation: reputation,
        Age: age,
        Gender: gender,
        Shareholder: shareholder,
        'Voting Preference': votingPref,
        Audience: audience,
      };
    }

    return {};
  }

  static formatDataForGlobal(responses) {
    const datas = [];
    for (let index = 0; index < responses.length; index++) {
      const item = responses[index];
      const dataByCountry = datas.find((country) => country.country === item.country_label);
      if (dataByCountry) {
        dataByCountry.data.push({
          label: item.kpi_label,
          value: toPercent(item.smp),
        });
      } else {
        datas.push({
          name: item.country_label,
          country: item.country_label,
          iso: getCodeFromCountries(item.country_label),
          data: [
            {
              label: item.kpi_label,
              value: toPercent(item.smp),
            },
          ],
        });
      }
    }
    return {
      benchmark: null,
      items: datas,
      type: 'global',
    };
  }

  static formatDataForNationalOverTime(responses, isAustralia, benchperiod) {
    const benchmark = {
      name: benchperiod,
      data: [],
    };
    const items = [];
    for (let index = 0; index < responses.benchmark.length; index++) {
      const item = responses.benchmark[index];
      benchmark.data.push({
        label: item.kpi_label,
        value: toPercent(item.smp),
      });
    }
    for (let index = 0; index < responses.allData.length; index++) {
      const item = responses.allData[index];
      const quarterName = isAustralia
        ? quarterAndYearFormat(item.quarter_label, item.year)
        : `${item.year}`;
      const dataByQuarter = items.find((quarter) => quarter.name === quarterName);
      if (dataByQuarter) {
        dataByQuarter.data.push({
          label: item.kpi_label,
          value: toPercent(item.smp),
        });
      } else {
        items.push({
          name: quarterName,
          data: [
            {
              label: item.kpi_label,
              value: toPercent(item.smp),
            },
          ],
        });
      }
    }
    return {
      benchmark,
      items,
      type: 'national',
    };
  }

  static groupByKpiLabel(values) {
    return Object.values(
      values.reduce((acc, curr) => {
        const key = curr['kpi_label'];

        if (!acc[key]) {
          acc[key] = {
            label: key,
            smp: 0,
            total: 0,
          };
        }

        acc[key] = {
          ...acc[key],
          smp: acc[key].smp + curr.smp,
          total: acc[key].total + 1,
        };

        return acc;
      }, {}),
    ).map(({ label, smp, total }) => ({
      label,
      value: toPercent(smp / total),
    }));
  }

  static formatDataForNationalAgainstPeer(responses) {
    const benchmark = {
      logo: 'BHP',
      name: 'BHP',
      data: this.groupByKpiLabel(responses.benchmark),
    };

    const items = Object.values(
      responses.allData.reduce((acc, curr) => {
        const key = curr['company_label'];
        if (!acc[key]) {
          acc[key] = {
            logo: key,
            name: key,
            data: [],
          };
        }

        acc[key] = {
          ...acc[key],
          data: [...acc[key].data, curr],
        };

        return acc;
      }, {}),
    ).map(({ logo, name, data }) => ({
      logo,
      name,
      data: this.groupByKpiLabel(data),
    }));

    return {
      benchmark,
      items,
      type: 'national',
    };
  }

  static formatDataForNational(responses, isAustralia, benchperiod, isAgainstPeer) {
    if (!isAgainstPeer) {
      return this.formatDataForNationalOverTime(responses, isAustralia, benchperiod);
    }
    return this.formatDataForNationalAgainstPeer(responses);
  }

  static formatDataForHost(responses) {
    const datas = [];
    for (let index = 0; index < responses.length; index++) {
      const item = responses[index];
      const dataByAsset = datas.find((asset) => asset.asset === item.asset_short);
      if (dataByAsset) {
        dataByAsset.data.push({
          label: item.kpi_label,
          value: toPercent(item.smp),
        });
      } else {
        datas.push({
          name: item.asset_short,
          asset: item.asset_short,
          data: [
            {
              label: item.kpi_label,
              value: toPercent(item.smp),
            },
          ],
        });
      }
    }
    return {
      benchmark: null,
      items: datas,
      type: 'host',
    };
  }

  static formatDataForAssetByCommunity(responses) {
    const datas = [];
    for (let index = 0; index < responses.allData.length; index++) {
      const item = responses.allData[index];
      const dataByCommunity = datas.find((asset) => asset.community === item.community_label);
      if (dataByCommunity) {
        dataByCommunity.data.push({
          label: item.kpi_label,
          value: toPercent(item.smp),
        });
      } else {
        datas.push({
          name: item.community_label,
          community: item.community_label,
          data: [
            {
              label: item.kpi_label,
              value: toPercent(item.smp),
            },
          ],
        });
      }
    }
    return {
      benchmark: null,
      items: datas,
      type: 'asset',
    };
  }

  static formatDataForAssetByPeers(responses) {
    const benchmark = {
      logo: 'BHP',
      name: 'BHP',
      data: this.groupByKpiLabel(responses.benchmark),
    };
    const items = Object.values(
      responses.allData.reduce((acc, curr) => {
        const key = curr['company_label'];

        if (!acc[key]) {
          acc[key] = {
            logo: key,
            name: key,
            data: [],
          };
        }

        acc[key] = {
          ...acc[key],
          data: [...acc[key].data, curr],
        };

        return acc;
      }, {}),
    ).map(({ logo, name, data }) => ({
      logo,
      name,
      data: this.groupByKpiLabel(data),
    }));
    return {
      items,
      benchmark,
      type: 'asset',
    };
  }

  static formatDataForAsset(responses, isByCommunity) {
    if (isByCommunity) {
      return this.formatDataForAssetByCommunity(responses);
    } else {
      return this.formatDataForAssetByPeers(responses);
    }
  }

  static async loadData({ filters, location }) {
    const params = {
      country: location.country,
      asset: location.asset,
      ...filters,
    };

    const INSIGHT = Insights.get(params.country, params.asset);

    if (INSIGHT === Insights.GLOBAL) {
      const responses = await this.httpRequestService.get(
        '/api/v2/corporate-reputation',
        null,
        {
          audience: params.audience,
          year: params.year,
        },
        false,
      );
      return this.formatDataForGlobal(responses);
    } else if (INSIGHT === Insights.NATIONAL) {
      let responses = {
        items: [],
        benchmark: [],
      };
      if (params.country === AUSTRALIA) {
        responses = await this.httpRequestService.get(
          '/api/v2/corporate-reputation/national',
          null,
          {
            reputation: params.reputation,
            audience: params.audience,
            benchQuarter: quarterLabelFromShortMonth(params.dateQuarterPeriod.benchmark.label),
            quarterFrom: quarterLabelFromShortMonth(params.dateQuarterPeriod.from.label),
            quarterTo: quarterLabelFromShortMonth(params.dateQuarterPeriod.to.label),
            benchPeriod: params.dateQuarterPeriod.benchmark.value,
            periodFrom: params.dateQuarterPeriod.from.value,
            periodTo: params.dateQuarterPeriod.to.value,
            country: params.country,
            ...filters.demographic,
          },
          false,
        );
      } else {
        responses = await this.httpRequestService.get(
          '/api/v2/corporate-reputation/national',
          null,
          {
            reputation: params.reputation,
            audience: params.audience,
            benchPeriod: params.dateRange.benchmark,
            periodFrom: params.dateRange.from,
            periodTo: params.dateRange.to,
            country: params.country,
            ...filters.demographic,
          },
          false,
        );
      }
      const benchperiod =
        params.country === AUSTRALIA
          ? quarterAndYearFormat(
              params.dateQuarterPeriod.benchmark.label,
              params.dateQuarterPeriod.benchmark.value,
            )
          : params.dateRange.benchmark;
      return this.formatDataForNational(
        responses,
        params.country === AUSTRALIA,
        benchperiod,
        params.reputation === 'Against peers',
      );
    } else if (INSIGHT === Insights.ALL_HOST_COMMUNITIES) {
      const responses = await this.httpRequestService.get(
        '/api/v2/corporate-reputation/host',
        null,
        {
          country: params.country,
          year: params.year,
          ...filters.demographic,
        },
        false,
      );
      return this.formatDataForHost(responses);
    } else if (INSIGHT === Insights.ASSET) {
      const responses = await this.httpRequestService.get(
        '/api/v2/corporate-reputation/asset',
        null,
        {
          country: params.country,
          year: params.year,
          asset: params.asset,
          community: params.community,
          reputation: params.reputation,
          ...filters.demographic,
        },
        false,
      );
      return this.formatDataForAsset(responses, params.reputation === 'By Community');
    }
    return {
      benchmark: null,
      items: [],
      type: null,
    };
  }
}
