
<v-row>
  <v-col class="col-12">
    <h4>News Heard about BHP</h4>
    <NewsHeardChart :lines="datas?.newsHeard" :showMonths="showMonths" />
  </v-col>
  <v-col class="col-12">
    <h4>News Impact on Favourability</h4>
    <NewsFavourabilityChart :lines="datas?.newsAwareness" :showMonths="showMonths" />
  </v-col>
</v-row>
