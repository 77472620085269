import { getCodeFromCountries } from '@/utils/countries.js';

const COMPANIES = [
  { id: '355', name: 'Alcoa', countries: ['AU'], image: 'Alcoa.svg', color: '#175689' },
  { id: '10', name: 'Amazon', countries: ['US'], image: 'Amazon.svg', color: '#FF9900' },
  {
    id: '345',
    name: 'Anglo-American',
    countries: ['AU', 'GB', 'CL', 'BR', 'CO', 'EC'],
    image: 'Anglo_American.svg',
    color: '#112E69',
  },
  {
    id: '369',
    name: 'Antofagasta Minerals',
    countries: ['CL'],
    image: 'Antofagasta_Minerals.svg',
    color: '#3E9DAB',
  },
  { id: '8', name: 'Apple', countries: ['US'], image: 'Apple.svg', color: '#717171' },
  {
    id: '348',
    name: 'Bengalla (Newhope)',
    countries: ['AU'],
    image: 'Bengalla.svg',
    color: '#0197D5',
  },
  {
    id: '336',
    name: 'BHP',
    countries: ['AU', 'US', 'GB', 'CL', 'CN', 'CA', 'BR', 'CO', 'EC'],
    image: 'BHP.svg',
    color: '#E65501',
  },
  { id: '372', name: 'BP', countries: ['GB'], image: 'BP.svg', color: '#009900' },
  { id: '376', name: 'Cameco', countries: ['CA'], image: 'Cameco.svg', color: '#002E50' },
  {
    id: '366',
    name: 'Capstone Mining',
    countries: ['US'],
    image: 'Capstone_Mining.svg',
    color: '#465761',
  },
  { id: '374', name: 'Cargill', countries: ['CN'], image: 'Cargill.svg', color: '#007028' },
  { id: '338', name: 'Chevron', countries: ['AU', 'US'], image: 'Chevron.svg', color: '#126BAC' },
  {
    id: '371',
    name: 'Codelco',
    countries: ['CL', 'CO', 'EC'],
    image: 'Codelco.svg',
    color: '#9C5A39',
  },
  { id: '383', name: 'Collahuasi', countries: ['CL'], image: 'Collahuasi.svg', color: '#004101' },
  {
    id: '339',
    name: 'Commonwealth Bank',
    countries: ['AU'],
    image: 'Commonwealth_Bank.svg',
    color: '#FEDF03',
  },
  {
    id: '373',
    name: 'ConocoPhillips',
    countries: ['CN'],
    image: 'Conoco_Phillips.svg',
    color: '#6C0F08',
  },
  { id: '373', name: 'Equinor', countries: ['UK'], image: 'Equinor.svg', color: '#FF3F76' },
  {
    id: '352',
    name: 'Fortescue Metals Group (FMG)',
    countries: ['AU', 'CO', 'EC'],
    image: 'Fortescue_Metals_Group.svg',
    color: '#112552',
  },
  {
    id: '365',
    name: 'Freeport McMoran',
    countries: ['US'],
    image: 'Freeport_McMoran.svg',
    color: '#88C8EC',
  },
  { id: '359', name: 'GE', countries: ['CN'], image: 'General_Electric.svg', color: '#87ACD9' },
  {
    id: '351',
    name: 'GFG Alliance / Liberty',
    countries: ['AU'],
    image: 'GFG.svg',
    color: '#374045',
  },
  { id: '343', name: 'Glencore', countries: ['AU', 'CA'], image: 'Glencore.svg', color: '#231F20' },
  {
    id: '347',
    name: 'Glencore (Mangoola)',
    countries: ['AU'],
    image: 'Mangoola_Glencore.svg',
    color: '#000000',
  },
  {
    id: '381',
    name: 'Homestake Mining',
    countries: ['US'],
    image: 'Homestake_Mining.svg',
    color: '#A4967C',
  },
  { id: '361', name: 'IBM', countries: ['US'], image: 'IBM.svg', color: '#85B8EB' },
  { id: '388', name: 'JBS', countries: ['BR'], image: 'JBS.svg', color: '#D83915' },
  {
    id: '356',
    name: 'KCGM / The Super Pit',
    countries: ['AU', 'CA'],
    image: 'KCGM.svg',
    color: '#EBD481',
  },
  { id: '379', name: 'Kerr McGee', countries: ['US'], image: 'Kerr_McGee.svg', color: '#05182D' },
  {
    id: '354',
    name: 'Mineral Resources (MinRes)',
    countries: ['AU'],
    image: 'Minerals_Resources.svg',
    color: '#919296',
  },
  { id: '375', name: 'Mosaic', countries: ['CA'], image: 'Mosaic.svg', color: '#005953' },
  {
    id: '346',
    name: 'Mt Pleasant Operation',
    countries: ['AU'],
    image: 'Mount_Pleasant.svg',
    color: '#656A70',
  },
  {
    id: '384',
    name: 'Newcrest',
    countries: ['CO', 'EC'],
    image: 'Newcrest_Mining.svg',
    color: '#AD8B3B',
  },
  {
    id: '391',
    name: 'Newmont Gold Corp',
    countries: ['CA'],
    image: 'Newmont_GoldCorp.svg',
    color: '#142D7A',
  },
  {
    id: '357',
    name: 'Northern Star',
    countries: ['AU'],
    image: 'Northern_Star.svg',
    color: '#C4A53F',
  },
  {
    id: '358',
    name: 'Northwest Shelf',
    countries: ['AU'],
    image: 'Northwest_Shelf.svg',
    color: '#3C789A',
  },
  { id: '389', name: 'Nubank', countries: ['BR'], image: 'Nubank.svg', color: '#820AD1' },
  { id: '378', name: 'Nutrien', countries: ['CA'], image: 'Nutrien.svg', color: '#AAD045' },
  { id: '349', name: 'OZ Minerals', countries: ['AU'], image: 'Oz_Minerals.svg', color: '#FDB913' },
  { id: '344', name: 'Peabody', countries: ['AU'], image: 'Peabody.svg', color: '#004987' },
  {
    id: '367',
    name: 'Pinto Valley',
    countries: ['US'],
    image: 'Pinto Valley.svg',
    color: '#052B48',
  },
  { id: '340', name: 'Qantas', countries: ['AU'], image: 'Qantas.svg', color: '#F90000' },
  {
    id: '387',
    name: 'Renova Foundation',
    countries: ['BR'],
    image: 'Renova_Foundation.svg',
    color: '#00A88E',
  },
  {
    id: '364',
    name: 'Resolution Copper',
    countries: ['US'],
    image: 'Resolution_Copper.svg',
    color: '#051E7F',
  },
  { id: '380', name: 'Rio Algom', countries: ['US'], image: 'Rio_Algom.svg', color: '#915630' },
  {
    id: '337',
    name: 'Rio Tinto',
    countries: ['AU', 'US', 'GB', 'CN', 'CA'],
    image: 'RioTinto.svg',
    color: '#E60D2E',
  },
  { id: '353', name: 'Roy Hill', countries: ['AU'], image: 'Roy_Hill.svg', color: '#363636' },
  { id: '386', name: 'Samarco', countries: ['BR'], image: 'Samarco.svg', color: '#004071' },
  { id: '350', name: 'Santos', countries: ['AU'], image: 'Santos.svg', color: '#008AC9' },
  {
    id: '360',
    name: 'Shell',
    countries: ['US', 'GB', 'CN', 'CA'],
    image: 'Shell.svg',
    color: '#F8E068',
  },
  { id: '370', name: 'SQM', countries: ['CL'], image: 'SQM.svg', color: '#80BC00' },
  {
    id: '390',
    name: 'Teck Resources',
    countries: ['CA'],
    image: 'Teck_Resources.svg',
    color: '#969696',
  },
  { id: '341', name: 'Telstra', countries: ['AU'], image: 'Telstra.svg', color: '#ED3591' },
  {
    id: '385',
    name: 'Tongling Nonferrous Metal Group',
    countries: ['CO', 'EC'],
    image: 'Tongling_nonferrous.svg',
    color: '#CEC522',
  },
  { id: '325', name: 'Unilever', countries: ['GB'], image: 'Unilever.svg', color: '#465CE2' },
  { id: '382', name: 'Vale', countries: ['CA', 'BR'], image: 'Vale.svg', color: '#00939A' },
  {
    id: '342',
    name: 'Woodside Energy',
    countries: ['AU', 'CN'],
    image: 'Woodside.svg',
    color: '#A31129',
  },
  { id: '', name: 'HSBC', countries: [], image: 'HSBC.svg', color: '#FF5B67' },
  {
    id: '',
    name: 'Hancock Prospecting',
    countries: [],
    image: 'Hancock_Prospecting.svg',
    color: '#C0C0C0',
  },
  { id: '', name: 'Heritage / Paria', countries: [], image: 'Heritage.svg', color: '#3D556D' },
  { id: '', name: 'K&S', countries: [], image: 'K_S.svg', color: '#14336A' },
  { id: '', name: 'NGC', countries: [], image: 'NGC.svg', color: '#F5907F' },
  { id: '', name: 'Pemex', countries: [], image: 'Pemex.svg', color: '#008B52' },
  {
    id: '',
    name: 'Republic Bank Limited',
    countries: [],
    image: 'Republic_Bank_Limited.svg',
    color: '#05BEFF',
  },
  { id: '', name: 'Total Mining', countries: [], image: 'Total_Mining.svg', color: '#8434D5' },
  { id: '', name: 'Cerro Colorado', countries: [], image: '', color: '#FFA669' },
  { id: '', name: 'Escondida', countries: [], image: '', color: '#FF8D3F' },
  { id: '', name: 'Spence', countries: [], image: '', color: '#FFA86D' },
];

export const getCompanyImage = (company) => {
  return COMPANIES.find((c) => c.name === company)?.image || null;
};

export const getCompanyNamesByCountryName = (countryName) => {
  if (!countryName) return [];
  const countryCode = getCodeFromCountries(countryName).toUpperCase();
  const companyNames = COMPANIES.filter((c) => c.countries.includes(countryCode)).map(
    (c) => c.name,
  );
  // 'BHP' should always be first
  if (companyNames.includes('BHP')) {
    companyNames.splice(companyNames.indexOf('BHP'), 1);
    companyNames.unshift('BHP');
  }
  return companyNames;
};

export const mapCompanyNamesToIds = (companyNames = []) => {
  return companyNames.map((companyName) => COMPANIES.find((c) => c.name === companyName).id || -1);
};

/**
 * Takes a data set and returns an array of colors to use for lines
 * @param {Arrat} data Array of data items
 * @returns Array of colors to use for lines
 */
export const mapColorsToCompanies = (data) => {
  let lastItemName = null;
  let index = -1;
  const colors = [];

  // Get colors not mapped to companies
  const nonCompanyColors = COMPANIES.filter((item) => {
    return !data.find((d) => d.name === item.company);
  });

  data.forEach((item) => {
    const company = COMPANIES.find((d) => d.name === item.name);
    if (company) {
      // If company is mapped to a color, use it
      colors.push(company.color);
    } else {
      // Otherwise, use colors not mapped to companies.
      // Only increment index if company name is different from last item
      if (item.name !== lastItemName) {
        lastItemName = item.name;
        index = index < nonCompanyColors.length - 1 ? index + 1 : 0;
      }
      colors.push(nonCompanyColors[index].color);
    }
  });
  return colors;
};
