import Vue from 'vue';
import * as components from 'vuetify/lib/components';
import * as directives from 'vuetify/lib/directives';
import Vuetify from 'vuetify/lib/';
import theme from '@/theme';

Vue.use(Vuetify, { components, directives });

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: theme.bhpBlue1,
        ...theme,
      },
    },
  },
});
