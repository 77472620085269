export function randomToken(length = 32) {
  return Math.random().toString(length).substr(2);
}

export function toPercent(value, fractionDigits = 0) {
  return +(value * 100).toFixed(fractionDigits);
}

export function groupBy(array, key) {
  return array.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}
