import httpRequestService from '@/services/HttpRequestService';
import eventBus from '@/EventBus';

const state = {
  username: '',
  initials: '',
};

const getters = {
  userName: (state) => state.username,
  userInitials: (state) => state.initials,
};

const mutations = {
  setUser(state, { name, initials }) {
    state.username = name;
    state.initials = initials;
  },
};

const actions = {
  async loadUserData({ commit }) {
    try {
      const user = await httpRequestService.get('/api/v2/auth/user', {}, {}, false);
      commit('setUser', user);
      eventBus.dispatch('loggedIn');
    } catch (err) {
      console.error('Error retrieving user data.'); // eslint-disable-line
      console.error(err); // eslint-disable-line
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
