<template>
  <div
    ref="screenshot-container"
    class="screenshot-container"
    :style="{
      height: `${height}px`,
      width: `${width}px`,
    }"
  >
    <div v-if="page" class="d-flex flex-column justify-space-between fill-height">
      <div
        ref="chart"
        class="content d-inline-flex align-center"
        :style="{ transform: `scale(${scale})` }"
      >
        <component v-bind:is="page['chartComponentName']" v-bind="page['data']" />
      </div>
      <div ref="footer" class="footer d-flex justify-space-between">
        <div>
          <h1 class="font-weight-medium grey--text text--darken-3">
            {{ page.title }}
          </h1>
          <ul>
            <li v-for="[title, value] in Object.entries(page.humanizedFilters)" :key="title">
              <template v-if="!!value">
                <b class="font-weight-medium">{{ title }}: </b> {{ value }}
              </template>
            </li>
          </ul>
        </div>

        <div class="d-flex align-center">
          <img :src="`data:image/png;base64,${BHP_LOGO}`" class="logo" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Highcharts from 'highcharts';
import screenshot from '@/mixins/screenshot.js';
import { BHP_LOGO } from '@/utils/image-as-base64.js';
import CardDataService from '@services/CardDataService.js';
import { randomToken } from '@utils/helpers.js';
import GlobalMapChart from './Cards/GlobalMap/GlobalMapChart.vue';
import ComparisonConcernsChart from './Cards/ComparisonConcerns/ComparisonConcernsChart.vue';
import OpinionLeadersChart from './Cards/OpinionLeaders/OpinionLeadersChart.vue';
import ReputationDriverTable from './Cards/ReputationDriver/ReputationDriverTable.vue';
import CommunityConcernsChart from './Cards/CommunityConcerns/CommunityConcernsChart.vue';
import TopSVPiPerformersChart from './Cards/TopSVPiPerformers/TopSVPiPerformersChart.vue';
import TopSVPiCountryChart from './Cards/TopSVPiCountry/TopSVPiCountryChart.vue';
import SentimentChart from './Cards/DigitalIntelligence/SentimentChart.vue';
import VolumeChart from './Cards/DigitalIntelligence/VolumeChart.vue';
import CorporateReputationChart from './Cards/CorporateReputation/CorporateReputationChart.vue';
import SocialValueChart from './Cards/SocialValue/SocialValueChart.vue';
import NewsAwarenessAndImpactChart from './Cards/NewsAwarenessAndImpact/NewsAwarenessAndImpactChart.vue';

export const ScreenshotGeneratorBus = new Vue();

const defaultHeight = 794;
const defaultWidth = 1123;
const defaultScale = 1;
const contentMaxHeight = 616;
const addedPadding = 32;

export default {
  name: 'ScreenshotGenerator',
  mixins: [screenshot],
  components: {
    CorporateReputationChart,
    GlobalMapChart,
    CommunityConcernsChart,
    SentimentChart,
    VolumeChart,
    OpinionLeadersChart,
    ReputationDriverTable,
    TopSVPiPerformersChart,
    TopSVPiCountryChart,
    ComparisonConcernsChart,
    SocialValueChart,
    NewsAwarenessAndImpactChart,
  },
  mounted() {
    ScreenshotGeneratorBus.$on('TAKE_SCREENSHOT', async (reportPage, cb) => {
      try {
        this.toggleAnimation(false);
        const page = this.formatPage(reportPage);
        this.page = page;
        const screenshot = await this.takeScreenshot();
        cb({ id: page.id, screenshot, rows: page.rows, title: page.title });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      } finally {
        this.toggleAnimation(true);
        this.height = defaultHeight;
        this.width = defaultWidth;
        this.scale = defaultScale;
      }
    });
  },
  data: function () {
    return {
      page: null,
      BHP_LOGO,
      height: defaultHeight,
      width: defaultWidth,
      scale: defaultScale,
      ratio: 1.41,
    };
  },
  methods: {
    formatPage(reportPage) {
      const id = randomToken();
      const service = CardDataService(reportPage.type);
      const chartComponentName = service.getChartComponentName();
      const humanizedFilters = service.humanizeFilters(reportPage.filters);
      Object.keys(humanizedFilters).forEach((key) => {
        if (!humanizedFilters[key]) {
          delete humanizedFilters[key];
        }

        if (Array.isArray(humanizedFilters[key]) && !humanizedFilters[key].length) {
          humanizedFilters[key] = 'All';
        }
      });

      return {
        id,
        ...reportPage,
        chartComponentName,
        humanizedFilters,
      };
    },
    async takeScreenshot() {
      await this.$nextTick();

      const chartHeight = this.$refs.chart.children[0].clientHeight;
      if (chartHeight + addedPadding > contentMaxHeight) {
        this.scale = 616 / (chartHeight + 32);
        await this.$nextTick();
      }

      const screenshot = await this.getImage(
        this.$refs['screenshot-container'],
        CardDataService(this.page.type).getReportConfiguration(),
      );

      return screenshot;
    },
    toggleAnimation(value) {
      Highcharts.setOptions({
        chart: {
          animation: value,
        },
        plotOptions: {
          series: {
            animation: value,
          },
          spline: {
            animation: value,
          },
          areaspline: {
            animation: value,
          },
          solidgauge: {
            animation: value,
          },
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/_colors.scss';

.screenshot-container {
  position: absolute;
  left: -30000px;
  background-color: #fff;

  .content {
    flex: 80;
    height: 616px;
    padding: 16px;
    overflow: hidden;

    div {
      width: 100%;
      border: 1px solid $bhp-functional-grey-3;
    }
  }

  .footer {
    flex: 20;
    padding: 16px;
    overflow: hidden;

    h1 {
      font-size: 20px;
    }

    ul {
      font-size: 14px;
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      li {
        margin-right: 32px;
      }
    }

    .logo {
      height: 64px;
    }
  }
}
</style>
