
<v-row no-gutters>
  <v-col>
    <div class="px-0 d-flex align-center">
      <Badge v-for="(color, key) in colors" :key="key" :text="key" :color="color" />
    </div>
  </v-col>
  <v-col cols="auto" class="svp__table">
    <v-simple-table>
      <thead>
        <tr class="svp__table__tr">
          <th class="svp__table__th">Lead indicator</th>
          <th class="svp__table__th">Performance rating</th>
          <th class="svp__table__th text-center">Sector leader</th>
          <th class="svp__table__th text-center">Gap-to-sector leader</th>
          <th class="svp__table__th text-center">Overall SV(P)I</th>
        </tr>
      </thead>
      <tbody>
        <tr class="svp__table__tr__trust">
          <td class="svp__table__td__attribute font-weight-bold">Trust</td>
          <td class="svp__table__td__attribute">
            <Bar :value="toPercent(datas.trust.performance)" text="%" :color="trustColor" />
          </td>
          <td class="svp__table__td" v-if="datas.trust.sectorleader">
            <template v-if="getCompanyImage(datas.trust.sectorleader)">
              <img
                :src="leaderImage(datas.trust.sectorleader)"
                style="max-width: 130px; max-height: 34px"
                :title="datas.trust.sectorleader"
              />
            </template>
            <template v-else>
              {{ datas.trust.sectorleader }}
            </template>
          </td>
          <td class="svp__table__td" v-else>
            <v-tooltip bottom color="white" class="insufficient-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on"> mdi-circle-off-outline </v-icon>
              </template>
              <span class="black--text">Insufficient data available</span>
            </v-tooltip>
          </td>
          <td class="svp__table__td" v-if="!isNaN(datas.trust.gaptoleader)">
            {{ datas.trust.gaptoleader | gapToPercent }}
          </td>
          <td class="svp__table__td" v-else>
            <v-tooltip bottom color="white" class="insufficient-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on"> mdi-circle-off-outline </v-icon>
              </template>
              <span class="black--text">Insufficient data available</span>
            </v-tooltip>
          </td>
          <td :rowspan="dataCells.length + 1" class="svp__table__td__overall">
            <div class="fill-height">
              <activity-gauge
                :svi="datas.overallsvpi.svpi"
                :awareness="datas.overallsvpi.awareness"
                class="svp-gauge"
              />
              <div v-if="datas.overallsvpi.svpi" class="text-center">
                <b
                  :class="{
                    'positive-gap-to-average': datas.overallsvpi.gapToAverage > 0,
                    'negative-gap-to-average': datas.overallsvpi.gapToAverage < 0,
                  }"
                  >{{ datas.overallsvpi.gapToAverage }}</b
                >
                p.p. gap-to-average
              </div>
            </div>
          </td>
        </tr>
        <tr v-for="(dataCell, index) of dataCells" :key="index" class="svp__table__tr">
          <td class="svp__table__td__attribute">{{ dataCell.attribute }}</td>
          <td class="svp__table__td__attribute">
            <Bar
              :value="toPercent(dataCell.performance)"
              text="%"
              :color="colors[dataCell.theme]"
            />
          </td>
          <td class="svp__table__td" v-if="dataCell.sectorleader">
            <template v-if="getCompanyImage(dataCell.sectorleader)">
              <img
                :src="leaderImage(dataCell.sectorleader)"
                style="max-width: 130px; max-height: 34px"
                :title="dataCell.sectorleader"
              />
            </template>
            <template v-else>
              {{ dataCell.sectorleader }}
            </template>
          </td>
          <td class="svp__table__td" v-else>
            <v-tooltip bottom color="white" class="insufficient-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on"> mdi-circle-off-outline </v-icon>
              </template>
              <span class="black--text">Insufficient data available</span>
            </v-tooltip>
          </td>
          <td class="svp__table__td" v-if="!isNaN(dataCell.gaptoleader)">
            {{ dataCell.gaptoleader | gapToPercent }}
          </td>
          <td class="svp__table__td" v-else>
            <v-tooltip bottom color="white" class="insufficient-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on"> mdi-circle-off-outline </v-icon>
              </template>
              <span class="black--text">Insufficient data available</span>
            </v-tooltip>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-col>
</v-row>
