<template>
  <div>
    <v-navigation-drawer
      app
      clipped
      class="menu-nav"
      width="270"
      color="bhpNavBlueGrey1"
      v-model="drawer"
      :style="{
        top: $vuetify.application.top + 'px',
        zIndex: 6,
        height: 'calc(100% - ' + $vuetify.application.top + 'px)',
      }"
    >
      <Menu />
    </v-navigation-drawer>
    <v-app-bar app clipped-left class="app-bar" elevation="1" color="white">
      <v-app-bar-nav-icon @click.stop="() => (drawer = !drawer)" />
      <v-toolbar-title class="title">
        {{ title }}
      </v-toolbar-title>
      <v-spacer />
      <v-menu offset-y v-model="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn elevation="0" :ripple="false" x-large v-bind="attrs" v-on="on" class="user-menu">
            <v-icon size="45"> account_circle </v-icon>
            <v-icon v-if="!menu"> expand_more </v-icon>
            <v-icon v-else> expand_less </v-icon>
          </v-btn>
        </template>
        <v-list class="user-menu-list pa-0">
          <!-- <v-list-item>
            <v-list-item-content>
              <v-list-item-title style="color: #666">{{ userName }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <v-list-item @click="signOut()">
            <v-list-item-content class="menu-item">
              <v-icon size="25" class="icon"> logout </v-icon>
              <v-list-item-title class="item-title">Sign-out</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import eventBus from '@/EventBus';
import { signOut, getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import Menu from './Menu.vue';

export default {
  data() {
    return {
      menu: false,
      drawer: true,
      currentUser: {},
      isCognitoUserPool: false,
    };
  },
  async created() {
    eventBus.on('logout', this.signOut);
    this.currentUser = await getCurrentUser();
    const session = await fetchAuthSession();
    this.isCognitoUserPool = session.tokens?.idToken?.payload?.iss?.includes('cognito');
  },
  methods: {
    async signOut() {
      try {
        await signOut();
        if (this.isCognitoUserPool) {
          this.$router.push('/login');
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    },
  },
  computed: {
    ...mapGetters('assets', ['assetSelected', 'countrySelected']),
    title() {
      const title = `${this.$route.meta.title || this.$route.name}`
        .replace(':country', this.countrySelected || '')
        .replace(':asset', this.assetSelected || '')
        .replace(':username', this.userName || '');
      if (!this.assetSelected) {
        return title.replace(' - ', '');
      }
      return title;
    },
    userName() {
      return this.currentUser?.signInDetails?.loginId;
    },
  },
  components: { Menu },
};
</script>

<style lang="scss" scoped>
@import '@styles/_colors.scss';
@import '@styles/_variables.scss';
@import '@styles/_fonts.scss';

.app-bar {
  z-index: 999 !important;

  &::v-deep .v-toolbar__content {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .title {
    color: $bhp-blue-1;
    font-size: $bhp-large-title-font-size !important;
  }

  .user-menu {
    height: 100% !important;
    padding: 0 0 0 6px !important;
    background-color: transparent !important;
    color: $bhp-blue-1;
    font-size: $bhp-title-font-size;
    border-radius: 0;

    &::before {
      border-radius: 0;
    }
  }

  .user-avatar {
    background-color: $bhp-nav-blue-grey-3;
    margin-right: 5px;
    color: $bhp-blue-1;
    font-size: $bhp-title-font-size;
  }
}

.user-menu-list {
  min-width: 150px;
}

.menu-item {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  gap: 10px;

  .icon {
    flex: 0 0 30px;
  }

  .item-title {
    flex: auto;
  }
}
</style>
