<template>
  <v-menu
    v-model="isMenuOpen"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    offset-overflow
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        @click="isMenuOpen = !isMenuOpen"
        @click:append="isMenuOpen = !isMenuOpen"
        :append-icon="isMenuOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'"
        v-bind="attrs"
        v-on="on"
        outlined
        class="select-btn rounded-0"
      >
        {{ label }}
        <v-icon v-if="isMenuOpen" right dark> mdi-chevron-up </v-icon>
        <v-icon v-else right dark> mdi-chevron-down </v-icon>
      </v-btn>
    </template>

    <v-container v-if="items.length" class="py-0 px-0">
      <v-tabs v-model="tab">
        <v-tab v-for="tab in items" :key="tab.label">
          {{ tab.label }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="tab in items" :key="tab.label">
          <v-row justify="space-between" class="pt-4">
            <v-col cols="auto" class="pt-0">
              <v-checkbox
                v-for="checkbox in tab.buckets"
                :key="checkbox"
                :label="checkbox"
                :input-value="isChecked(tab.type, checkbox)"
                @change="toggleChecked(tab.type, checkbox)"
                class="checkbox"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col cols="auto">
              <v-btn small outlined tile class="mod-btn mr-4" @click="toggleAll(tab.type, true)"
                >All</v-btn
              >
              <v-btn small outlined tile class="mod-btn mr-4" @click="toggleAll(tab.type, false)"
                >None</v-btn
              >
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-container>
    <v-list v-else dense class="pa-0">
      <v-list-item>
        <v-list-item-title>No data available</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'tabbed-multi-select',

  props: {
    label: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    tab: null,
    model: {},
    lastModel: {},
    isMenuOpen: false,
  }),

  beforeMount() {
    this.mapItemsToModel();
  },

  watch: {
    // Update model based on item props, loaded asynchronously
    items(newItems, oldItems) {
      if (newItems.length !== oldItems.length) {
        this.mapItemsToModel();
      }
    },
    // Emit event when menu is closed
    isMenuOpen() {
      if (!this.isMenuOpen) {
        // Check if changes have been made
        if (JSON.stringify(this.model) === JSON.stringify(this.lastModel)) {
          return;
        }
        // If there are changes, update lastModel
        this.lastModel = JSON.parse(JSON.stringify(this.model));

        // If all items are selected for a tab, set the tab to null
        const emitModel = {};
        for (const [modelTabName, modelTabItems] of Object.entries(this.model)) {
          // Array of all items for tab from props
          const itemTab = this.items.find((x) => x.type === modelTabName);
          // If model tab items are the same as props tab items, set tab to null
          if (JSON.stringify(modelTabItems) === JSON.stringify(itemTab.buckets)) {
            emitModel[modelTabName] = null;
          } else {
            emitModel[modelTabName] = JSON.parse(JSON.stringify(modelTabItems));
          }
        }
        this.$emit('input', emitModel);
      }
    },
  },

  methods: {
    // Map item props to model
    mapItemsToModel() {
      this.model = this.items.reduce((model, tab) => {
        model[tab.type] = tab.buckets.map((x) => x);
        return model;
      }, {});
      // Save lastModel as a way to track if changes have been made
      this.lastModel = JSON.parse(JSON.stringify(this.model));
    },

    // Toggle checked state of a checkbox
    toggleChecked(tabType, checkboxLabel) {
      // Update data model
      const index = this.model[tabType].indexOf(checkboxLabel);
      if (index === -1) {
        this.model[tabType].push(checkboxLabel);
      } else {
        this.model[tabType].splice(index, 1);
      }
    },

    // Toggle all checkboxes for a tab
    toggleAll(tabType, checked) {
      const item = this.items.find((x) => x.type === tabType);
      this.model[tabType] = checked ? item.buckets.map((x) => x) : [];
    },

    // Check if a checkbox is checked
    isChecked(tabType, checkboxLabel) {
      return this.model[tabType].includes(checkboxLabel);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/_colors.scss';

.select-btn::v-deep {
  width: 100%;
  height: 40px !important;
  padding-left: 12px !important;
  justify-content: flex-start;
  color: $bhp-blue-1;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: normal;
  text-transform: none;

  .v-btn__content {
    justify-content: space-between;
  }

  .v-icon {
    font-size: 24px;
  }
}

.v-tab {
  border-bottom: 1px solid $bhp-functional-grey-1;
  border-right: 1px solid $bhp-functional-grey-1;
}

.checkbox::v-deep {
  padding: 0;
  margin: 0 0 0 16px;

  label {
    margin-left: 24px;
    padding: 14px 0;
    font-size: 13px;
    color: $bhp-blue-1;
  }
}

.mod-btn {
  border-color: $bhp-nav-blue-grey-4;
  color: $bhp-text-grey;
}
</style>
