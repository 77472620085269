// authService.js
import { Hub } from 'aws-amplify/utils';
import { signInWithRedirect, fetchAuthSession } from 'aws-amplify/auth';
import router from '@/router';
import { SESSION_TOKEN } from '@/services/HttpRequestService';

export default {
  async loginWithRedirect(provider) {
    await signInWithRedirect({ provider });
  },
  listenToAuthEvents() {
    Hub.listen('auth', async ({ payload: { event } }) => {
      switch (event) {
        case 'signInWithRedirect':
          try {
            const session = await fetchAuthSession();
            sessionStorage.setItem(SESSION_TOKEN, session.tokens?.idToken?.toString());
            router.push('/');
            // Handle user state
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error('Error getting the current user', e);
          }
          break;
      }
    });
  },
};
