import html2canvas from 'html2canvas';

export default {
  methods: {
    async getImage(element, reportConfig) {
      const canvas = await html2canvas(element, {
        allowTaint: true,
        useCORS: true,
        scale: 4,
        backgroundColor: reportConfig.backgroundColor,
        ignoreElements: reportConfig.ignoreElements,
      });
      return canvas.toDataURL();
    },
  },
};
