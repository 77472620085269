
<div>
  <v-navigation-drawer
    app
    clipped
    class="menu-nav"
    width="270"
    color="bhpNavBlueGrey1"
    v-model="drawer"
    :style="{
      top: $vuetify.application.top + 'px',
      zIndex: 6,
      height: 'calc(100% - ' + $vuetify.application.top + 'px)',
    }"
  >
    <Menu />
  </v-navigation-drawer>
  <v-app-bar app clipped-left class="app-bar" elevation="1" color="white">
    <v-app-bar-nav-icon @click.stop="() => (drawer = !drawer)" />
    <v-toolbar-title class="title">
      {{ title }}
    </v-toolbar-title>
    <v-spacer />
    <v-menu offset-y v-model="menu">
      <template v-slot:activator="{ on, attrs }">
        <v-btn elevation="0" :ripple="false" x-large v-bind="attrs" v-on="on" class="user-menu">
          <v-icon size="45"> account_circle </v-icon>
          <v-icon v-if="!menu"> expand_more </v-icon>
          <v-icon v-else> expand_less </v-icon>
        </v-btn>
      </template>
      <v-list class="user-menu-list pa-0">
        <!-- <v-list-item>
          <v-list-item-content>
            <v-list-item-title style="color: #666">{{ userName }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <v-list-item @click="signOut()">
          <v-list-item-content class="menu-item">
            <v-icon size="25" class="icon"> logout </v-icon>
            <v-list-item-title class="item-title">Sign-out</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</div>
