import { CardBaseService } from './CardBaseService';
import Insights from '@utils/insights';
import { FILTERS_TYPES } from '@/utils/panel-filters';
import { CARDS_TYPES } from '@services/CardDataService.js';
import { toPercent } from '@/utils/helpers';
import { strToQuarterAndYear } from '@/utils/quarters';
import { getDefaultTimePeriod } from '@/utils/defaultTimePeriods';
import { AUSTRALIA, isAudienceFilterHidden } from '@/utils/countries';

export default class TopSVPiCountryService extends CardBaseService {
  static getCardType() {
    return CARDS_TYPES.TOP_SVPI_COUNTRY;
  }

  static getChartTitle() {
    return 'Leading Drivers of Reputation: Sector Comparison';
  }

  static getChartComponentName() {
    return 'TopSVPiCountryChart';
  }

  static getFilters(defaultFilters = {}, location = { country: null, asset: null }) {
    const INSIGHT = Insights.get(location.country, location.asset);

    const { defaultTimeperiod, defaultAudience, defaultDemographic, defaultTimePeriodByQuarter } =
      defaultFilters;

    const audience =
      INSIGHT === Insights.ASSET || INSIGHT === Insights.ALL_HOST_COMMUNITIES
        ? 'Host Community'
        : defaultAudience;

    return {
      year: getDefaultTimePeriod(location) ?? defaultTimeperiod ?? null,
      quarter: getDefaultTimePeriod(location) ?? defaultTimePeriodByQuarter ?? null,
      audience: audience ?? null,
      demographic: defaultDemographic ?? null,
      community: this.getDefaultCommunity(location.asset, location.country),
    };
  }

  static getFiltersConfig(model, filters) {
    const { asset, country } = { ...filters };
    const INSIGHT = Insights.get(country, asset);

    let timePeriod = { type: FILTERS_TYPES.TIME_PERIOD, model, key: 'year' };
    // For Australia national view, we use quarter periods
    if (INSIGHT === Insights.NATIONAL && country === AUSTRALIA) {
      timePeriod = { type: FILTERS_TYPES.TIME_PERIOD_BY_QUARTER, model, key: 'quarter' };
    }

    return [
      {
        type: FILTERS_TYPES.DEMOGRAPHIC,
        model,
        key: 'demographic',
        country,
        isLocal: !!asset,
        isHidden: INSIGHT === Insights.GLOBAL,
      },
      {
        type: FILTERS_TYPES.AUDIENCE,
        model,
        key: 'audience',
        isHidden: isAudienceFilterHidden(country, asset),
      },
      {
        type: FILTERS_TYPES.COMMUNITY,
        model,
        key: 'community',
        isHidden: INSIGHT !== Insights.ASSET,
        items: this.getCommunities(asset, country),
      },
      timePeriod,
    ];
  }

  static getReportPageConfig({ location = {}, filters, data }) {
    return {
      type: this.getCardType(),
      title: this.getChartTitle(),
      data: {
        datas: data,
      },
      filters: {
        ...filters,
        country: location.country,
        asset: location.asset,
      },
      rows: this.getRows(data, filters, location.country, location.asset),
    };
  }

  static getRows(data, filters, country, asset) {
    if (!data) return [];

    const { attributes, trust, overallsvpi } = data;

    const rows = [
      this.formatValue(country, { ...trust, theme: '', attribute: 'Trust' }),
      ...attributes.map((attribute) => this.formatValue(country, attribute)),
    ].map((value) => ({
      ...value,
      'Overall SV(P)I - Awareness': toPercent(overallsvpi.awareness),
      'Overall SV(P)I - SV(P)I': toPercent(overallsvpi.svpi),
    }));

    // Add description of filters to last row
    if (rows.length && filters?.year) {
      this.addFiltersToRows(rows, filters, country, asset);
    }
    return rows;
  }

  static formatValue(country, value) {
    return {
      Country: country,
      Theme: value?.theme,
      'Lead indicator': value?.attribute,
      'Performance rating': `${toPercent(value?.performance)}%`,
      'Sector leader': value?.sectorleader,
      'Gap-to-sector leader': toPercent(value?.gaptoleader),
    };
  }

  static humanizeFilters({ audience, demographic, year, quarter, country, asset, community }) {
    const { age, gender, shareholder, votingPref } = demographic || {};
    const INSIGHT = Insights.get(country, asset);

    if (INSIGHT === Insights.GLOBAL) {
      return {
        Country: country,
        'Time Period': year,
      };
    }

    if (INSIGHT === Insights.NATIONAL) {
      return {
        Country: country,
        'Time Period': year,
        ...(country === AUSTRALIA && { Audience: audience }),
        Age: age,
        Gender: gender,
        Shareholder: shareholder,
        'Voting Preference': votingPref,
      };
    }

    return {
      'Host Community': `${asset} (${country})`,
      Community: community,
      'Time Period': quarter,
      Age: age,
      Gender: gender,
      Shareholder: shareholder,
      'Voting Preference': votingPref,
    };
  }

  static async loadData({ location, filters }) {
    const INSIGHT = Insights.get(location.country, location.asset);
    const params = {
      period: filters.year,
      asset: location.asset,
      country: location.country,
      audience: filters.audience,
      community: INSIGHT === Insights.ASSET ? filters.community : null,
      ...filters.demographic,
    };

    // If Australia national view, we use quarter periods
    if (INSIGHT === Insights.NATIONAL && location.country === AUSTRALIA) {
      const { quarter, year } = strToQuarterAndYear(filters.quarter);
      params.period = year;
      params.quarter = quarter;
    }

    // Set audience for host communities
    if (INSIGHT === Insights.ALL_HOST_COMMUNITIES || INSIGHT === Insights.ASSET) {
      params.audience = 'Host Community';
    }

    const datas = await this.httpRequestService.get('/api/v2/svp-comparison', null, params, false);
    return datas[0];
  }
}
