<template>
  <div>
    <highcharts :options="chart" :key="JSON.stringify(colors)"></highcharts>
  </div>
</template>

<script>
import store from '@/store';
import { mapColorsToCompanies } from '@/utils/companies';
import moment from 'moment';

export default {
  name: 'VolumeChart',

  props: {
    data: {
      type: Array,
      default: () => [],
    },
    height: {
      type: [Number, String],
      default: 300,
    },
  },

  data() {
    return {
      chart: {},
    };
  },

  mounted() {
    this.makeChart();
  },

  computed: {
    colors() {
      return mapColorsToCompanies(this.data);
    },
  },

  methods: {
    makeChart() {
      this.chart = {
        chart: {
          height: this.height,
          type: 'spline',
          plotBorderWidth: 0,
          spacingRight: 1,
          spacingBottom: 1,
          spacingLeft: 0,
          style: {
            fontFamily: "'Graphik Web', sans-serif",
          },
        },
        title: {
          text: '',
        },
        subtitle: {
          text: '··· Online News  &nbsp;&nbsp;&#8212; Social Media ',
          align: 'right',
          verticalAlign: 'bottom',
          useHTML: 'true',
        },
        credits: {
          enabled: false,
        },
        yAxis: {
          title: {
            text: '',
          },
          min: 0,
        },
        colors: this.colors,
        xAxis: {
          type: 'datetime',
          dateTimeLabelFormats: {
            // don't display the dummy year
            month: '%e %b',
            year: '%b',
          },
        },
        tooltip: {
          useHTML: true,
          backgroundColor: null,
          borderWidth: 0,
          shadow: false,
          formatter() {
            return `<div class="_tooltip">
              <div class="_bar" style="background-color:${this.color};margin-bottom:8px;">
            </div><div style="margin-bottom:5px;"><b>Volume: ${this.point.y}</b></div>
            ${this.series.name} | ${this.series.options.custom.mediaType} | 
            ${moment(this.x).format('YYYY-MM-DD')}</div>`;
          },
        },
        legend: {
          align: 'right',
          padding: 20,
          itemStyle: {
            fontWeight: 'normal',
          },
        },
        plotOptions: {
          spline: {
            marker: {
              enabled: false,
              symbol: 'circle',
              lineColor: null,
              lineWidth: 0,
            },
          },
          series: {
            events: {
              click: function (event) {
                store.dispatch(
                  'digitalIntelPeriod/setDigitalIntelPeriod',
                  moment(event.point.date),
                );
              },
            },
          },
        },
        series: this.data.map((line) => {
          delete line.color;
          return {
            ...line,
            custom: {
              mediaType: line.mediaType?.toLowerCase() === 'news' ? 'Online News' : 'Social Media',
            },
            data: line.data.filter((point) => point.y !== null),
          };
        }),
      };
    },
  },

  watch: {
    data() {
      this.makeChart();
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@styles/_colors.scss';

.y-axis-subtitles {
  display: none;
  flex-direction: column;
  font-size: 10px;
  height: 100%;
  margin-left: -4px;
  justify-content: space-between;
  position: absolute;
  z-index: 7;

  span {
    transform: rotate(-90deg);
    color: $bhp-negative-red;

    &:first-child {
      color: $bhp-positive-green;
      margin-top: 12px;
    }
  }
}
</style>
