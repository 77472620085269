
<v-menu
  v-model="isMenuOpen"
  :close-on-content-click="false"
  transition="scale-transition"
  offset-y
  right
  content-class="bhp-select-menu menu"
>
  <template v-slot:activator="{ on, attrs }">
    <v-text-field
      :value="valueSected"
      @click="isMenuOpen = !isMenuOpen"
      @click:append="isMenuOpen = !isMenuOpen"
      :label="label"
      :append-icon="isMenuOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'"
      :class="`bhp-select select-width-${width} mutli-select`"
      outlined
      dense
      hide-details
      readonly
      v-bind="attrs"
      v-on="on"
    ></v-text-field>
  </template>
  <div class="buttons" v-if="includeToggles">
    <v-btn
      small
      outlined
      tile
      class="mod-btn mr-4"
      @click="toggleAll(true)"
    >All</v-btn>
    <v-btn
      small
      outlined
      tile
      class="mod-btn"
      @click="toggleAll(false)"
    >None</v-btn>
  </div>
  <v-list
    dense max-height="250px"
    :class="`multi-list bhp-select-list ${includeToggles && 'with-toggles'}`"
  >
    <v-list-item v-if="!items.length">
      No data Available
    </v-list-item>
    <v-list-item
      v-for="(item, index) in itemList"
      :key="`${item}-${index}`"
      :disabled="isItemDisabled(item)"
      class="v-list-item--link"
    >
      <v-list-item-action class="mr-2">
        <v-checkbox
          :ripple="false"
          color="bhpBlue2"
          v-model="item.checked"
          @change="addOrRemoveItemFromChecked(item)"
          hide-details
        ></v-checkbox>
      </v-list-item-action>
      <v-list-item-content
        @click="checkItem(item)"
      >
        <v-list-item-title>
          {{ item.text }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</v-menu>
