
<div class="selector__table">
  <v-simple-table class="table" dense>
    <template v-slot:default>
      <thead class="selector__table__thead">
        <tr class="selector__table__tr">
          <th class="selector__table__th invisible-cell-1"></th>
          <th
            v-for="(svpi, index) in datas.svpis"
            :key="index"
            class="selector__table__th top-cell"
          >
            <flag :iso="countryCodes[svpi.country]" class="mb-n1 mr-2" size="36" /> <br />
            <small>{{ svpi.country }}</small>
          </th>
        </tr>
      </thead>
      <tbody class="selector__table__tbody">
        <tr class="selector__table__tr">
          <td class="selector__table__td invisible-cell-2"></td>
          <td v-for="(svpi, index) in datas.svpis" :key="index" class="selector__table__td cell">
            <ActivityGauge
              :svi="svpi.overallsvpi.svpi"
              :awareness="svpi.overallsvpi.awareness || 0"
              class="activigauge-centered"
            />
          </td>
        </tr>
        <tr class="selector__table__tr">
          <td class="selector__table__td start-cell">Gap to&nbsp;average</td>
          <td v-for="(svpi, index) in datas.svpis" :key="index" class="selector__table__td cell">
            <template v-if="svpi.gaptoaverage">
              <b
                :class="{
                  'positive-gap-to-average': svpi.gaptoaverage > 0,
                  'negative-gap-to-average': svpi.gaptoaverage < 0,
                }"
                >{{ svpi.gaptoaverage }}</b
              >
            </template>
            <template v-else>
              <v-icon> mdi-minus </v-icon>
            </template>
          </td>
        </tr>
        <tr>
          <td class="selector__table__td start-cell" style="vertical-align: middle">
            Sector&nbsp;leader
          </td>
          <td v-for="(svpi, index) in datas.svpis" :key="index" class="selector__table__td cell">
            <template v-if="getCompanyImage(svpi.sectorleader)">
              <img
                :src="leaderImage(svpi.sectorleader)"
                style="height: 32px"
                :title="svpi.sectorleader"
              />
            </template>
            <template v-else>
              {{ svpi.sectorleader }}
            </template>
          </td>
        </tr>
        <tr>
          <td class="selector__table__td start-cell">Top 5 Social Value Drivers</td>
          <td v-for="(svpi, index) in datas.svpis" :key="index" class="selector__table__td cell">
            <span
              v-if="!svpi.top5attributes.length"
              class="d-flex flex-column align-center justify-center"
            >
              <v-icon x-large> mdi-circle-off-outline </v-icon>
              <span class="black--text font-weight-medium mt-2"> No Data Available </span>
            </span>
            <p
              v-for="(attribute, index) of svpi.top5attributes"
              class="drivers-list"
              :key="index"
            >
              <b>{{ index + 1 }}.</b> {{ attribute }}
            </p>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</div>
