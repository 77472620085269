<template>
  <v-list class="pa-0 menu-list">
    <v-list-item class="menu-title">
      <v-list-item-content class="pa-0">
        <v-list-item-title>
          <v-img :src="logo" contain width="155" max-height="32" class="logo" />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item to="/" :ripple="false" :class="isActive('/')">
      <v-list-item-icon>
        <span class="material-icons-outlined"> home </span>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Home</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-subheader class="sub-header text-uppercase"> reporting </v-subheader>
    <v-list-item to="/report-builder" :ripple="false" :class="isActive('/report-builder')">
      <v-list-item-icon>
        <span class="material-icons-outlined"> layers </span>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>
          Report Builder
          <v-avatar color="primary" size="20" v-if="addOnBuilderPagesCount">
            {{ addOnBuilderPagesCount }}
          </v-avatar>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-subheader class="sub-header text-uppercase"> dashboard </v-subheader>
    <v-list-item
      v-for="({ key, name }, index) in countries"
      :key="`list-item-${index}`"
      :to="`/${name.replaceAll(' ', '_')}`"
      :ripple="false"
      class="country d-flex align-center"
      :class="isActive(`/${name.replaceAll(' ', '_')}`)"
    >
      <v-list-item-icon class="align-self-center mr-1">
        <flag :iso="key" size="18" />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ name }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-subheader class="sub-header text-uppercase"> live data </v-subheader>
    <!-- <v-list-item
      to="/digital-intelligence"
      :ripple="false"
      :class="isActive('/digital-intelligence')"
    >
      <v-list-item-icon>
        <span class="material-icons-outlined"> preview </span>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Digital Intelligence</v-list-item-title>
      </v-list-item-content>
    </v-list-item> -->
    <v-subheader class="sub-header text-uppercase"> more </v-subheader>
    <v-list-item
      class="more-link mb-2"
      :class="isActive('/report-library')"
      to="/report-library"
      :ripple="false"
    >
      <v-list-item-icon>
        <span class="material-icons-outlined"> folder </span>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>Report Library</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item
      class="more-link"
      :class="isActive('/about-data')"
      to="/about-data"
      :ripple="false"
    >
      <v-list-item-icon>
        <span class="material-icons-outlined"> info </span>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>About Data</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapGetters } from 'vuex';
import { getCodeFromCountries } from '@utils/countries';
import logo from '@/assets/imgs/logo.svg';

export default {
  data() {
    return {
      countries: [],
    };
  },
  props: {
    model: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters('selects', ['assetsAndCommunitiesByCountries']),
    ...mapGetters('reportbuilder', ['addOnBuilderPagesCount']),
    logo() {
      return logo;
    },
  },
  methods: {
    isActive(url) {
      return {
        active: url !== '/' ? decodeURI(this.$route.path).includes(url) : url === this.$route.path,
      };
    },
  },
  async created() {
    await this.onSelectsLoaded(async () => {
      this.countries =
        this.assetsAndCommunitiesByCountries.map(({ country }) => {
          const key = getCodeFromCountries(country);
          return {
            name: country,
            key,
          };
        }) || [];
    });
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/_colors.scss';
@import '@styles/_fonts.scss';

.menu-list {
  margin-bottom: 32px;
}

.menu-title {
  padding: 0 24px;
  .logo {
    margin-right: auto;
  }

  margin-bottom: 32px;
  margin-top: 40px;
}

.sub-header {
  margin-top: 32px;
  font-size: $bhp-caption-font-size;
  line-height: $bhp-caption-line-height;
  color: $bhp-nav-blue-grey-3;
  font-weight: bold;
  height: $bhp-caption-line-height;
  margin-bottom: 8px;
  padding: 0 24px;
}

.divider {
  margin: 0 24px;
}

.v-list-item:not(.menu-title) {
  min-height: 24px;
  max-height: 24px;
  padding: 4px 24px;
  font-weight: 500;
  color: $bhp-blue-1 !important;

  .v-avatar {
    color: $bhp-nav-blue-grey-1 !important;
    font-size: $bhp-caption-font-size;
    line-height: $bhp-caption-line-height;
    margin-top: -4px;
  }

  .v-list-item__content {
    padding: 0;
  }

  .v-list-item__title {
    font-size: $bhp-title-font-size;
    line-height: 24px;
  }

  &::before {
    opacity: 0;
  }

  &:hover {
    color: $bhp-blue-2 !important;
  }

  .v-list-item__icon {
    margin: 0;

    &:first-child {
      margin-right: 0;
    }

    .material-icons-outlined {
      font-size: $bhp-title-font-size;
      line-height: $bhp-title-line-height;
    }
  }

  &.active::after {
    border-right: 4px solid $bhp-blue-2;
    position: absolute;
    right: 0px;
    height: 32px;
  }

  &.country {
    .v-list-item__icon {
      font-size: $bhp-title-font-size;
      padding-top: 1px;
    }

    &::before {
      transition: none;
    }

    &:hover {
      &::before {
        background-color: $bhp-nav-blue-grey-2;
        left: 24px;
        right: 24px;
        opacity: 1;
        z-index: -1;
      }
      color: $bhp-blue-1 !important;
    }

    &.active {
      color: $bhp-nav-blue-grey-1 !important;
      &::after {
        border-right: none;
        position: relative;
        height: 24px;
      }

      &::before {
        background-color: $bhp-blue-2 !important;
        opacity: 1;
        z-index: -1;
        left: 24px;
        right: 24px;
      }
    }
  }

  .more-link {
    margin-bottom: 8px;
  }
}
</style>
