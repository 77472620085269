import Insights from '@utils/insights';

export default {
  computed: {
    isGlobalInsight: function () {
      return Insights.get(this.country, this.asset) === Insights.GLOBAL;
    },
    isAllHostCommunitiesInsight: function () {
      return Insights.get(this.country, this.asset) === Insights.ALL_HOST_COMMUNITIES;
    },
    isNationalInsight: function () {
      return Insights.get(this.country, this.asset) === Insights.NATIONAL;
    },
    isAssetInsight: function () {
      return Insights.get(this.country, this.asset) === Insights.ASSET;
    },
  },
};
