<template>
  <highcharts :options="chartOptions"></highcharts>
</template>

<script>
import theme from '@/theme';
import moment from 'moment';

export default {
  name: 'NewsHeardChart',

  props: {
    lines: {
      type: Array,
      required: true,
    },
    height: {
      type: [Number, String],
      default: 200,
    },
    showMonths: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    chartOptions() {
      const ctx = this;

      return {
        chart: {
          height: this.height,
          type: 'line',
          plotBorderWidth: 0,
          spacingRight: 1,
          spacingBottom: 1,
          spacingLeft: 0,
          timezone: 'Australia/Sydney',
          style: {
            fontFamily: "'Graphik Web', sans-serif",
          },
        },

        colors: [theme['bhpChartGreen']],

        credits: {
          enabled: false,
        },

        legend: {
          enabled: false,
        },

        title: {
          text: '',
        },

        xAxis: {
          type: 'datetime',
          gridLineWidth: 1,
          labels: {
            format: ctx.showMonths ? '{value:%b %y}' : '{value:%Y}',
          },
        },

        yAxis: {
          gridLineWidth: 1,
          labels: {
            format: '{value}%',
          },
          title: null,
        },

        series: [
          {
            name: 'SMP',
            data:
              ctx.lines?.map((line) => [
                moment(line.date, 'YYYY-MM').valueOf(),
                Math.round(line.smp * 100),
              ]) ?? [],
            type: 'spline',
          },
        ],

        tooltip: {
          useHTML: true,
          formatter() {
            return ctx.showMonths
              ? `${new Date(this.x).toLocaleString('default', { month: 'short' })} ${new Date(
                  this.x,
                ).getFullYear()}: <strong>${this.y}%</strong>`
              : `${new Date(this.x).getFullYear()}: <strong>${this.y}%</strong>`;
          },
        },
      };
    },
  },
};
</script>
