<template>
  <v-select
    :label="label"
    :class="`bhp-select select-width-${width}`"
    v-model="period"
    outlined
    dense
    append-icon="mdi-chevron-down"
    :items="items"
    hide-details
    :menu-props="{ offsetY: true, contentClass: 'bhp-select-list' }"
    :item-text="itemText"
    :item-value="itemValue"
    :key="digitalIntelPeriod"
  />
</template>

<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'date-range-by-period-select',
  props: {
    value: {
      type: Object,
      default: () => ({
        from: moment().subtract(7, 'd').format('YYYY-MM-DD'),
        to: moment().format('YYYY-MM-DD'),
        period: 0,
      }),
    },
    label: {
      type: String,
      default: null,
    },
    width: {
      type: [Number, String],
      default: 100,
    },
    itemText: {
      type: String,
      default: 'text',
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    itemDisabled: {
      type: [Function, String, Array],
      default: 'disabled',
    },
  },
  data() {
    return {
      period: 0,
      items: [
        {
          text: 'Last Week',
          value: 0,
        },
        {
          text: 'Last Month',
          value: 1,
        },
        {
          text: 'Last 3 Months',
          value: 2,
        },
        {
          text: 'Last year',
          value: 3,
        },
      ],
    };
  },
  watch: {
    digitalIntelPeriod() {
      // When the user selects a custom time period, update the period select
      if (this.digitalIntelPeriod) {
        this.items[4] = {
          text: `${this.digitalIntelPeriod.from} - ${this.digitalIntelPeriod.to}`,
          value: 4,
        };
        this.period = 4;
      }
    },

    period() {
      // When the user selects a period, we need to remove the custom time period
      // and dispatch event to reset custom time period
      if (this.period !== 4) {
        this.items.splice(4, 1);
        this.setDigitalIntelPeriod(null);
      }
      const date = {
        from: this.from,
        to: this.to,
        period: this.period,
      };
      this.$emit('input', date);
    },
  },
  methods: {
    ...mapActions('digitalIntelPeriod', ['setDigitalIntelPeriod']),
  },
  computed: {
    ...mapState('digitalIntelPeriod', ['digitalIntelPeriod']),
    from() {
      switch (this.period) {
        case 1:
          return moment().subtract(1, 'M').format('YYYY-MM-DD');
        case 2:
          return moment().subtract(3, 'M').format('YYYY-MM-DD');
        case 3:
          return moment().subtract(1, 'y').format('YYYY-MM-DD');
        case 0:
        default:
          return moment().subtract(7, 'd').format('YYYY-MM-DD');
      }
    },
    to() {
      return moment().format('YYYY-MM-DD');
    },
  },
};
</script>
