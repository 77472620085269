import Highcharts from 'highcharts';
import HighchartsVue from 'highcharts-vue';
import Vue from 'vue';

import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';

import heatmap from 'highcharts/modules/heatmap';
import mapInit from 'highcharts/modules/map';
import highchartsMore from 'highcharts/highcharts-more';
import solidgauge from 'highcharts/modules/solid-gauge';

import loading from './mixins/loading';
import download from './mixins/download';
import insights from './mixins/insights';
import selectMixin from './mixins/selects';
import helpers from './mixins/helpers';
import selects from './plugins/components';
import '@styles/global.scss';
import '@/utils/str.js';

import { Amplify } from 'aws-amplify';
import awsconfig from './amplify-config';

Amplify.configure(awsconfig);

mapInit(Highcharts);

Vue.use(HighchartsVue);
Vue.use(selects);
Vue.mixin(loading);
Vue.mixin(download);
Vue.mixin(insights);
Vue.mixin(selectMixin);
Vue.mixin(helpers);

heatmap(Highcharts);
highchartsMore(Highcharts);
solidgauge(Highcharts);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
