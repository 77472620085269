<template>
  <v-tooltip bottom color="white" class="insufficient-tooltip">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on">
        mdi-circle-off-outline
      </v-icon>
    </template>
    <span class="black--text">Insufficient data available</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'InsufficientDataTooltip',
};
</script>

