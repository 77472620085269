
<div v-if="datas">
  <v-row class="chart-row">
    <v-col cols="2"><span class="title-col">Pillar</span></v-col>
    <v-col cols="10" class="no-border">
      <v-row>
        <v-col cols="2" v-for="(category, index) in datas.categories" :key="index">
          <span class="category-label">{{ category }}</span>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <v-row class="chart-row">
    <v-col cols="2"><span class="title-col">Lead Indicator</span></v-col>
    <v-col cols="10" class="no-border">
      <v-row>
        <v-col cols="2" v-for="(indicator, index) in datas.indicators" :key="index">{{
          indicator
        }}</v-col>
      </v-row>
    </v-col>
  </v-row>
  <v-row v-for="(section, sectionIndex) in datas.sections" :key="sectionIndex" class="chart-row">
    <v-col cols="2"
      ><span class="title-col">{{ section.name }}</span></v-col
    >
    <v-col cols="10" class="no-border">
      <v-row>
        <v-col cols="2" v-for="(score, scoreIndex) in section.scores" :key="scoreIndex">
          <SocialValueGauge :socialValue="score.score" :shiftValue="score.shift" :color="color" />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <v-row>
    <v-col cols="12">
      <div class="legend">
        <div class="legend-item">
          <div class="grey-ring">
            <div class="filled-ring"></div>
            <div class="empty-circle"></div>
          </div>
          <div class="context">Positive rating</div>
        </div>
        <div class="legend-item">
          <div class="grey-circle">
            <span class="negative">-</span> / <span class="positive">+</span>
          </div>
          <div class="context">Shift from previous wave</div>
        </div>
      </div>
    </v-col>
  </v-row>
</div>
