import Vue from 'vue';

const bus = new Vue();

export default {
  on(event, callback) {
    bus.$on(event, (e) => callback(e));
  },
  dispatch(event, data) {
    bus.$emit(event, data);
  },
};
