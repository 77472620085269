<template>
  <app-layout>
    <router-view :key="$route.fullPath" />

    <ScreenshotGenerator />
  </app-layout>
</template>

<script>
import AppLayout from '@/layouts/AppLayout.vue';
import ScreenshotGenerator from '@components/ScreenshotGenerator.vue';
import authService from '@/services/AuthService';

export default {
  name: 'App',

  components: {
    AppLayout,
    ScreenshotGenerator,
  },

  created() {
    authService.listenToAuthEvents();
  },
};
</script>
