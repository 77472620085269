import axios from 'axios';
import eventBus from '@/EventBus';
export const SESSION_TOKEN = 'session_token';
export const SESSION_REFRESH_TOKEN = 'session_refresh_token';

const REQUEST_HEADER = 'x-jwt-token';

axios.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem(SESSION_TOKEN);
    if (!token) return config;
    return {
      ...config,
      headers: {
        ...config.headers,
        [REQUEST_HEADER]: token,
      },
    };
  },
  (error) => Promise.reject(error),
);

axios.interceptors.response.use(
  (config) => {
    return config;
  },
  async (err) => {
    const originalConfig = err.config;
    if (originalConfig.url !== 'api/v2/auth/login' && err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await axios.post('api/v2/auth/refresh-token', {
            refreshToken: sessionStorage.getItem(SESSION_REFRESH_TOKEN),
          });
          const { token } = rs.data;
          sessionStorage.setItem(SESSION_TOKEN, token);
          return axios(originalConfig);
        } catch (error) {
          return Promise.reject(error);
        }
      }
    }
    return Promise.reject(err);
  },
);

class HttpRequestService {
  /**
   * @param {string} url
   * @param {Object} bodyParams
   * @param {Object} queryParams
   * @returns {Array | Object}
   */
  async get(url, bodyParams = {}, queryParams = {}, data = true) {
    return await this.tryCatch(async () => {
      const axiosConfig = {
        params: queryParams,
        data: bodyParams,
      };
      const objects = await axios.get(url, axiosConfig);
      return data ? objects : objects.data;
    });
  }

  /**
   * @param {string} url
   * @param {Object} bodyParams
   * @returns {Object}
   */
  async post(url, bodyParams = {}, data = true) {
    return await this.tryCatch(async () => {
      const axiosConfig = {
        data: bodyParams,
      };
      const objects = await axios.post(url, axiosConfig);
      return data ? objects : objects.data;
    });
  }

  /**
   * @param {string} url
   * @param {Object} bodyParams
   * @param {Object} queryParams
   * @returns {Object}
   */
  async put(url, bodyParams = {}, queryParams = {}, data = true) {
    return await this.tryCatch(async () => {
      const axiosConfig = {
        params: queryParams,
        data: bodyParams,
      };
      const objects = await axios.put(url, axiosConfig);
      return data ? objects : objects.data;
    });
  }

  /**
   * @param {string} url
   * @param {Object} bodyParams
   * @param {Object} queryParams
   * @returns {Array | Object}
   */
  async delete(url, bodyParams = {}, queryParams = {}, data = true) {
    return await this.tryCatch(async () => {
      const axiosConfig = {
        params: queryParams,
        data: bodyParams,
      };
      const objects = await axios.delete(url, axiosConfig);
      return data ? objects : objects.data;
    });
  }

  async tryCatch(callback) {
    try {
      return await callback();
    } catch (error) {
      if (error.response.status === 403) {
        eventBus.dispatch('logout');
      }
      return error.response.data;
    }
  }
}

export default new HttpRequestService();
