const awsconfig = {
  aws_project_region: import.meta.env.VITE_AWS_REGION,
  aws_cognito_region: import.meta.env.VITE_AWS_REGION,
  aws_user_pools_id: import.meta.env.VITE_USER_POOL_ID,
  aws_user_pools_web_client_id: import.meta.env.VITE_COGNITO_CLIENT_ID,
  oauth: {
    domain: import.meta.env.VITE_OAUTH_DOMAIN,
    scope: ['email', 'openid'],
    redirectSignIn: import.meta.env.VITE_OAUTH_SIGNIN_REDIRECT,
    redirectSignOut: import.meta.env.VITE_OAUTH_SIGNOUT_REDIRECT,
    responseType: 'code',
    options: {
      IdentityProvider: 'Cognito user pool directory',
    },
  },
};

export default awsconfig;
