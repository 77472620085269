<template>
  <v-row no-gutters>
    <v-col v-for="card in cards" class="card align-stretch d-flex" :key="card.asset">
      <v-card class="svpi-card" elevation="0">
        <v-card-title class="pb-0">
          <v-row no-gutters>
            <v-col cols="12" class="card-title py-0">
              {{ card.asset }}
              <v-divider class="my-2" />
            </v-col>
            <v-col cols="12" class="card-subtitle py-0">
              SV(P)I
              <v-divider class="my-2" />
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12" class="pb-4">
              <ActivityGauge class="activityGauge" :svi="card.svpi" :awareness="card.awareness" />
              <div v-if="card.svpi" class="text-center">
                <b
                  :class="{
                    'positive-gap-to-average': card.gapToAverage > 0,
                    'negative-gap-to-average': card.gapToAverage < 0,
                  }"
                  >{{ card.gapToAverage }}</b
                >
                p.p. gap-to-average
              </div>
            </v-col>
            <v-col v-if="card.pillars.length" cols="12" class="content-title">
              Performance across all pillars
              <v-divider class="my-2" />
            </v-col>
            <v-col cols="12" class="pb-4">
              <div class="pillars">
                <div class="pillar" v-for="(pillar, index) in card.pillars" :key="index">
                  <div class="icon material-icons-outlined">
                    {{ pillarsIcons[pillar.name] }}
                  </div>
                  <div class="copy">
                    <div>
                      {{ pillar.name }}
                    </div>
                    <div>
                      <b>{{ Math.round(pillar.score * 100) }}%</b>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col v-if="card.attributes.length" cols="12" class="content-title">
              Top 5 Drivers of Social Value
              <v-divider class="my-2" />
            </v-col>
            <v-col cols="12">
              <ul class="drivers">
                <li v-for="(driver, index) in card.attributes" :key="index">
                  {{ driver.attribute }}
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ActivityGauge from '@components/Charts/ActivityGauge.vue';

export default {
  components: {
    ActivityGauge,
  },

  props: {
    cards: {
      type: Array,
      default: () => [],
    },
    cols: {
      type: [String, Number],
      default: 4,
    },
  },

  data() {
    return {
      pillarsIcons: {
        Economic: 'work_outline',
        Corporate: 'corporate_fare',
        Community: 'people',
        Environmental: 'public',
      },
    };
  },
};
</script>
<style lang="scss" scoped>
@import '@styles/_colors.scss';
@import '@styles/_fonts.scss';

.card {
  max-width: 600px;

  .card-title {
    color: $bhp-blue-1;
    font-size: $bhp-title-font-size;
    line-height: $bhp-title-line-height;
  }

  .card-subtitle {
    font-weight: 100;
    font-size: $bhp-title-font-size;
    line-height: $bhp-title-line-height;
  }

  .activityGauge {
    margin-left: auto;
    margin-right: auto;
  }

  .content-title {
    font-size: $bhp-title-font-size;
    line-height: $bhp-title-line-height;
  }

  .pillars {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;

    .pillar {
      display: flex;
      align-items: center;
      gap: 8px;

      .icon {
        border-radius: 50%;
        background-color: $bhp-nav-blue-grey-2;
        color: $bhp-blue-1;
        padding: 5px;
      }
    }
  }

  .drivers {
    padding: 0 24px !important;

    li {
      list-style-type: decimal;
      font-size: $bhp-text-font-size;

      &::marker {
        font-weight: bold;
      }
    }
  }
}
</style>
