<template>
  <div v-if="datas">
    <v-row class="chart-row">
      <v-col cols="2"><span class="title-col">Pillar</span></v-col>
      <v-col cols="10" class="no-border">
        <v-row>
          <v-col cols="2" v-for="(category, index) in datas.categories" :key="index">
            <span class="category-label">{{ category }}</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="chart-row">
      <v-col cols="2"><span class="title-col">Lead Indicator</span></v-col>
      <v-col cols="10" class="no-border">
        <v-row>
          <v-col cols="2" v-for="(indicator, index) in datas.indicators" :key="index">{{
            indicator
          }}</v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-for="(section, sectionIndex) in datas.sections" :key="sectionIndex" class="chart-row">
      <v-col cols="2"
        ><span class="title-col">{{ section.name }}</span></v-col
      >
      <v-col cols="10" class="no-border">
        <v-row>
          <v-col cols="2" v-for="(score, scoreIndex) in section.scores" :key="scoreIndex">
            <SocialValueGauge :socialValue="score.score" :shiftValue="score.shift" :color="color" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="legend">
          <div class="legend-item">
            <div class="grey-ring">
              <div class="filled-ring"></div>
              <div class="empty-circle"></div>
            </div>
            <div class="context">Positive rating</div>
          </div>
          <div class="legend-item">
            <div class="grey-circle">
              <span class="negative">-</span> / <span class="positive">+</span>
            </div>
            <div class="context">Shift from previous wave</div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import theme from '@/theme';
import SocialValueGauge from './SocialValueGauge.vue';

export default {
  components: {
    SocialValueGauge,
  },

  props: {
    country: {
      type: String,
      default: null,
    },
    asset: {
      type: String,
      default: null,
    },
    datas: {
      type: Object,
      default: null,
    },
  },

  computed: {
    color() {
      return theme.bhpChartGreen;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/_colors.scss';

.category-label {
  font-weight: 500;
}

.chart-row {
  margin-left: 0;
  margin-right: 0;

  .col {
    border-bottom: 1px solid $bhp-functional-grey-1;
  }

  .no-border {
    border: none;
  }
}

.title-col {
  display: flex;
  align-items: center;
  height: 100%;
  font-weight: 500;
}

.legend {
  justify-content: flex-end;
  display: flex;

  .legend-item {
    align-items: center;
    gap: 8px;
    display: flex;
    margin-left: 32px;
  }
}

.grey-ring {
  background-color: #eee;
  border-radius: 16px;
  height: 32px;
  position: relative;
  overflow: hidden;
  width: 32px;

  .filled-ring {
    background-color: #888;
    height: 32px;
    left: 16px;
    position: absolute;
    width: 32px;
  }

  .empty-circle {
    background-color: #fff;
    border-radius: 10px;
    height: 20px;
    left: 6px;
    position: absolute;
    top: 6px;
    width: 20px;
  }
}

.grey-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border: 2px solid #eee;
  color: #eee;
  border-radius: 100%;

  .negative {
    color: $bhp-negative-red;
  }

  .positive {
    color: $bhp-positive-green;
  }
}
</style>
