
<div class="answer-column-title">
  <flag
    v-if="columnTitleProperty === 'country'"
    :iso="answer.iso"
    size="40"
    :title="answer.name"
  />
  <span v-else-if="columnTitleProperty !== 'logo' || !getCompanyImage(answer.logo)">{{
    answer[columnTitleProperty]
  }}</span>
  <v-img
    v-else-if="columnTitleProperty === 'logo' && getCompanyImage(answer.logo)"
    :src="leaderImage(answer.logo)"
    :aspect-ratio="16 / 9"
    :title="answer.name"
    width="64"
    contain
  />
</div>
