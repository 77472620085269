export default {
  bhpOrange1: '#E65E00',
  bhpBlue1: '#234483',
  bhpBlue2: '#1966B1',
  bhpBlue3: '#72a3d4',
  bhpWhite: '#FFFFFF',

  bhpPositiveGreen: '#53818a',
  bhpNegativeRed: '#B14E4D',

  bhpVeryPositiveGreen: '#53818a',
  bhpLeaningPositiveGreen: '#73a8a5',
  bhpNeutralGrey: '#B0B0B0',
  bhpLeaningNegativeRed: '#b07e7d',
  bhpVeryNegativeRed: '#B14E4D',

  bhpChartOrange: '#E65E00',
  bhpChartGreen: '#01AB8B',
  bhpChartYellow: '#FAB636',
  bhpChartPurple: '#D04D97',
  bhpChartBlue: '#2DA3D7',
  bhpChartTurquoise: '#4FDBE7',
  bhpChartLightGreen: '#B3DE68',
  bhpChartDeepPurple: '#742068',
  bhpChartMediumPurple: '#9F2996',
  bhpChartBlueGrey: '#5C889C',

  bhpNavBlueGrey1: '#F7F8FA',
  bhpNavBlueGrey2: '#E6EBF1',
  bhpNavBlueGrey3: '#90A1C0',
  bhpNavBlueGrey4: '#596C90',
  bhpNavBlueGrey5: '#dde3ef',

  bhpFunctionalGrey1: '#E5E5E5',
  bhpFunctionalGrey2: '#F8F8F8',
  bhpFunctionalGrey3: '#B0B0B0',

  bhpTextGrey: '#5D6064',
  bhpMapYellow: '#FCE4B7',

  bhpCoporate: '#F1AA7C',
  bhpCommunity: '#F1E08C',
  bhpEnvironmental: '#B7D38B',
  bphEconomic: '#97B0BE',

  bhpFunctionalTurquoise: '#92C5C3',
  bhpFunctionalDeepTurquoise: '#018982',
};
