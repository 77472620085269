import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import { getCurrentUser } from 'aws-amplify/auth';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/GlobalInsights.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'no',
    },
  },
  {
    path: '/auth/callback',
    name: 'AuthCallback',
    component: () => import('@/views/AuthCallback.vue'),
    meta: {
      layout: 'no',
    },
  },
  {
    path: '/digital-intelligence',
    name: 'Digital Intelligence',
    component: () => import('@/views/DigitalIntelligence.vue'),
  },
  {
    path: '/report-library',
    name: 'Report Library',
    component: () => import('@/views/ReportLibrary.vue'),
  },
  {
    path: '/about-data',
    name: 'Behind the numbers',
    component: () => import('@/views/AboutData.vue'),
  },
  {
    path: '/report-builder',
    name: 'Report Builder',
    component: () => import('@/views/ReportBuilder.vue'),
  },
  {
    path: '/:country',
    name: 'country',
    meta: {
      title: ':country - :asset',
    },
    component: () => import('@/views/Country.vue'),
  },
  {
    path: '/:country/:asset',
    name: 'country-asset',
    meta: {
      title: ':country - :asset',
    },
    component: () => import('@/views/Country.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: import.meta.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { y: 0, x: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const isLoginRoute = to.path === '/login';
  const isCallbackRoute = to.path === '/auth/callback';

  let isAuthenticated = false;
  await getCurrentUser()
    .then(() => (isAuthenticated = true))
    .catch(() => (isAuthenticated = false));

  if (!isAuthenticated) {
    if (isLoginRoute) {
      next();
    } else if (isCallbackRoute) {
      return;
    } else {
      next('/login');
    }
  } else {
    // If logged in, then dispatch
    if (!store.state.selects.loaded) {
      store.dispatch('selects/loadSelects');
    }
    store.dispatch('assets/setCountrySelected', to.params?.country?.replaceAll('_', ' ') || null);
    store.dispatch('assets/setAssetSelected', to.params?.asset?.replaceAll('_', ' ') || null);

    // If logged in, then redirect from /login to /
    if ((isLoginRoute || isCallbackRoute) && isAuthenticated) {
      next('/');
    } else {
      next();
    }
  }
});

export default router;
