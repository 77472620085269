import Vue from 'vue';
import Vuex from 'vuex';

import selects from './selects';
import user from './user';
import assets from './assets';
import reportbuilder from './reportbuilder';
import digitalIntelPeriod from './digitalIntelPeriod';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    selects,
    user,
    assets,
    reportbuilder,
    digitalIntelPeriod,
  },
});
