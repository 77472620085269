import SimpleSelect from '@components/Selects/SimpleSelect.vue';
import MultiSelect from '@components/Selects/MultiSelect.vue';
import TabbedMultiSelect from '@components/Selects/TabbedMultiSelect.vue';
import DateRangeSelect from '@components/Selects/DateRangeSelect.vue';
import DateRangeAndBenchmarkSelect from '@components/Selects/DateRangeAndBenchmarkSelect.vue';
import DateRangeAndBenchmarkByQuarterSelect from '@components/Selects/DateRangeAndBenchmarkByQuarterSelect.vue';
import DateRangeByPeriodSelect from '@components/Selects/DateRangeByPeriodSelect.vue';
import Panel from '@components/Panel.vue';
import Flag from '@components/Flag.vue';

export default {
  install: (app) => {
    app.component('simple-select', SimpleSelect);
    app.component('multi-select', MultiSelect);
    app.component('tabbed-multi-select', TabbedMultiSelect);
    app.component('date-range-select', DateRangeSelect);
    app.component('date-range-and-benchmark-select', DateRangeAndBenchmarkSelect);
    app.component('date-range-and-benchmark-by-quarter-select', DateRangeAndBenchmarkByQuarterSelect);
    app.component('date-range-by-period-select', DateRangeByPeriodSelect);
    app.component('panel', Panel);
    app.component('flag', Flag);
  },
};
