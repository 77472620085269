
<v-list class="pa-0 menu-list">
  <v-list-item class="menu-title">
    <v-list-item-content class="pa-0">
      <v-list-item-title>
        <v-img :src="logo" contain width="155" max-height="32" class="logo" />
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
  <v-list-item to="/" :ripple="false" :class="isActive('/')">
    <v-list-item-icon>
      <span class="material-icons-outlined"> home </span>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>Home</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
  <v-subheader class="sub-header text-uppercase"> reporting </v-subheader>
  <v-list-item to="/report-builder" :ripple="false" :class="isActive('/report-builder')">
    <v-list-item-icon>
      <span class="material-icons-outlined"> layers </span>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>
        Report Builder
        <v-avatar color="primary" size="20" v-if="addOnBuilderPagesCount">
          {{ addOnBuilderPagesCount }}
        </v-avatar>
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
  <v-subheader class="sub-header text-uppercase"> dashboard </v-subheader>
  <v-list-item
    v-for="({ key, name }, index) in countries"
    :key="`list-item-${index}`"
    :to="`/${name.replaceAll(' ', '_')}`"
    :ripple="false"
    class="country d-flex align-center"
    :class="isActive(`/${name.replaceAll(' ', '_')}`)"
  >
    <v-list-item-icon class="align-self-center mr-1">
      <flag :iso="key" size="18" />
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>{{ name }}</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
  <v-subheader class="sub-header text-uppercase"> live data </v-subheader>
  <!-- <v-list-item
    to="/digital-intelligence"
    :ripple="false"
    :class="isActive('/digital-intelligence')"
  >
    <v-list-item-icon>
      <span class="material-icons-outlined"> preview </span>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>Digital Intelligence</v-list-item-title>
    </v-list-item-content>
  </v-list-item> -->
  <v-subheader class="sub-header text-uppercase"> more </v-subheader>
  <v-list-item
    class="more-link mb-2"
    :class="isActive('/report-library')"
    to="/report-library"
    :ripple="false"
  >
    <v-list-item-icon>
      <span class="material-icons-outlined"> folder </span>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>Report Library</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
  <v-list-item
    class="more-link"
    :class="isActive('/about-data')"
    to="/about-data"
    :ripple="false"
  >
    <v-list-item-icon>
      <span class="material-icons-outlined"> info </span>
    </v-list-item-icon>
    <v-list-item-content>
      <v-list-item-title>About Data</v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</v-list>
