<template>
  <v-select
    :label="label"
    class="bhp-select"
    :class="`select-width-${width}`"
    v-model="model"
    outlined
    dense
    append-icon="mdi-chevron-down"
    :items="items"
    hide-details
    :menu-props="{ offsetY: true, contentClass: 'bhp-select-list' }"
    :item-text="itemText"
    :item-value="itemValue"
  />
</template>

<script>
export default {
  name: 'simple-select',
  props: {
    value: {
      type: [String, Number, Object, Array],
      default: null,
    },
    callback: {
      type: Function,
    },
    label: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => ([]),
    },
    width: {
      type: [Number, String],
      default: 100,
    },
    itemText: {
      type: String,
      default: 'text',
    },
    itemValue: {
      type: String,
      default: 'value',
    },
    itemDisabled: {
      type: [Function, String, Array],
      default: 'disabled',
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
        this.$emit('select', value);
      },
    },
  },
};
</script>
