// Maps the default time period for each location, to ensure data is shown initially, when available
export const DEFAULT_TIME_PERIODS_BY_LOCATION = {
  /* Chile: {
    local: '2020',
  }, */
};

/**
 * Returns the default time period for the given location
 * @param {Object} location Object with 'country' and 'asset' properties
 * @returns {String} Default time period for the given location
 */
export function getDefaultTimePeriod(location) {
  let category = 'national';
  if (location.asset) category = 'local';
  return DEFAULT_TIME_PERIODS_BY_LOCATION[location.country]?.[category] ?? null;
}
