/**
 * Converts combined string of quarter and year to an array with quarter and year
 * @param {String} str String in the format 'Mar 2020'
 * @returns Array of two elements: [quarter, year] where quarter is a string in the format 'March Quarter'
 */
export const strToQuarterAndYear = (str) => {
  const [quarter, year] = str.split(' ');
  return { quarter: quarterLabelFromShortMonth(quarter), year: year.trim() };
};

/**
 * Converts quarter and year to a string to the format 'Mar 2020'
 * @param {String} quarter String quarter in the format 'March Quarter'
 * @param {Number} year Number year
 * @returns String in the format 'Mar 2020'
 */
export const quarterAndYearFormat = (quarter, year) => {
  return `${shortMonthFromQuarterLabel(quarter)} ${year}`;
};

/**
 * Converts quarter label as it appears in the DB to a short month
 * @param {String} quarterLabel String quarter in the format 'March Quarter'
 * @returns String in the format 'Mar'
 */
export function shortMonthFromQuarterLabel(quarterLabel) {
  if (quarterLabel === 'March Quarter') return 'Mar';
  else if (quarterLabel === 'June Quarter') return 'Jun';
  else if (quarterLabel === 'September Quarter') return 'Sep';
  else if (quarterLabel === 'December Quarter') return 'Dec';
  else return quarterLabel;
}

/**
 * Converts short month to a quarter label as it appears in the DB
 * @param {String} shortMonth String in the format 'Mar'
 * @returns String in the format 'March Quarter'
 */
export function quarterLabelFromShortMonth(shortMonth) {
  if (shortMonth === 'Mar') return 'March Quarter';
  else if (shortMonth === 'Jun') return 'June Quarter';
  else if (shortMonth === 'Sep') return 'September Quarter';
  else if (shortMonth === 'Dec') return 'December Quarter';
  else return shortMonth;
}
