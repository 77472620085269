import httpRequestService from '@/services/HttpRequestService';
import eventBus from '@/EventBus';
import { quarterAndYearFormat } from '@/utils/quarters';
import { AUSTRALIA } from '@/utils/countries';

const state = () => ({
  timePeriods: [],
  sentiments: [],
  dateRanges: [],
  demographics: [],
  audiences: [],
  keyAttributs: [],
  quarterPeriods: [],
  assetsAndCommunitiesByCountries: [],
  topics: [],
  loaded: false,
  countrySelected: AUSTRALIA,
  assetSelected: 'NSWEC',
});

const getters = {
  timePeriods: (state) => state.timePeriods,
  sentiments: (state) => state.sentiments,
  dateRanges: (state) => state.dateRanges,
  demographics: (state) => state.demographics,
  audiences: (state) => state.audiences,
  keyAttributs: (state) => state.keyAttributs,
  quarterPeriods: (state) => state.quarterPeriods,
  timePeriodsByQuarter: (state) =>
    state.quarterPeriods.map((date) => quarterAndYearFormat(date.label, date.value)),
  topics: (state) => state.topics,
  assetsAndCommunitiesByCountries: (state) => state.assetsAndCommunitiesByCountries,
  countries: (state) => state.assetsAndCommunitiesByCountries.map(({ country }) => country),
  assets: (state) =>
    state.assetsAndCommunitiesByCountries
      .find(({ country }) => country === state.countrySelected)
      ?.assets.map((asset) => asset.asset_short) || [],
  loaded: (state) => state.loaded,
  countrySelected: (state) => state.countrySelected,
  defaultTimeperiod: (state) => state.timePeriods[state.timePeriods.length - 1] || null,
  defaultSentiment: (state) =>
    state.sentiments.find((sentiment) => sentiment === 'Positive') || null,
  defaultDateRangesTo: (state) => state.dateRanges[state.dateRanges.length - 1] || null,
  defaultDateRangesFrom: (state) => state.dateRanges[0] || null,
  defaultDateRangesBenchmark: (state) => state.dateRanges[0] || null,
  defaultDemographic: (state) => (context) =>
    state.demographics.reduce((acc, curr) => {
      const { country } = context;
      if (curr.country === country || !curr.country) {
        acc[curr.type] = [];
      }
      return acc;
    }, {}) || null,
  defaultAudience: (state) => state.audiences[0] || null,
  defaultKeyAttribut: (state) => state.keyAttributs[5] || null,
  defaultQuarterPeriodsTo: (state) => state.quarterPeriods[state.quarterPeriods.length - 1] || null,
  defaultQuarterPeriodsFrom: (state) => state.quarterPeriods[0] || null,
  defaultQuarterPeriodsBenchmark: (state) => state.quarterPeriods[0] || null,
  defaultCountry: (state) =>
    state.assetsAndCommunitiesByCountries
      .map(({ country }) => country)
      .find((country) => country === AUSTRALIA),
  defaultCountries: (state) => [
    ...state.assetsAndCommunitiesByCountries.map(({ country }) => country),
  ],
  defaultCountriesAll: (state) => [
    ...state.assetsAndCommunitiesByCountries.map(({ country }) => country),
  ],
  defaultTimePeriodByQuarter: (state) =>
    state.quarterPeriods.map((date) => quarterAndYearFormat(date.label, date.value))[
      state.quarterPeriods.length - 1
    ] || null,
  defaultSelectValues: (_, getters) => (context) => ({
    defaultTimeperiod: getters.defaultTimeperiod,
    defaultSentiment: getters.defaultSentiment,
    defaultDateRangesTo: getters.defaultDateRangesTo,
    defaultDateRangesFrom: getters.defaultDateRangesFrom,
    defaultDateRangesBenchmark: getters.defaultDateRangesBenchmark,
    defaultDemographic: getters.defaultDemographic(context),
    defaultAudience: getters.defaultAudience,
    defaultKeyAttribut: getters.defaultKeyAttribut,
    defaultQuarterPeriodsTo: getters.defaultQuarterPeriodsTo,
    defaultQuarterPeriodsFrom: getters.defaultQuarterPeriodsFrom,
    defaultQuarterPeriodsBenchmark: getters.defaultQuarterPeriodsBenchmark,
    defaultCountry: getters.defaultCountry,
    defaultCountries: getters.defaultCountries,
    defaultCountriesAll: getters.defaultCountriesAll,
    defaultTimePeriodByQuarter: getters.defaultTimePeriodByQuarter,
  }),
};

const actions = {
  async loadSelects({ commit }) {
    try {
      const selects = await httpRequestService.get('/api/v2/selects', {}, {}, false);
      commit('setSelects', selects);
      eventBus.dispatch('selectLoaded');
    } catch (err) {
      console.error('Error retrieving selects data.'); // eslint-disable-line
      console.error(err); // eslint-disable-line
    }
  },
  setCountrySelected({ commit }, countrySelected) {
    commit('setCountrySelected', countrySelected);
  },
};

const mutations = {
  setSelects(state, data) {
    state.audiences = data.audiences;
    state.demographics = data.demographics;
    state.timePeriods = data.timePeriods;
    state.sentiments = data.sentiments;
    state.keyAttributs = data.keyAttributes;
    state.dateRanges = data.timePeriods;
    state.quarterPeriods = data.quarterPeriods;
    state.assetsAndCommunitiesByCountries = data.assetsAndCommunitiesByCountries;
    state.topics = data.topics;
    state.loaded = true;
  },
  setCountrySelected(state, countrySelected) {
    state.countrySelected = countrySelected;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
