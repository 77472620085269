/* ------------------------ Format rows for CSV file ------------------------ */
const rowsToCsv = (rows) => {
  if (!rows || !rows.length) return 'No Data Available';
  const header = Object.keys(rows[0]);
  const headerString = header.join(',');
  const replacer = (key, value) => value ?? '';
  const rowItems = rows.map((row) =>
    header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','),
  );
  return [headerString, ...rowItems].join('\r\n');
};

export default {
  methods: {
    /* ----------------- Create CSV for multiple combined charts ---------------- */
    downloadMultipleCharts(fileName, charts) {
      let chartStr = '\r\n';
      charts.forEach((chart) => {
        const { title, rows } = chart;
        if (!!title && !!rows && rows.length) {
          chartStr += `${title.toUpperCase()}\r\n${rowsToCsv(rows)}\r\n\n\n`;
        }
      });
      this.createDownloadFileUrl(
        `${fileName}.csv`,
        `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURI(chartStr)}`,
      );
    },

    /* ---------------------- Create CSV for a single chart --------------------- */
    downloadCharts(title, rows) {
      this.createDownloadFileUrl(
        `${title}.csv`,
        `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURI(rowsToCsv(rows))}`,
      );
    },

    /* ----------------------- Create a file download link ---------------------- */
    createDownloadFileUrl(fileName, href) {
      const link = document.createElement('a');
      link.href = href;
      link.download = fileName;
      link.click();
    },
  },
};
