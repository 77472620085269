<template>
  <highcharts class="chart" :options="chart" :key="JSON.stringify(data)"></highcharts>
</template>

<script>
import theme from '@/theme';

export default {
  name: 'ReputationDriverPerformanceOvertimeChart',

  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    chart() {
      return {
        chart: {
          type: 'areaspline',
          height: 80,
          marginBottom: 15,
          marginTop: 0,
          backgroundColor: 'transparent',
        },
        tooltip: {
          enabled: false,
          crosshairs: false,
        },
        title: {
          visible: false,
          text: '',
        },
        yAxis: {
          visible: false,
        },
        xAxis: {
          visible: true,
          tickLength: 0,
          labels: {
            useHTML: true,
            style: {
              fontSize: '9px',
            },
            formatter: ({ value }) => {
              if (!this.data[value]) return '';
              return `${this.data[value]['year']}`;
            },
          },
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          series: {
            connectNulls: true,
            dataLabels: {
              enabled: false,
              formatter: function () {
                return `${this.y}%`;
              },
            },
            marker: {
              fillColor: theme.bhpNavBlueGrey3,
              lineWidth: 1,
              lineColor: theme.bhpNavBlueGrey3,
            },
            point: {
              events: {
                mouseOver: function () {
                  this.series.chart.update({
                    chart: { className: 'hover', height: 200 },
                    plotOptions: { series: { dataLabels: { enabled: true } } },
                  });
                },
                mouseOut: function () {
                  this.series.chart.update({
                    chart: { className: '', height: 80 },
                    plotOptions: { series: { dataLabels: { enabled: false } } },
                  });
                },
              },
            },
          },
        },
        legend: {
          enabled: false,
        },
        series: [
          {
            color: theme.bhpNavBlueGrey5,
            data: this.data.map(({ value }) => {
              return this.toPercent(value);
            }),
          },
        ],
      };
    },
  },
};
</script>

<style scoped lang="scss">
.chart::v-deep .highcharts-container .highcharts-xaxis-labels span {
  margin-top: -5px !important;
  opacity: 0 !important;
}

.chart::v-deep .highcharts-container.hover .highcharts-xaxis-labels span {
  opacity: 1 !important;
}
</style>
