<template>
  <div
    class="corp-rep-chart d-flex justify-space-between overflow-x-auto overflow-y-hidden"
    v-if="items.type"
  >
    <div
      class="theme-container d-flex flex-column-reverse align-center px-4"
      :class="{
        'align-self-end': !spreadData,
      }"
    >
      <div
        v-for="theme in REVERSE_THEMES"
        :key="theme.name"
        class="mt-1 py-1 font-weight-medium text-uppercase white--text text-center"
        :style="{ 'background-color': theme.color, width: theme.width }"
      >
        {{ theme.name }}
      </div>
    </div>

    <div
      class="answers-container d-flex align-self-end"
      :class="{
        'flex-wrap': spreadData,
      }"
    >
      <div
        v-if="(items.type === 'national' || items.type === 'asset') && items.benchmark"
        class="d-flex flex-column align-self-end align-center px-4 font-weight-medium answer-column"
        :class="{
          'flex-grow-1 flex-shrink-1': !spreadData,
          'ma-2': spreadData,
        }"
      >
        <span class="d-flex flex-column align-center answer-column-title">
          <span v-if="columnTitleProperty !== 'logo'">
            {{ items.benchmark.name }}
          </span>
          <v-img
            v-else
            :src="`/images/companies/${items.benchmark.logo}.svg`"
            :aspect-ratio="16 / 9"
            :width="48"
            :title="items.benchmark.name"
            contain
          />
          <small class="benchmark" v-if="columnTitleProperty === 'name'"> (Benchmark) </small>
        </span>
        <template v-if="items.benchmark?.data.length">
          <template v-for="({ name, color }, index) in THEMES">
            <div
              v-if="typeof getValue(items.benchmark.data, name) !== 'undefined'"
              :key="index"
              :data-value="`${getValue(items.benchmark.data, name)}%`"
              :class="[index > THEMES.length / 2 ? 'black--text' : 'white--text']"
              class="mt-1 py-1 font-weight-medium text-uppercase text-center"
              :style="{
                'background-color': color,
                width: `${getValue(items.benchmark.data, name)}%`,
              }"
            ></div>
            <div v-else :key="name" class="mt-1 py-1">
              <InsufficientDataTooltip />
            </div>
          </template>
        </template>
        <template v-else>
          <div style="height: 270px; margin-top: 36px">
            <InsufficientDataTooltip />
          </div>
        </template>
      </div>
      <template v-if="items.items.length">
        <div
          v-for="(answer, index) in items.items"
          :key="answer.label || index"
          :class="{
            'd-none': !answer[columnTitleProperty],
            'd-flex': answer[columnTitleProperty],
            'flex-grow-1 flex-shrink-1': !spreadData,
            'ma-2': spreadData,
          }"
          class="flex-column align-self-end align-center px-4 font-weight-medium answer-column"
        >
          <CorporateReputationChartCompanyHeader
            :answer="answer"
            :columnTitleProperty="columnTitleProperty"
          />

          <template v-for="({ name, color }, index) in THEMES">
            <div
              v-if="typeof getValue(answer.data, name) !== 'undefined'"
              :key="index"
              :data-value="`${getValue(answer.data, name)}%`"
              :class="[index > THEMES.length / 2 ? 'black--text' : 'white--text']"
              class="mt-1 py-1 font-weight-medium text-uppercase text-center"
              :style="{ 'background-color': color, width: `${getValue(answer.data, name)}%` }"
            ></div>

            <div v-else :key="name" class="mt-1 py-1">
              <InsufficientDataTooltip />
            </div>
          </template>
        </div>
      </template>
      <template v-else>
        <div
          class="d-flex flex-column align-self-end align-center px-4 font-weight-medium answer-column"
          :class="{
            'flex-grow-1 flex-shrink-1': !spreadData,
            'ma-2': spreadData,
          }"
        >
          <span
            class="d-flex flex-column align-center mb-2 text-center justify-start answer-column-title"
          >
            <span> Insufficient data available </span>
          </span>
          <div style="height: 270px; margin-top: 36px">
            <InsufficientDataTooltip />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import InsufficientDataTooltip from '@/components/Tooltips/InsufficientDataTooltip.vue';
import CorporateReputationChartCompanyHeader from './CorporateReputationChartCompanyHeader.vue';

const THEMES = [
  { name: 'Advocacy', color: 'rgb(48, 48, 48)', width: '80%' },
  { name: 'Trust', color: 'rgb(162, 199, 84)', width: '85%' },
  { name: 'Favourability', color: 'rgb(66, 151, 152)', width: '90%' },
  { name: 'Familiarity', color: 'rgb(122, 195, 220)', width: '95%' },
  { name: 'Awareness', color: 'rgb(104, 103, 168)', width: '100%' },
  { name: 'Unfamiliarity', color: 'rgba(122, 195, 220, 0.5)', width: '95%' },
  { name: 'Unfavourability', color: 'rgba(66, 151, 152, 0.5)', width: '90%' },
  { name: 'Distrust', color: 'rgba(162, 199, 84, 0.5)', width: '85%' },
  { name: 'Criticism', color: 'rgba(48, 48, 48, 0.5)', width: '80%' },
];

export default {
  name: 'CorporateReputationChart',

  components: { InsufficientDataTooltip, CorporateReputationChartCompanyHeader },

  props: {
    reputation: {
      type: String,
    },
    asset: {
      type: String,
      default: null,
    },
    country: {
      type: String,
      default: null,
    },
    items: {
      type: Object,
      default: () => ({
        benchmark: null,
        items: [],
        type: null,
      }),
    },
    spreadData: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      THEMES,
    };
  },
  computed: {
    columnTitleProperty: function () {
      if (this.isGlobalInsight) {
        return 'country';
      }
      if (this.isAllHostCommunitiesInsight) {
        return 'asset';
      }
      if (this.isNationalInsight) {
        if (['Against local peers', 'Against peers'].includes(this.reputation)) {
          return 'logo';
        } else {
          return 'name';
        }
      }
      if (this.isAssetInsight && this.reputation === 'By Community') {
        return 'community';
      }
      if (['Against local peers', 'Against peers'].includes(this.reputation)) {
        return 'logo';
      }
      return 'town';
    },
    REVERSE_THEMES() {
      return [...THEMES].reverse();
    },
  },
  methods: {
    getValue(datas, theme) {
      const data = datas.find((d) => theme === d.label);
      return data?.value;
    },
  },
};
</script>

<style lang="scss">
// Hide chart until loaded (is-loading class added in Panel.vue)
.is-loading .corp-rep-chart {
  opacity: 0;
}
</style>

<style scoped lang="scss">
@import '@styles/_colors.scss';

.theme-container {
  position: absolute;
  padding-top: 65px;
  background-color: $bhp-white;
  z-index: 1;
  align-content: flex-end;
}

@media (min-width: 992px) {
  .theme-container {
    width: 25%;
  }
  .answers-container {
    width: 75%;
    margin-left: 25%;
  }
}

@media (min-width: 1600px) {
  .theme-container {
    width: 15%;
  }
  .answers-container {
    width: 85%;
    margin-left: 15%;
  }
}

.answer-column-title {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: 70px;
  height: 64px !important;
}

.benchmark {
  font-size: 0.7em;
}

.theme-container > div {
  white-space: nowrap;
}

.answer-column {
  max-width: 400px;
  flex-basis: 0;
  border-right: 2px dashed $bhp-functional-grey-1;

  &:nth-of-type(1) {
    border-left: 2px dashed $bhp-functional-grey-1;
  }

  > div {
    height: 30px;
    position: relative;

    &::after {
      content: attr(data-value);
      position: absolute;
      transform: translateX(-50%);
    }

    &.white--text {
      text-shadow: 1px 1px 1px #000;
    }
  }
}
</style>
