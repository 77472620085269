<template>
  <div :class="[showBorder ? 'ml-4 pl-4' : '', 'actions']">
    <v-tooltip
      v-if="!reportDisabled"
      bottom
      color="white"
      content-class="action-tooltip"
      nudge-top="6"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="isGeneratingReport || showNoData"
          outlined
          tile
          icon
          v-bind="attrs"
          v-on="on"
          class="action-btn mr-4"
          @click="addToReport"
        >
          <v-icon> create_new_folder </v-icon>
        </v-btn>
      </template>
      <span>Add to report</span>
    </v-tooltip>
    <v-tooltip
      v-if="!downloadDisabled"
      bottom
      color="white"
      content-class="action-tooltip"
      nudge-top="6"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :disabled="showNoData"
          outlined
          tile
          icon
          v-bind="attrs"
          v-on="on"
          class="action-btn mr-4"
          @click="downloadChart"
        >
          <span class="material-icons-outlined"> file_download </span>
        </v-btn>
      </template>
      <span class="text--black">Download chart</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'Actions',

  props: {
    reportDisabled: {
      type: Boolean,
      default: true,
    },
    isGeneratingReport: {
      type: Boolean,
      default: false,
    },
    showNoData: {
      type: Boolean,
      default: false,
    },
    downloadDisabled: {
      type: Boolean,
      default: false,
    },
    showBorder: {
      type: Boolean,
      default: true,
    },
    addToReport: {
      type: Function,
      default: () => {},
    },
    downloadChart: {
      type: Function,
      default: () => {},
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@styles/_colors.scss';

.actions {
  &.with-border {
    border-left: 1px solid $bhp-nav-blue-grey-3;
  }

  .action-btn {
    width: 40px;
    height: 40px;
    color: $bhp-blue-1;
    border-color: $bhp-blue-1;

    &.report-actived {
      color: white;
      background-color: $bhp-blue-1;
    }
  }
}

.action-tooltip {
  color: #000000;
  background-color: $bhp-functional-grey-1;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%),
    0px 3px 14px 2px rgb(0 0 0 / 12%);
}
</style>
