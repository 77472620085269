import { AUSTRALIA, isAudienceFilterHidden } from '@/utils/countries';
import { getDefaultTimePeriod } from '@/utils/defaultTimePeriods';
import Insights from '@/utils/insights';
import { FILTERS_TYPES } from '@/utils/panel-filters';
import { strToQuarterAndYear } from '@/utils/quarters';
import { CARDS_TYPES } from '../CardDataService';
import { CardBaseService } from './CardBaseService';

export default class SVPScoreboardService extends CardBaseService {
  static getCardType() {
    return CARDS_TYPES.SVP_SCORECARD;
  }

  static getChartTitle() {
    return 'Social Value Scorecard Performance';
  }

  static getChartComponentName() {
    return 'SocialValueChart';
  }

  static getFilters(defaultFilters = {}, location = { country: null, asset: null }) {
    const { defaultDemographic, defaultAudience, defaultTimeperiod, defaultTimePeriodByQuarter } =
      defaultFilters;

    return {
      demographic: defaultDemographic ?? null,
      audience: defaultAudience ?? null,
      year: getDefaultTimePeriod(location) ?? defaultTimeperiod ?? null,
      quarter: getDefaultTimePeriod(location) ?? defaultTimePeriodByQuarter ?? null,
      community: this.getDefaultCommunity(location.asset, location.country),
    };
  }

  static getFiltersConfig(model, { country, asset }) {
    const INSIGHT = Insights.get(country, asset);

    let timePeriod = { type: FILTERS_TYPES.TIME_PERIOD, model, key: 'year' };
    // For Australia national view, we use quarter periods
    if (INSIGHT === Insights.NATIONAL && country === AUSTRALIA) {
      timePeriod = { type: FILTERS_TYPES.TIME_PERIOD_BY_QUARTER, model, key: 'quarter' };
    }

    return [
      {
        type: FILTERS_TYPES.DEMOGRAPHIC,
        model,
        key: 'demographic',
        country,
        isLocal: !!asset,
        isHidden: INSIGHT === Insights.GLOBAL,
      },
      {
        type: FILTERS_TYPES.AUDIENCE,
        model,
        key: 'audience',
        isHidden: isAudienceFilterHidden(country, asset),
      },
      {
        type: FILTERS_TYPES.COMMUNITY,
        model,
        key: 'community',
        isHidden: INSIGHT !== Insights.ASSET,
        items: this.getCommunities(asset, country),
      },
      timePeriod,
    ];
  }

  static getReportPageConfig({ location = {}, filters, data }) {
    return {
      type: this.getCardType(),
      title: this.getChartTitle(),
      filters: {
        ...filters,
        country: location.country,
        asset: location.asset,
      },
      data: {
        datas: data,
      },
      rows: this.getRows(data, filters, location.country, location.asset),
    };
  }

  // Formats rows for CSV report
  static getRows(datas, filters, country, asset) {
    if (!datas) return [];

    const rows = [];
    for (let i = 0; i < datas.categories.length; i++) {
      const row = {
        Pillar: datas.categories[i],
        'Lead Indicator': datas.indicators[i],
      };
      for (let j = 0; j < datas.sections.length; j++) {
        row[`${datas.sections[j].name} - Value`] = `${datas.sections[j].scores[i].score}%`;
        row[`${datas.sections[j].name} - Shift`] = datas.sections[j].scores[i].shift;
      }
      rows.push(row);
    }

    // Add description of filters to last row
    if (rows.length) {
      this.addFiltersToRows(rows, filters, country, asset);
    }
    return rows;
  }

  static humanizeFilters({ country, asset, community, year, quarter, audience, demographic }) {
    const INSIGHT = Insights.get(country, asset);
    const { age, gender, shareholder, votingPref } = demographic;

    if (INSIGHT === Insights.NATIONAL) {
      return {
        Country: country,
        Audience: audience,
        Year: year,
        Quarter: quarter,
        Age: age,
        Gender: gender,
        Shareholder: shareholder,
        'Voting Preference': votingPref,
      };
    }

    return {
      'Host Community': asset && country ? `${asset} (${country})` : null,
      Community: community,
      Audience: audience,
      Year: year,
      Quarter: quarter,
      Age: age,
      Gender: gender,
    };
  }

  static async loadData({ filters, location }) {
    const INSIGHT = Insights.get(location.country, location.asset);

    const params = {
      audience: filters.audience,
      country: location.country,
      asset: location.asset,
      year: filters.year,
      community: filters.community,
      ...filters.demographic,
    };

    // Set audience for host communities
    if (INSIGHT === Insights.ALL_HOST_COMMUNITIES || INSIGHT === Insights.ASSET) {
      params.audience = 'Host Community';
    }

    // If Australia national view, we use quarter periods
    if (INSIGHT === Insights.NATIONAL && location.country === AUSTRALIA) {
      const { quarter, year } = strToQuarterAndYear(filters.quarter);
      params.year = year;
      params.quarter = quarter;
    }

    const svpScorecardEndPoint = '/api/v2/svp-scorecard';
    const result = await this.httpRequestService.get(svpScorecardEndPoint, null, params, false);
    return result;
  }
}
