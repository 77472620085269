<template>
  <highcharts
    v-if="datas.assetsAndCommunitiesByCountries"
    constructor-type="mapChart"
    :options="chart"
    :key="refreshMap"
  />
</template>

<script>
import theme from '@/theme';
import { getCodeFromCountries } from '@utils/countries';

const PIN_POINT =
  'url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB3aWR0aD0iMzk1LjcxcHgiIGhlaWdodD0iMzk1LjcxcHgiIHZpZXdCb3g9IjAgMCAzOTUuNzEgMzk1LjcxIiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCAzOTUuNzEgMzk1LjcxOyINCgkgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgZmlsbD0iI2E2NWI3NSI+DQoJPHBhdGggZD0iTTE5Ny44NDksMEMxMjIuMTMxLDAsNjAuNTMxLDYxLjYwOSw2MC41MzEsMTM3LjMyOWMwLDcyLjg4NywxMjQuNTkxLDI0My4xNzcsMTI5Ljg5NiwyNTAuMzg4bDQuOTUxLDYuNzM4DQoJCWMwLjU3OSwwLjc5MiwxLjUwMSwxLjI1NSwyLjQ3MSwxLjI1NWMwLjk4NSwwLDEuOTAxLTAuNDYzLDIuNDg2LTEuMjU1bDQuOTQ4LTYuNzM4YzUuMzA4LTcuMjExLDEyOS44OTYtMTc3LjUwMSwxMjkuODk2LTI1MC4zODgNCgkJQzMzNS4xNzksNjEuNjA5LDI3My41NjksMCwxOTcuODQ5LDB6IE0xOTcuODQ5LDg4LjEzOGMyNy4xMywwLDQ5LjE5MSwyMi4wNjIsNDkuMTkxLDQ5LjE5MWMwLDI3LjExNS0yMi4wNjIsNDkuMTkxLTQ5LjE5MSw0OS4xOTENCgkJYy0yNy4xMTQsMC00OS4xOTEtMjIuMDc2LTQ5LjE5MS00OS4xOTFDMTQ4LjY1OCwxMTAuMiwxNzAuNzM0LDg4LjEzOCwxOTcuODQ5LDg4LjEzOHoiLz4NCg0KPC9zdmc+DQo=)';

export default {
  props: {
    datas: {
      type: Object,
      default: () => ({
        map: null,
        iso: null,
        assetsAndCommunitiesByCountries: [],
      }),
    },
    height: {
      type: [String, Number],
      default: 550,
    },
  },
  data() {
    return {
      chart: {},
      refreshMap: 0,
    };
  },
  watch: {
    'datas.map'() {
      this.loadData();
    },
    'datas.iso'() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      const dataBubbles = [];
      const data = [];
      this.datas.assetsAndCommunitiesByCountries.forEach((assetsByCountrie) => {
        const code = getCodeFromCountries(assetsByCountrie.country);
        const assets = [];
        assetsByCountrie.assets.forEach((asset) => {
          const assetLi = `<a href="/${assetsByCountrie.country.replaceAll(
            ' ',
            '_',
          )}/${asset.asset_short.replaceAll(' ', '_')}">${asset.asset_label}</a>`;
          const lon = parseFloat(asset.longitude);
          const lat = parseFloat(asset.latitude);

          const found = assets.find((a) => a.lat === lat && a.lon === lon);
          if (found) {
            found.name += `<br/>${assetLi}`;
          } else {
            assets.push({
              lon: lon,
              lat: lat,
              name: assetLi,
            });
          }
        });
        if (code === this.datas.iso || this.datas.iso === 'world') {
          dataBubbles.push(...assets);
          data.push({
            code: code.toUpperCase(),
            z: assetsByCountrie.assets.length,
            country: assetsByCountrie.country,
          });
        }
      });
      const text =
        this.datas.iso !== 'world'
          ? `${
              this.datas.assetsAndCommunitiesByCountries?.find(
                (a) => getCodeFromCountries(a.country) === this.datas.iso,
              )?.assets?.length || 0
            } Hosts Communities`
          : `${
              this.datas.assetsAndCommunitiesByCountries.length
            } Countries | ${this.datas.assetsAndCommunitiesByCountries.reduce(
              (sum, assetsByCountry) => sum + assetsByCountry.assets.length,
              0,
            )} Hosts Communities`;
      this.chart = {
        chart: {
          spacingTop: 0,
          spacingRight: 0,
          spacingBottom: 0,
          spacingLeft: 0,
          height: this.height,
        },
        title: {
          backgroundColor: theme.bhpWhite,
          style: {
            fontSize: '0.7rem',
            color: theme.bhpTextGrey,
            padding: 12,
          },
          text,
          align: 'right',
          y: 25,
          x: -12,
        },
        credits: {
          enabled: false,
        },
        mapNavigation: {
          enabled: true,
          enableMouseWheelZoom: false,
          buttons: {
            zoomOut: {
              x: -12,
              y: 12,
            },
            zoomIn: {
              x: -12,
              y: -12,
            },
          },
          buttonOptions: {
            align: 'right',
            verticalAlign: 'bottom',
          },
        },
        mapView: {
          projection: {
            name: 'WebMercator',
          },
        },
        accessibility: {
          enabled: false,
        },
        tooltip: {
          pointFormat: '{point.name}: {point.z}',
          useHTML: true,
          style: {
            pointerEvents: 'auto',
          },
        },
        plotOptions: {
          mappoint: {
            marker: {
              symbol: PIN_POINT,
            },
          },
        },
        series: [
          {
            mapData: this.datas.map,
            name: 'Country',
            showInLegend: false,
            cursor: 'pointer',
            nullColor: theme.bhpNavBlueGrey1,
            color: theme.bhpBlue3,
            joinBy: ['iso-a2', 'code'],
            data,
            tooltip: {
              pointFormat: '<b>{point.name}</b>: {point.z}',
              headerFormat: '',
            },
            events: {
              click: (evt) => {
                if (this.country || !evt.target?.className?.baseVal) return;
                this.$router.push({
                  path: `/${evt.point.country}`,
                });
              },
            },
            states: {
              hover: {
                color: theme.bhpBlue2,
                borderColor: theme.bhpBlue2,
              },
            },
          },
          {
            showInLegend: false,
            type: 'mappoint',
            name: '',
            dataLabels: {
              enabled: false,
            },
            tooltip: {
              useHTML: true,
              headerFormat: '<b>Assets</b><br/>',
              pointFormat: '{point.name}',
            },
            color: 'transparent',
            marker: {
              height: 12,
              width: 12,
              fillOpacity: 0,
            },
            data: dataBubbles,
          },
        ],
      };
      this.refreshMap += 1;
    },
  },
};
</script>

<style></style>
